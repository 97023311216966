import React from 'react';
import {Helmet} from "react-helmet";

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Not found</title>
                <meta name="description" content="The page is not found" />
            </Helmet>         
                <h1>Not found (404)</h1>
                <p>The page you are looking for is not found.</p>
        </>
    );
};

export default NotFound;
