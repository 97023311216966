import React, { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import GeneralService from "services/GeneralService";
import Loader from "components/loader-gifs/Loader";
import TornadoRow from "components/tornado-reports/TornadoRow";
import TornadoHead from "components/tornado-reports/TornadoHead";

function TornadoReports() {
    const contentRef = useRef()
    const [tornadoReports, setTornadoReports] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const generalService = new GeneralService();

    useEffect(() => {
        let isMounted = true; // flag to check if component is still mounted
    
        const getTornadoReports = async () => {
            setIsLoading(true);
            try {
                const response = await generalService.getTornadoReportsList();
                if (isMounted) {
                    setTornadoReports(JSON.parse(response));
                } else {
                    console.log("Component is not mounted, so not setting state.");
                }
            } catch (error) {
                console.error("Failed to fetch tornado reports:", error);
                // Optionally set some state to show an error message
            } finally {
                if (isMounted) {
                    setIsLoading(false);
                }
            }
        };
        console.log('getTornadoReports', isMounted)
    
        getTornadoReports();
    
        return () => {
            isMounted = false; // set it to false when the component unmounts
        };
    }, []);

    return (
        <div className='mx-auto px-4 md:px-8 h-full overflow-y-scroll' ref={contentRef}>
            <Helmet>
                <title>Recent Tornado reports in the USA</title>
                <meta name="description" content="List of the 100 most recent tornado reports in the last 30 days." />
            </Helmet>
            <div className='pb-10'>
                <h1>Tornado reports in the last 30 days</h1>
                <p>This list shows the last 100 tornado reports in the last 30 days. Note that multiple tornado reports could relate to the same tornado.</p>
                {isLoading ? (
                    <Loader />
                ) : tornadoReports && tornadoReports.length > 0 ? (
                    <div className=''>
                        <table className='w-full text-lg table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
                            <TornadoHead />
                            <tbody>
                                {tornadoReports.map((tornadoReport) => (
                                    <TornadoRow key={tornadoReport.report_id} tornadoReport={tornadoReport} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p>No tornado reports in the last 24 hours.</p>
                )}
            </div>
        </div>
    );
    
}

export default TornadoReports