import React, { useRef, useEffect, useState } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import GeneralService from "services/GeneralService";
import Loader from "components/loader-gifs/Loader";
import {SpotterRow} from "components/spotters/SpotterRow";
import { getTimeFromTimestamp, utcDateToReadable } from 'utils/general-functions';
import { US_STATES, CATEGORICAL_RISK_FULL_NAME_FROM_CODE  } from 'utils/constants';
import TornadoHead from 'components/tornado-reports/TornadoHead';
import TornadoRow from 'components/tornado-reports/TornadoRow';
import SunIcon from "assets/images/icons/sun.png"
import SunsetIcon from "assets/images/icons/sunset.png"
import NightIcon from "assets/images/icons/night.png"

function getSunlightIcon(sunlight) {
    const classNames = "w-6 h-6 baseline"
    if (sunlight === "day") {
        return <img src={SunIcon} className={classNames} alt="Tornado at day" title="Day"/> 
    } else if (sunlight === "night") {
        return <img src={NightIcon} className={classNames} alt="Tornado at night" title="Night" /> 
    } else if (sunlight === "sunset") {
        return <img src={SunsetIcon} className={classNames} alt="Tornado at sunset" title="Sunset" /> 
    }
}


function TornadoReports() {
    const contentRef = useRef()
    const { tornadoReportId } = useParams();
    const [tornadoReportItem, setTornadoReportItem] = useState(null)
    const [nearbyTornadoes, setNearbyTornadoes] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [nearbyTornadoesLoading, setNearbyTornadoesLoading] = useState(false);

    const navigate = useNavigate();   
    const generalService = new GeneralService();

    useEffect( async () => {
        const getTornadoReportItem = async () => {
            setIsLoading(true)
            setNearbyTornadoesLoading(true)
            //console.log("Tornado report id", tornadoReportId)
            generalService.getTornadoReportItem(tornadoReportId)
            .then((response) => {
                //console.log("Tornado report item", response)
                setTornadoReportItem(response)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log("Error", error)
                setIsLoading(false)
            })

            generalService.getNearbyTornadoReports(tornadoReportId)
            .then((response) => {
                //console.log("Nearby reports", response)
                setNearbyTornadoes(response)
                setNearbyTornadoesLoading(false)
            })
            .catch((error) => {
                console.log("Error", error)
                setNearbyTornadoesLoading(false)
            })            

        }
        getTornadoReportItem()
    }, [tornadoReportId]);    

    function handleSpotterClicked(spotterSlug) {
        console.log("Spotter clicked", spotterSlug)
        navigate(`/spotters/${spotterSlug}`)
    }


    function handleReportClicked(tornadoReportId) {
        console.log("Report clicked", tornadoReportId)
        navigate(`/tornado-reports/${tornadoReportId}`)
    }    

    return (
        <div className='mx-auto px-4 md:px-8 h-full overflow-y-scroll' ref={contentRef}>

            <div className='pb-10'>
                
                {isLoading ? (
                    <Loader />
                ) : tornadoReportItem ? (
                    <>
                    <Helmet>
                        <title>Tornado report in {tornadoReportItem.county ?? ""}, {tornadoReportItem.state} on {tornadoReportItem.datetime_utc.slice(0,10)}</title>
                        <meta name="description" content="Tornado Report" />
                    </Helmet>
                    <div>
                        <h1>Tornado report in {tornadoReportItem.county ?? ""}, {tornadoReportItem.state} on {tornadoReportItem.datetime_utc.slice(0,10)}</h1>
                        <div className='flex flex-col md:flex-row bg-[#012432ba] rounded-md p-4 gap-4'>
                            <div className='flex-1'>
                                <h2 className=' font-bold'>Time and location</h2>
                                <p>Date: <a href={`/history/${tornadoReportItem.datetime_utc.slice(0,10)}`} className="underline">{tornadoReportItem.datetime_utc.slice(0,10)}</a></p>
                                <p>Time: {getTimeFromTimestamp(tornadoReportItem.datetime_utc)} Z / {utcDateToReadable(tornadoReportItem.datetime_utc,'cdt').split(",")[1].replace(":00 ", " ")} {getSunlightIcon(tornadoReportItem.sunlight)}</p>
                                <p>Location: {tornadoReportItem.location}, {tornadoReportItem.county ?? ""} county</p>
                                <p>State: {US_STATES[tornadoReportItem.state]}</p>
                            </div>
                            <div className='flex-1'>
                                <h2 className='font-bold'>Tornado information</h2>
                                <p>Source: {tornadoReportItem.data_source === 'spc' ? "SPC" : `Spotter Network reporter: ${tornadoReportItem.reporter}`}</p>
                                <p>{tornadoReportItem.comments}</p>                                
                                <p>EF Scale: {tornadoReportItem.f_scale_enhanced ?? "Unknown"}</p>
                                <p>Tags: {tornadoReportItem.tags ? tornadoReportItem.tags.join(", ") : "-"}</p>
                                <p>Injuries/Fatalities: {tornadoReportItem.injuries ? `🩹 ${tornadoReportItem.injuries}` : ""} {tornadoReportItem.fatalities ? `💀 ${tornadoReportItem.fatalities}` : ""}</p>
                            </div>
                            <div className='flex-1'>
                                <h2 className='font-bold'>Weather conditions</h2>
                                <p className="italic">Based on the outlook {tornadoReportItem.relevant_outlook_run}</p>
                                <p>SPC Outlook: {CATEGORICAL_RISK_FULL_NAME_FROM_CODE[tornadoReportItem.max_categorical_risk]}</p>
                                <p>SPC Tornado Outlook: {tornadoReportItem.max_tornado_risk } %</p>
                                <p><a href={`/history/${tornadoReportItem.datetime_utc.slice(0,10)}`} className="underline">SPC Text and other info about this day</a></p>
                            </div>                            
                        </div>
                        <div>
                            <h3>Spotters nearby</h3>
                            <table className='w-max-[500px] table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
                            <thead>
                            <tr className='bg-transparent text-lg text-white border-solid border-0 border-b border-[#ffffff54]'>
                                <th className='font-bold text-left p-[8px] min-w-[100px] sm:min-w-[150px] lg:min-w-[200px]'>
                                    Name
                                </th>
                                 <th className='font-bold text-left p-[8px] sm:min-w-[120px] lg:min-w-[200px]'>
                                    Twitter
                                </th>
                                <th className='font-bold text-left p-[8px] sm:min-w-[120px] lg:min-w-[200px]'>
                                    Distance to tornado
                                </th>
                            </tr>
                        </thead>   
                        <tbody>
                        {tornadoReportItem.spotters_nearby && tornadoReportItem.spotters_nearby.length > 0 ? (
                                tornadoReportItem.spotters_nearby.map(nearbySpotter => (
                                    <tr
                                        className='bg-transparent text-white text-lg border-solid border-0 border-b border-[#ffffff54]'
                                        key={nearbySpotter.id}
                                    >
                                        <td className='p-[8px] align-baseline'>
                                            <p className='m-0 text-sm w-fit cursor-pointer hover:text-[green]' >
                                                <a href={`/spotters/${nearbySpotter.slug}`} className="underline text-lg"> {nearbySpotter.first_name} {nearbySpotter.last_name}</a>
                                            </p>
                                        </td>       
                                        <td className='p-[8px] align-baseline'>
                                            {nearbySpotter.twitter && (<a href={`https://x.com/${nearbySpotter.twitter}`} target="_blank" className="underline">{nearbySpotter.twitter}</a>)}
                                        </td>
                                        <td className='p-[8px] align-baseline'>
                                        {parseFloat(nearbySpotter.distance_difference_miles).toFixed(2)} miles @ {parseFloat(nearbySpotter.time_difference_minutes).toFixed(2)} min
                                        </td>                                        
                                    </tr>
                                    ))
                            ) : (
                                tornadoReportItem.datetime_utc.slice(0,10) < "2024-06-08" ? (
                                    <p className="italic">There is no data about nearby spotters from the date of this tornado report (data is only available from June 8th 2024).</p>
                                ) : (
                                    <p>No spotters nearby.</p>
                                )
                            )}


                        </tbody>
                    </table>                                                 
                        

                        </div>

                        
                        {nearbyTornadoesLoading ? (
                            <Loader />
                        ) : nearbyTornadoes ? (
                            <>
                                <h3>Historical tornadoes near this location</h3>
                                <p>
                                    This is a list of other tornado reports within 20 miles of the location of this tornado report. Tornado reports from this same day {tornadoReportItem.datetime_utc.slice(0,10)} are highlighted in red.
                                </p>
                                <table className='w-full text-lg table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
                                <TornadoHead />
                                <tbody>
                                    {nearbyTornadoes.map((tornadoReport) => (
                                        <TornadoRow 
                                            key={tornadoReport.report_id} 
                                            tornadoReport={tornadoReport} 
                                            comparedDate={tornadoReportItem.datetime_utc.slice(0,10)}
                                        />
                                    ))}
                                </tbody>
                                </table>                                


                                <h3>See latest tornado reports</h3>
                                <p><a href="/tornado-reports" className="underline">List of tornado reports last 24h</a></p>
                            </>
                        ) : (
                            <p>No nearby tornado reports found.</p>
                        )}
                    </div>
                    </>
                ) : (
                    <p>There is no tornado report to be found (404)</p>
                )}
            </div>
        </div>
    );
    
}

export default TornadoReports