import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
// import {timeSince} from "utils/general-functions";
// import TweetService from "services/TweetService";
// import './Photos.css'

const Photos = ({children}) => {
    return ( 
        <>
        <Helmet>
            <title>Photos of storms and tornadoes last 24 hours</title>
            <meta 
                name="description"
                content="New photos from storm chasers of storms and tornadoes from the last 24 hours. You can set the time slider to show photos from a range within that time window"    
            />
        </Helmet>        
        <h1>Photos from tweets</h1>
        <p>This service does unfortunately no longer exist on StormCenter.</p>
        </>
    )
    // const tweetService = new TweetService();    
    // const [sliderValue, setSliderValue] = useState([-12, 0]);
    // const [photos, setPhotos] = useState()
    // const now = new Date()

    // useEffect(() => {
    //     async function fetchPhotos() {
    //         console.log("Fetching Twitter photos from backend...")
    //         const data = await tweetService.getAllImageTweets();
    //         //const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/get-all-image-tweets', {method: 'POST'});
    //         //const data = await response.json();
    //         let dataJson = JSON.parse(data)
    //         let photos
    //         if (dataJson.length > 0) {
    //             photos = dataJson.filter((tweet) => {
    //                 return true //(new Date(tweet.created_at) > new Date(now.getTime() - (-sliderValue[0] * 60 * 60 * 1000)) && new Date(tweet.created_at) <= new Date(now.getTime() - (-sliderValue[1] * 60 * 60 * 1000)))
    //             })
    //         } else {
    //             photos = dataJson
    //         }
    //         setPhotos(photos);
    //     }

    //     fetchPhotos();
    // }, []);

    // return (
    //     <div className={'box-border px-8 pb-3 -mt-1'}>
    //         <Helmet>
    //             <title>Photos of storms and tornadoes last 24 hours</title>
    //             <meta 
    //                 name="description"
    //                 content="New photos from storm chasers of storms and tornadoes from the last 24 hours. You can set the time slider to show photos from a range within that time window"    
    //             />
    //         </Helmet>

    //         <h1>Photos from tweets in last 24h</h1>
    //         <div id="">
    //         <span className="text-center ">
    //         <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 ">
    //             {photos && photos.map((imageTweet) => {
    //                 return (
    //                     <div
    //                         key={imageTweet.tweet_numeric}
    //                         className=' h-[200px] max-w[200px] border-[2px] border-[#6b7280] border-solid  rounded-lg relative'>
    //                         <a
    //                             className='absolute bottom-3 right-5'
    //                             target='_blank'
    //                             rel='noreferrer'
    //                             href={"https://www.twitter.com/" + imageTweet.twitter + "/statuses/" + imageTweet.tweet_numeric}
    //                         >@{imageTweet.twitter}
    //                         </a>
    //                         <a
    //                             title={imageTweet.text}
    //                             target='_blank'
    //                             rel='noreferrer'
    //                             href={"https://www.twitter.com/" + imageTweet.twitter + "/statuses/" + imageTweet.tweet_numeric}
    //                             className='block h-full '
    //                         >
    //                             <div className='w-full'>
    //                             <span
    //                                 className={'absolute top-0 left-2 bg-black bg-opacity-50 text-[#fff] p-1 rounded-r-lg rounded-tr-none font-bold'}>
    //                                 @{imageTweet?.twitter}&nbsp;-&nbsp;
    //                                 <span className={'font-light text-[11px]'}>{timeSince(imageTweet.created_at) + ' ago'}</span>                                
    //                             </span>
    //                                 <img 
    //                                     src={imageTweet.image_url} 
    //                                     alt={"Credit: " + imageTweet.twitter}
    //                                     className='h-[200px]  w-full object-cover rounded'
    //                                 />
    //                             </div>

    //                         </a>
    //                     </div>
    //                 )
    //             })}
    //         </div>
    //         </span>
    //         </div>
    //     </div>
    // )
}

export default Photos