import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
} from "@fortawesome/free-solid-svg-icons";

import { StormContext } from 'contexts/StormContext';
import { useAuth } from 'contexts/AuthContext';
import { getRelativeTimeFromDateTime } from 'utils/general-functions';
import { timeSince } from "utils/general-functions";
import { US_STATES } from "utils/constants";

import sliderFilterWhite from 'assets/images/icons/sliderfilter-white.webp';

export const SpotterHighscoreRow = ({
    tornadoCount,
    spotterId,
    slug,
    name,
    lastName,
    twitter,
    lastSnPing,
    lastPosition,
    lat,
    lng
}) => {
    const { state } = useAuth();
    const navigate = useNavigate();
    const { zoomToPositionAndTime } = useContext(StormContext);

    // const handleClickSpotters = () => {
    //     navigate('/');
    //     zoomToPositionAndTime(lat, lng, getRelativeTimeFromDateTime(lastSnPing));
    // };
    const handleClickSpotters = () => {
        const targetLocation = {
            lat: lat,
            lng: lng,
            relativeTime: getRelativeTimeFromDateTime(lastSnPing),
        };
        navigate('/', { state: { targetLocation } });
    };    


    const now = new Date();
    const pingDate = new Date(lastSnPing);
    const msDifference = now - pingDate;
    const hoursDifference = msDifference / (1000 * 60 * 60);

    const isActive = lastSnPing && hoursDifference <= 24;
    const activityStatus = isActive ? `${pingDate.toISOString().replace("T", " ").substr(0, 16)}Z ( ${timeSince(pingDate)} ago)` : "None in last 24h";
    const stateName = isActive && lastPosition ? US_STATES[lastPosition] : "";

    return (
        <tr
            className='bg-transparent text-white border-solid border-0 border-b border-[#ffffff54]'
            key={spotterId}
        >
            <td className='p-[8px] align-baseline'>
                {tornadoCount}
            </td>
            <td className='p-[8px] align-baseline'>
                <p className='m-0 w-fit cursor-pointer hover:text-[green]' >
                <a href={`/spotters/${slug}`} className="underline"> {name} {lastName}</a>  <img onClick={handleClickSpotters} src={sliderFilterWhite} alt="" className="h-3"></img>
                </p>
            </td>      
            <td className='p-[8px] align-baseline'>
                <a href={`https://x.com/${twitter.replace("@", "")}`} className="underline" target="_blank"> {twitter}</a>
            </td>
            <td className='p-[8px] align-baseline'>
                {activityStatus}
            </td>
            <td className='p-[8px] align-baseline'>
                {stateName}
            </td>
        </tr>
    );
};
