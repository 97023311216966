import React from "react";
import {
    IconLayer,
  } from "@deck.gl/layers";

// Internal function
import {
    timeSince,
    utcDateToReadable,
    getRelativeTimeFromDateTime,
} from "utils/general-functions";  

import {
    getSpotterReportName,
    getSpotterReportIconSize,
    getEventIcon
} from "utils/map-functions";

import sliderFilterDark from 'assets/images/icons/sliderfilter-dark.webp';


// Spotter Reports  
function getSpotterReportIcon(reportType) {
    const spotterReportEventIconLookup = {
        other: getEventIcon('other'),
        tornado: getEventIcon('tornado'), 
        wallcloud: getEventIcon('wallcloud'),
        funnelcloud: getEventIcon('funnel'),
        hail: getEventIcon('hail'),
        wind: getEventIcon('wind'),
    };
    return "." + spotterReportEventIconLookup[reportType];
}


// Layers
export const createSpotterReportsLayer = ({
    spotterReports,
    otherReportsCheck,
    displayPopup,
    zoomToPositionAndTime,
    setModalType,
  }) => {
    return spotterReports.length > 0
    ? new IconLayer({
        id: "spotter-reports-layer",
        data: spotterReports,
        pickable: true,

        getIcon: (d) => {
            return {
            url: getSpotterReportIcon(d.report_type),
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            mask: false,
            };
        },
        getSize: (d) => getSpotterReportIconSize(d.report_type),
        getPosition: (d) => d.position,
        getColor: d => {
            const [r, g, b] = [Math.sqrt(d.exits), 140, 0];
            const opacity = Math.max(d.sliderRelativeOpacity * 255, 100); // Convert to 0-255 range
            return [r, g, b, opacity];
          },         
        visible: otherReportsCheck,
        onClick: (d) => {
            setModalType("OtherReports");
            displayPopup(
            <div className={"text-black font-bold"}>
                <h3 className={"text-black font-bold"}>
                <img src={getSpotterReportIcon(d.object.report_type)} alt="spotter report icon" className="w-5 h-5" /> Storm spotter report: {getSpotterReportName(d.object.report_type)}         
                </h3>
                
                <div className="grid grid-cols-3 gap-1 text-base">
                <div className="text-left inline-block text-black font-bold col-span-1">Source</div>
                <div className="col-span-2">Spotter Network</div>

                <div className="text-left inline-block text-black font-bold col-span-1">Time (UTC/Zulu)</div>
                <div className="col-span-2">
                    {utcDateToReadable(d.object.datetime_utc, 'zulu')} ({timeSince(new Date(d.object.datetime_utc))} ago)
                    <img
                    src={sliderFilterDark}
                    alt=""
                    className={'h-4 cursor-pointer'}
                    onClick={() =>
                        zoomToPositionAndTime(
                        d.object["latitude"],
                        d.object["longitude"],
                        getRelativeTimeFromDateTime(d.object["datetime_utc"])
                        )
                    }
                    />
                </div>

                <div className="text-left inline-block text-black font-bold col-span-1">Reporter comment</div>
                <div className="col-span-2">{d.object.comments}</div>

                <div className="text-left inline-block text-black font-bold col-span-1">Reporter</div>
                <div className="col-span-2">{d.object.spotter_full_name}</div>  

                <div className="text-left inline-block text-black font-bold col-span-1">Coordinates</div>
                <div className="col-span-2">{d.object.latitude}, {d.object.longitude}</div>                 
                </div>    
            </div>          
            );
        },
        })
    : null
}