import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

export const createSatelliteLayer = ({
    id = "satellite-layer",
    data,
    satelliteCheck,
  }) => {
    return new TileLayer({
      id,
      data,
      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,
      opacity: 0.3,
      renderSubLayers: (props) => {
        const {
          bbox: { west, south, east, north },
        } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north],
        });
      },
      visible: satelliteCheck,
    });
  };

  export const createRadarLayer = ({
    id = "radar-layer",
    sliderValue,
    customTimestampTransform,
    radarCheck,
  }) => {
    console.log("Fetching radar layer")
    // const timestamp = customTimestampTransform("iastate", sliderValue[1]);
    // const cacheBuster = new Date().getTime(); // Use current time to bust the cache
    // //data: `https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/ridge::USCOMP-N0Q-${timestamp}/{z}/{x}/{y}.png?cache=${cacheBuster}`,
    return new TileLayer({
      id,
      data:
        "https://mesonet.agron.iastate.edu/cache/tile.py/1.0.0/ridge::USCOMP-N0Q-" +
        customTimestampTransform("iastate", sliderValue[1]) +
        "/{z}/{x}/{y}.png",
      minZoom: 0,
      maxZoom: 19,
      tileSize: 256,
      opacity: 0.2,
      renderSubLayers: (props) => {
        const {
          bbox: { west, south, east, north },
        } = props.tile;
        return new BitmapLayer(props, {
          data: null,
          image: props.data,
          bounds: [west, south, east, north],
        });
      },
      visible: radarCheck,
    });
  };  