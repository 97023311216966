import React, {useContext, useEffect, useState} from 'react'
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from 'classnames'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { StormContext } from 'contexts/StormContext';

export const SignificantEvents = ({significantEvents}) => {

    const isMobile = useMediaQuery("(max-width:600px)");
    const isTab = useMediaQuery("(min-width:601px)")
    const isLrg = useMediaQuery("(max-width: 1000px)")

    const {isToggleTornadoDays, setIsToggleTornadoDays} = useContext(StormContext)

    const [sliderSettings, setSliderSettings] = useState({});

    useEffect(() => {
        setSliderSettings({
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: isMobile ? 1 : ( isTab&&isLrg) ? 2:3,
            slidesToScroll: 1,
            autoplay: true,
            className: "relative ml-10 w-[calc(100%-90px)] [&>div>div]:!flex [&>div>div>div]:!h-[inherit] [&>div>div>div>div]:!h-full",
            nextArrow: <NextArrow/>,
            prevArrow: <PrevArrow/>,
        })
    }, [isMobile, isLrg, isTab]);


    return (
        <div className="my-5 md:my-2 lg:my-0 min-[1170px]:col-span-8 h-fit">
            <h3 className={'mt-0'} onClick={() => setIsToggleTornadoDays(!isToggleTornadoDays)}>
                Significant Tornado Days 
                <FontAwesomeIcon icon={faChevronDown} className={classNames("inline md:hidden transform ml-2", {
                    'rotate-180': isToggleTornadoDays,
                })}/>
            </h3>
            <div className={classNames('md:block', {
                'hidden': !isToggleTornadoDays
            })}>
                <Slider
                    {...sliderSettings}
                >
                    {significantEvents?.map((events, i) => {
                        return (
                            <div key={i} className={`bg-[#0d2e40] ps-2 h-full`}>
                                <a
                                    href={`/history/${formatDate(events.event_date)}`}
                                    className="bg-white min-h-[70px] block p-[12px] pr-2 rounded-sm h-full"
                                >
                                    <span className="significant-date text-[0.9em] leading-none text-[#1c1c1d] m-0 font-medium">
                                        {displayFormatDate(events.event_date)}
                                    </span>
                                    <h5 className="text-[1.1rem] text-[#012432] leading-none   my-[0.2em]">
                                        {events.name}
                                    </h5>
                                    <span className="d-block small text-[#1c1c1d] leading-none italic font-medium mb-2">
                                        {events.taglines[0]}
                                    </span>
                                </a>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

const NextArrow = ({className, onClick}) => {
    return (
        <div className={`${className} absolute !-right-6 min-[1169px]:!-right-8 !top-1/2`} onClick={onClick}/>
    );
};

const PrevArrow = ({className, onClick}) => {
    return (
        <div className={`${className} absolute !-left-6 min-[1169px]:!-left-8  !top-1/2`} onClick={onClick}/>
    );
};

const formatDate = (inputDate) => {
    // Parse the input date string into a Date object
    const dateObj = new Date(inputDate);

    // Get the year, month, and day from the Date object
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

const displayFormatDate = (inputDate) => {
    // Parse the input date string into a Date object
    const dateObj = new Date(inputDate);

    // Get the year, month, and day from the Date object
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Create the formatted date string in "DD/MM/YYYY" format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
};