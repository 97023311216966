import React, { useContext, useEffect, useMemo, useState, useRef, lazy, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from 'contexts/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import {
    faStar,
} from "@fortawesome/free-solid-svg-icons";

import SpotterService from "services/SpotterService";
import SpotterTornadoRow from "components/spotters/SpotterTornadoRow";
import { timeSince, getRelativeTimeFromDateTime } from 'utils/general-functions';
import { US_STATES } from "utils/constants";

const NotFound = lazy(() => import("pages/NotFound"));
const Loader = lazy(() => import("components/loader-gifs/Loader"));




function SpotterItem() {
    const { state } = useAuth();
    const navigate = useNavigate();    
    const { spotterSlug } = useParams();
    const spotterService = useMemo(() => new SpotterService(), []);
    const currentURL = "https://stormcenter.app" + window.location.pathname;

    const [spotter, setSpotter] = useState(null);
    const [nearbyTornadoes, setNearbyTornadoes] = useState(null);
    const [last24hActive, setLast24hActive] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [activityStatus, setActivityStatus] = useState("");
    const [stateName, setStateName] = useState("");
    const [distanceCovered24h, setDistanceCovered24h] = useState("");   
    const [hasError, setHasError] = useState(false); 

    const handleClickSpotters = (latitude, longitude, lastSnPing) => {
        const targetLocation = {
            lat: latitude,
            lng: longitude,
            relativeTime: getRelativeTimeFromDateTime(lastSnPing),
        };
        navigate('/', { state: { targetLocation } });
    };       

    const updateSpotterInfo = (spotter) => {
        if (!spotter) return;
        const now = new Date();
        const pingDate = new Date(spotter.last_ping_utc);
        const msDifference = now - pingDate;
        const hoursDifference = msDifference / (1000 * 60 * 60);
        const isActive = spotter.last_ping_utc && hoursDifference <= 24;
        setLast24hActive(isActive);
        
        const newActivityStatus = isActive ? `${pingDate.toISOString().replace("T", " ").substr(0, 16)}Z (${timeSince(pingDate)} ago)` : "Not in last 24h";
        setActivityStatus(newActivityStatus);
        
        const newStateName = isActive && spotter.location_state ? US_STATES[spotter.location_state] : "Unknown";
        setStateName(newStateName);
        console.log(spotter)
        const newDistanceCovered24h = isActive ? (spotter.distance_last_24h < 100 ? "Unknown" : `${spotter.distance_last_24h} miles`) : "Unknown";
        setDistanceCovered24h(newDistanceCovered24h);
    };    

    useEffect(() => {
        setSpotter(null);
        setIsLoading(true);
        setHasError(false);
        spotterService.getSpotterItem(spotterSlug)
            .then(spotter => {
                setSpotter(spotter);
                updateSpotterInfo(spotter);
                return  spotter;
            })
            .then(async (spotter) => {
                await spotterService.getTornadoesNearbyBySpotter(spotter.id)
                    .then(nearbyTornadoReports => {
                        setNearbyTornadoes(nearbyTornadoReports);
                    });
            })
            .then(() => {
                setIsLoading(false);
            })
            .catch(error => {
                setHasError(true);
                console.error(error);
            });
    }, [spotterSlug, spotterService]);

    const toggleFavorite = () => {
        setSpotter(prevSpotter => ({ ...prevSpotter, isFavorite: !prevSpotter.isFavorite }));
    };
    if (isLoading) {
        return <div className='ml-5'><Suspense fallback={<div>Loading...</div>}><Loader /></Suspense></div>;
    }

    if (hasError || !spotter) {
        return <div className='ml-5'><Suspense fallback={<div>Loading...</div>}><NotFound /></Suspense></div>;
    }

    return (
            <>
                <Helmet>
                    <title>Where is Storm Chaser {`${spotter.sn_first_name} ${spotter.sn_last_name}`}?</title>
                    <meta name="description" content={`Storm Chaser ${spotter.sn_first_name} ${spotter.sn_last_name} is currently in ${stateName}. Follow the chase here!`} />
                    <link rel="canonical" href={currentURL} />
                </Helmet>
                <div className='ml-5'>
                    {state.isAuthenticated && state.user &&
                        <FontAwesomeIcon icon={faStar} className={`${spotter.isFavorite ? 'text-[green]' : 'text-[grey]'} cursor-pointer`} onClick={toggleFavorite}/>
                    }                    
                    <h1>Storm Chaser {spotter.sn_first_name} {spotter.sn_last_name}</h1>
                    <div class="p-4 rounded-lg shadow max-w-4xl mx-auto w-100">
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Last Spotter Network ping</span>
                            <span class="text-lg">{activityStatus}</span>
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Current location</span>
                            <span class="text-lg">{stateName}</span>
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Distance covered last 24h</span>
                            <span class="text-lg">{distanceCovered24h}</span>
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Live chaser map</span>
                            {last24hActive ? <span onClick={() => handleClickSpotters(spotter.sn_latitude, spotter.sn_longitude, spotter.last_ping_utc)} className="text-lg underline cursor-pointer">Go to position</span> : <span class="text-lg">Not active</span>}
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Twitter</span>
                            {spotter.twitter ? <a href={`https://x.com/${spotter.twitter}`} class="text-blue-500 hover:text-blue-700 underline text-lg">@{spotter.twitter}</a> : <span class="text-gray-500 text-lg">-</span>}
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">YouTube</span>
                            {spotter.youtube_channel ? <a href={`https://www.youtube.com/@${spotter.youtube_channel}`} class="text-blue-500 hover:text-blue-700 underline text-lg" target="_blank">{spotter.youtube_channel}</a> : <span class="text-gray-500 text-lg">-</span>}
                        </div>
                        <div class="flex justify-between items-center py-2">
                            <span class="font-bold w-64 text-lg">Live stream</span>
                            {spotter.other_livestream_url ? <a href={spotter.other_livestream_url} class="text-blue-500 hover:text-blue-700 underline text-lg">{spotter.other_livestream_url}</a> : <span class="text-gray-500 text-lg">-</span>}
                        </div>
                    </div>



                    <h3>Tornadoes (possibly) spotted by {spotter.sn_first_name} {spotter.sn_last_name} in the last 60 days</h3>
                    
                    {nearbyTornadoes && nearbyTornadoes.length > 0 ? (
                        <>
                        <p>These are tornado reports where the chaser has been close in distance and time to. This does not mean that the chaser necessarily saw the tornado.</p>
                        <div className="tornado-reports-table overflow-x-auto">
                            <table className='w-full table border-collapse border-spacing-0 bg-[#012432ba] rounded-md text-normal'>
                                <thead>
                                    <tr className='bg-transparent border-solid text-lg border-0 border-b border-[#ffffff54]'>
                                        <th className='font-bold text-left p-[8px] min-w-[110px]'>Date</th>
                                        <th className='font-bold text-left p-[8px] min-w-[100px]'>Time</th>
                                        <th className='font-bold text-left p-[8px]'>Sunlight</th>
                                        <th className='font-bold text-left p-[8px] min-w-[120px]'>Chaser distance</th>
                                        <th className='font-bold text-left p-[8px]'>Location</th>
                                        <th className='font-bold text-left p-[8px]'>State</th>
                                        <th className='font-bold text-left p-[8px]'>Tags</th>
                                        <th className='font-bold text-left p-[8px] min-w-[70px]'>EF/F-Scale</th>
                                        <th className='font-bold text-left p-[8px]'>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {nearbyTornadoes?.map((tornadoReport) => (
                                        <SpotterTornadoRow key={tornadoReport.id} tornadoReport={tornadoReport} />
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        </>
                    ) : (
                        <p className="italic">No records of this chaser being close to tornado reports was found.</p>
                    )}                    
                </div>
            </>
    );
}

export default SpotterItem;
