import React from "react";
import { Dialog } from "@mui/material";

const TermsAndConditionsModal = ({
    open,
    onClose
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="custom-modal"
        >
            <div className="m-2 text-black">
                <button onClick={onClose} className="float-right text-black">X</button>
                <h2 className="text-black">Terms and conditions</h2>
                <p className="text-black">Registering for StormCenter means that you can customize your experience at StormCenter.app and allows for a more enhanced experience compared to not being logged in.
                </p>
                <p className="text-black">The registered/logged in user experience is a work in progress at the moment, and is subjected to changes in features, bugs and (hopefully not) accidental loss of data.</p>

                <h3 className="text-black">What we collect</h3>
                <p className="text-black">When you save a spotter as a favorite, that data is saved in the database for you to keep track of the spotter. Your settings are also saved in the database. Nothing else.</p>

                <h3 className="text-black">Privacy</h3>
                <p className="text-black">StormCenter.app does not sell your data to any third party. We use your data to provide you with the best experience possible. We do not store your password in plain text and we do not store your password in our database. We use a secure hash to store your password in our database.
                </p>
            
                <h3 className="text-black">Deleting an account</h3>
                <p className="text-black">As of now, you need to contact christoffer@stormchasingusa.com from the email you have used for your login, and ask to have your account deleted. In the future, this will be able to do in the application directly.</p>
            </div>
        </Dialog>
    )
}

export default TermsAndConditionsModal;