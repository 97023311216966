import { GeoJsonLayer } from '@deck.gl/layers';
import { AlertPopupInfo } from 'components/storm-map/AlertPopupInfo'; 

// TORNADO WARNING
export const createTornadoWarningLayers = ({
    tornadoWarnings,
    warningsCheck,
    displayPopup,
    setModalType
  }) => {
    const safeTornadoWarnings = tornadoWarnings ? tornadoWarnings : [];
    return safeTornadoWarnings.map((polygon, index) => {
      const polygonFeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            warning_info: polygon,
            type: "Feature",
            geometry: polygon.warning_polygon_json,
          },
        ],
      };
  
      return new GeoJsonLayer({
        id: "tornado-warnings-geojson-layer-" + index,
        data: polygonFeatureCollection,
        filled: true,
        opacity: 0.4,
        getFillColor: [255, 0, 0],
        getBorderColor: [255, 255, 255],
        lineWidthMinPixels: 3,
        visible: warningsCheck,
        pickable: true,
        onClick: (d) => {
          setModalType('Warning');
          displayPopup(
            <AlertPopupInfo 
              title="Tornado Warning"
              objectInfo={d.object.warning_info}
            />
          );
        },       
      });
    });
  };

  // SEVERE WARNING
  export const createSevereWarningLayers = ({
    severeWarnings,
    warningsCheck,
    displayPopup,
    setModalType
  }) => {
    const safeSevereoWarnings = severeWarnings ? severeWarnings : []; 
    return safeSevereoWarnings.map((polygon, index) => {
      const polygonFeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            warning_info: polygon,
            type: "Feature",
            geometry: polygon.warning_polygon_json,
          },
        ],
      };
  
      return new GeoJsonLayer({
        id: "severe-warnings-geojson-layer-" + index,
        data: polygonFeatureCollection,
        filled: true,
        opacity: 0.3,
        getFillColor: [255, 247, 0],
        stroked: true,
        getLineColor: [0, 0, 0, 100],
        lineWidthMinPixels: 3,
        visible: warningsCheck,
        pickable: true,
        onClick: (d) => {
          setModalType('Warning');
          displayPopup(
            <AlertPopupInfo 
              title="Severe Weather Warning"
              objectInfo={d.object.warning_info}
            />
          );
        },      
      });
    });
  };


  ///// WATCHES /////

  // TORNADO WATCH
  export const createTornadoWatchLayers = ({
    tornadoWatches,
    watchesCheck,
    displayPopup,
    setModalType
  }) => {
    return tornadoWatches.map((watch, index) => {
      const polygonFeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            watch_info: watch,
            type: "Feature",
            geometry: watch.watch_polygon_json_slim,
          },
        ],
      };
  
      return new GeoJsonLayer({
        id: "tornado-watch-geojson-layer-" + index,
        data: polygonFeatureCollection,
        filled: true,
        opacity: 0.1,
        getFillColor: [255, 0, 0],
        visible: watchesCheck,
        pickable: true,
        onClick: (d) => {
          setModalType("Watches");
          displayPopup(
            <AlertPopupInfo 
              title="Tornado Watch"
              objectInfo={d.object.watch_info}
            />
          );
        },
      });
    });
  };


  // SEVERE WATCH
  export const createSevereWatchLayers = ({
    severeWatches,
    watchesCheck,
    displayPopup,
    setModalType
  }) => {
    return severeWatches.map((watch, index) => {
      const polygonFeatureCollection = {
        type: "FeatureCollection",
        features: [
          {
            watch_info: watch,
            type: "Feature",
            geometry: watch.watch_polygon_json_slim,
          },
        ],
      };
  
      return new GeoJsonLayer({
        id: "severe-watch-geojson-layer-" + index,
        data: polygonFeatureCollection,
        filled: true,
        opacity: 0.3,
        getFillColor: [255, 247, 0, 50],
        getLineColor: [255, 247, 0, 255],
        lineWidthMinPixels: 2,
        visible: watchesCheck,
        pickable: true,
        onClick: (d) => {
          setModalType("Watches");
          displayPopup(
            <AlertPopupInfo 
              title="Severe Weather Watch"
              objectInfo={d.object.watch_info}
            />
          );
        },       
      });
    });
  };