import React from 'react';

function TornadoHead() {
    return (        
        <thead>
        <tr className='bg-transparent border-solid border-0 border-b border-[#ffffff54]'>
            <th className=' font-bold text-left p-[8px]'>Report date</th>
            <th className=' font-bold text-left p-[8px]'>UTC time</th>
            <th className=' font-bold text-left p-[8px]'>Sunlight</th>
            <th className=' font-bold text-left p-[8px]'>County</th>
            <th className=' font-bold text-left p-[8px]'>State</th>
            <th className=' font-bold text-left p-[8px]'>Comments</th>
            <th className=' font-bold text-left p-[8px]'>Source</th>
            <th className=' font-bold text-left p-[8px]'>Tags</th>
            <th className=' font-bold text-left p-[8px]'>EF Scale</th>
            <th className=' font-bold text-left p-[8px]'>Injuries / Fatalities</th>
            <th className=' font-bold text-left p-[8px]'>Category risk</th>
            <th className=' font-bold text-left p-[8px]'>Tornado risk</th>
            <th className=' font-bold text-left p-[8px]'>Spotters nearby</th>
        </tr>
        </thead>
    );
}

export default TornadoHead;

