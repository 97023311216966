import React, { useContext, useEffect, useMemo, useState, useRef, lazy, Suspense } from "react";
import SpotterService from "services/SpotterService";
import { Helmet } from "react-helmet";
import { SpotterHighscoreRow } from 'components/spotters/SpotterHighscoreRow'

const Loader = lazy(() => import("components/loader-gifs/Loader"));


function Highscore() {

    const [highscoreList, setHighscoreList] = useState();
    const spotterService = useMemo(() => new SpotterService(), []);

    const contentRef = useRef()
    const [isLoading, setIsLoading] = useState(true);

    const getTopSpotters = async () => {
        const response = await spotterService.getSpotterHighscore({
            limit: 100,
        });
        return response
    }

    useEffect(() => {
        console.log("Highscore useEffect")
        setHighscoreList(null);
        setIsLoading(true);
        spotterService.getSpotterHighscore()
            .then(highscore => {
                setHighscoreList(highscore);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);    

    return (
    <div className='mx-auto px-4 md:px-8 h-full overflow-y-scroll' ref={contentRef}>
    <Helmet>
        <title>Storm chasers who have seen the most tornadoes</title>
        <meta name="description" content="This is a 'high score' list of storm chasers who have been close to the most tornadoes." />
    </Helmet>
    <div className='pb-10'>
        <h1 className=''>Which chaser has seen the most tornadoes lately?</h1>
        <p>
            This list shows 
            which storm chasers (active SpotterNetwork users) <strong className="font-bolder">have been close to</strong> tornades in the last 60 days. If a chaser has had a spotter network ping near a tornado <span className="italic">report</span> within a certain time and distance it counts as a "count". 
            There are numerous caveats though. Many high profile chasers do not actively have their SpotterNetwork beacon on (such as tour companies, many famous social media profiles etc) and will not be in the list, or at least very rarely, despite seeing multiple tornadoes. A "hit" would also mean that the timing of the ping is aligned with the tornado report time. 
            Also, being close to a tornado does not mean that they have seen the tornado (and one tornado could be reported multiple times!). This all leads to the highscore being a very rough estimate and should be taken with a baseball sized grain of salt!
        </p>
        <p>
            Despite all that, this is a reasonable measure of objectively counting "tornadoes seen" by active SpotterNetwork members. I hope you enjoy it, for what it is worth!
        </p>

        <div className="pb-6 ">
            <div className=" overflow-x-auto">
                <table className='w-max-[700px] table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md text-lg'>
                    <thead>
                        <tr className='bg-transparent  text-white border-solid border-0 border-b border-[#ffffff54]'>
                            <th className='font-bold text-left text-xl p-[8px] min-w-[100px] sm:min-w-[150px] lg:min-w-[200px]'>
                                Est. tornado count
                            </th>
                            <th className='font-bold text-left p-[8px] min-w-[100px] sm:min-w-[150px] lg:min-w-[200px]'>
                                Name
                            </th>
                            <th className='font-bold text-left p-[8px] sm:min-w-[120px] lg:min-w-[200px]'>
                                Twitter
                            </th>
                            <th className='font-bold text-left p-[8px] min-w-[130px] lg:min-w-[150px]'>
                                Last ping
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            //console.log("Favorites full", spotterFavoritesFull)
                        }
                        {isLoading ? (<tr key='loader'>
                            <td className='p-[8px] align-baseline text-center' colSpan="7">
                                <Loader />
                            </td>
                        </tr>) : (highscoreList)
                                .map((item, idx) =>
                                    <>

                                        <SpotterHighscoreRow
                                            tornadoCount={item.tornado_count}
                                            spotterId={item.spotter_id}
                                            slug={item.slug}
                                            name={item?.sn_first_name}
                                            lastName={item?.sn_last_name}
                                            twitter={item?.twitter ? `@${item?.twitter}` : ""}
                                            lastSnPing={item?.last_ping_utc ?? ""}
                                            lastPosition={item?.location_state ?? ""}
                                            key={`${item.spotter_id}_${idx}`}
                                        />
                                    </>
                                ) ?? <tr>
                            </tr>}


                    </tbody>
                </table>
            </div>
        </div>
    </div>
    </div>)
}

export default Highscore