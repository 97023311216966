import React, { useContext, useState, useRef } from "react";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import useMediaQuery from "@mui/material/useMediaQuery";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import LeftSidebar from "components/LeftSidebar";
import RightSidebar from "components/RightSidebar";
import { StormContext } from "contexts/StormContext";


const MainLayout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(StormContext);
	const isMobile = useMediaQuery("(max-width: 1023px)");

  const location = useLocation();
  /*const isHomePage = useMemo(() => {
    return location.pathname === "/";
  }, [location]);*/

  // Toggle sidebar menu
  const toggleMenuOpen = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const toggleBtnRef = useRef(null)
    
  const toggleLeftSidebar = (e) => {
      setIsSidebarOpen(!isSidebarOpen)
  }

  return (
    <>
      <div className="App flex stormmap">
        <div
          className={classNames(
            " fixed top-16 lg:top-0 lg:relative z-[2000] left-0 lg:h-full w-64 transition-transform duration-300 transform overflow-x-hidden",
            {
              "-translate-x-full lg:translate-x-0": !isSidebarOpen,
              "translate-x-0": isSidebarOpen,
							"custom-mobile-sidebar": isMobile,
            }
          )}
        >
          <LeftSidebar menuStatus={true} toggleMenuOpen={toggleMenuOpen} toggleBtnRef={toggleBtnRef} />
        </div>
        <div className="w-full lg:w-[calc(100vw-256px)]">
          <div
            className={"fixed bg-[#012E40] z-[90] w-full lg:w-[calc(100vw-256px)] h-16 flex items-center py-2 lg:py-1 px-4 lg:px-8 shadow-lg"}
          >
            <div className={"flex items-center lg:hidden"}>
              <a href="/">
                <img
                  className="h-8 w-auto mr-2"
                  src="/images/stormcenter-logo.webp"
                  alt="StormCenter storm chasing map logo"
                />
              </a>
              <FontAwesomeIcon icon={faBars} onClick={toggleLeftSidebar} className="text-xl ml-2 cursor-pointer" ref={toggleBtnRef}/>
          </div>
          </div>
          <RightSidebar />
          <div className={"h-[calc(100vh-64px)] mt-16"}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default MainLayout;
