import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";

import AuthService from 'services/AuthService';
import Loader from 'components/loader-gifs/Loader';


const Verify = () => {
  const auth = new AuthService();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const currentURL = "https://stormcenter.app" + window.location.pathname;

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    //console.log(otp)
    try { 
      await auth.verifyCode({otp});
      setLoading(false);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      setError("Verify failed"); 
      console.error('Reset failed', error);
    }
  }


  return (
    <>
      <Helmet>
        <title>Verify your password</title>
        <meta name="description" content="Verify your password by entering the code here." />
        <link rel="canonical" href={currentURL} />
      </Helmet>       
    <section className="h-screen">
      <div className="flex items-center justify-center">
        <div className="w-full max-w-xl mt-24 px-4">
          <div className="bg-transparent p-8 shadow-lg rounded shadow-white">
            <h1 className="text-center text-3xl font-bold mb-6">Verification</h1>
            <form onSubmit={handleSubmit} className="space-y-6" noValidate>
              <div className="space-y-2">
                <label htmlFor="otp" className="block text-sm font-medium text-white-700">
                  Enter Verification Code
                </label>
                <input
                  id="otp"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  name="otp"
                  value={otp}
                  onChange={e => setOtp(e.target.value)}
                  required
                />
                {/* <p className="text-red-500 text-xs italic">Your code is wrong</p> */}
              </div>

              <div className="flex justify-center">
                <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {loading ? <Loader className="w-6 h-6">Verify Code</Loader> : "Verify Code"}
                </button>
              </div>
              {error && <p className='text-center text-red-500 text-xs mt-1'>{error}</p>}
              <div className="mt-4 text-center">
                <Link to="/login" className="text-blue-500 hover:text-blue-800">Skip</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default Verify;
