export const MAP_SETTINGS = {
    default_pitch: 0,
    pitch_history_map: 0, // EventDate
    max_zoom: 12,
    zoom_continental: 4, // View of entire USA
    zoom_local: 8,
    zoom_focus: 12,
    default_latitude: 39.331213739629675,
    default_longitude: -98.80729369136544,
    default_projection: "mercator",
    default_bearing: 0,
}

// Not yet implemented
// Default values for parameters that could customized by session cookies or logged in users
// Should primarily look at session cookie, then user setting, then these defaults.
export const CUSTOMIZED_SETTINGS = {
    // Default values
    map_style: "Navigation",
    time_indicator_format: 0, // 0 = Relative time
    lower_slider_value: -12,
    sendout_tornado_limit: 10, // Will only be handled in backend. Just noted here for reminder.
    sendout_category_limit: 6,  // Will only be handled in backend. Just noted here for reminder.
    checkbox_outlook: "off",
    checkbox_radar: true,
    checkbox_satellite: false,
    checkbox_spotters_default: true,
    checkbox_tornado: true,
    checkbox_hail: true,
    checkbox_wind: false,
    checkbox_other_reports: true,
    checkbox_warnings: true,
    checkbox_watches: true,
    checkbox_tvchannels: false,
    checkbox_legend: true

}