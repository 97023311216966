import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StyledEngineProvider } from '@mui/material/styles';
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { ToastContainer } from "react-toastify";

import Routes from "./routes";
import './styles/global.css';
import {StormProvider} from "./contexts/StormContext";
import { AuthProvider } from './contexts/AuthContext';
import ErrorBoundary from 'components/ErrorBoundary';

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
  {process.env.NODE_ENV === "production" ? (
    <ErrorBoundary>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <StormProvider>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          <StyledEngineProvider injectFirst>
            <Routes />
          </StyledEngineProvider>
        </StormProvider>
      </QueryClientProvider>
    </AuthProvider>
    <ToastContainer theme="colored" closeOnClick />
    </ErrorBoundary>
  ) : (
    <>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <StormProvider>
          {/* <ReactQueryDevtools initialIsOpen={true} /> */}
          <StyledEngineProvider injectFirst>
            <Routes />
          </StyledEngineProvider>
        </StormProvider>
      </QueryClientProvider>
    </AuthProvider>
    <ToastContainer theme="colored" closeOnClick />    
    </>
  )}
  </BrowserRouter>
);
