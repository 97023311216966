import React, { useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar,
  } from "@fortawesome/free-solid-svg-icons";

import { useAuth } from "contexts/AuthContext";
import { timeSince, utcDateToReadable } from 'utils/general-functions';
import { StormContext } from "contexts/StormContext";
import { useToggleFavorite } from 'hooks/useToggleFavorite';

// Images
import youtubeIcon from 'assets/images/logos/youtube.webp';
import twitterIcon from 'assets/images/logos/twitter.webp';
import greenCameraIcon from 'assets/images/icons/green_camera.webp';

const SpotterPopUp = ({d}) => {
    const {state} = useAuth();
    const spotterId = d.object.id;
    const { spotterFavorites } = useContext(StormContext);
    const {toggleSpotterFavorite} = useToggleFavorite();
    const isFavorite = spotterFavorites.includes(spotterId);

    return (
        <div className={"text-black font-bold"}>
            <h3 className="text-black">
            {
            state.isAuthenticated && state.user &&
            <span className='p-[8px]'>
                <FontAwesomeIcon icon={faStar} className={`${isFavorite ? 'text-[green]' : 'text-[grey]'} cursor-pointer`} onClick={() => toggleSpotterFavorite(spotterId)}/>
            </span>
            }
            {d.object.sn_first_name} {d.object.sn_last_name}
            </h3>
            {d.object.youtube_active_livestream && (
            <>
            <span class="text-base  flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3 shadow-md " role="alert" >
            <img src={greenCameraIcon} height="30px" className="shadow-md mr-2" alt="live stream ongoing" />&nbsp; This spotter is currently live streaming:&nbsp; <a target="_blank" className="text-base text-white underline" href={d.object.youtube_active_livestream.url}>{d.object.youtube_active_livestream.title}</a></span>
            </>
            )}
            <div className="flex justify-left space-x-4">
                {d.object.twitter && (
                <a
                    href={`https://www.twitter.com/${d.object.twitter}`}
                    className="text-black mb-2 inline"
                >
                    <div className="flex flex-col items-center">
                    <img src={twitterIcon} height="30px" alt="twitter logo" />
                    <span className="text-black text-base mt-2 ">@{d.object.twitter}</span>
                    </div>
                </a>
                )}
                {d.object.youtube_channel && (
                <a
                    href={`https://www.youtube.com/@${d.object.youtube_channel}`}
                    className="text-black mb-2 inline"
                >
                    <div className="flex flex-col items-center">
                    <img src={youtubeIcon} height="25px" alt="youtube logo" />
                    <span className="text-black text-base mt-2 ">@{d.object.youtube_channel}</span>
                    </div>
                </a>
                )}   
            </div>         
            <p className="text-black">
            Last SpotterNetwork ping: {utcDateToReadable(d.object.last_ping_utc, 'zulu')} (
            {timeSince(d.object.last_ping_utc)} ago)
            </p>
            <p className="text-black">
            Distance covered last 24 h:&nbsp;
            {d.object.distance_last_24h < 50 ? "<50" : d.object.distance_last_24h } miles
            </p>
        </div>
    )
}

export default SpotterPopUp;
