import React, { useEffect, useContext } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress } from '@mui/material';
import {Helmet} from "react-helmet";

import Tooltip from '@mui/material/Tooltip';

import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";
import { getHeatmapColor } from "utils/general-functions";
import { } from "utils/constants";

const EventYearAll = () => {  
    const currentURL = "https://stormcenter.app" + window.location.pathname;  
    const eventService = new EventService();
    const {setEvents, events} = useContext(StormContext);

    useEffect(() => {
        (async () => {
            const response = await eventService.getEventsByYearAll();            
            setEvents(response)        
        })();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>Yearly stats about tornadoes since 2004</title>
                <meta name="description" content="Tornadoes (tornado reports) each year since 2004 with the possibility to get insights of each year." />
                <link rel="canonical" href={currentURL} />
            </Helmet> 
            <div className="overflow-y-auto h-full px-4 md:px-8">
                {/* Other content for EventDate */}
                <h1>Tornadoes by year</h1>
                <p>
                    Get insights on storm stats for each year, by clicking the year. Tornadoes, here, means 'tornado reports', which may not be the same thing as actual surveyed tornadoes.
                </p>
                
                <div className="block pb-5">
                    <TableContainer component={Paper} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Table sx={{ maxWidth: 450 }} aria-label="Tornado Reports Table" style={{ tableLayout: "fixed" }}>
                        <TableHead>
                            <TableRow>
                            <TableCell
                                style={{
                                    position: "sticky",
                                    left: 0,
                                    background: "white",
                                    zIndex: 1,
                                    maxWidth: "70px", // Set maximum width to 50px
                                    width: "70px",   // Set width to 50px
                                }}          
                            >
                                Year
                            </TableCell>
                            
                            <TableCell
                            style={{width: "150px"}}>
                                Tornadoes
                            </TableCell>
                            <TableCell
                            style={{width: "150px"}}>
                                Hail
                            </TableCell>  
                            <TableCell
                            style={{width: "150px"}}>
                                Wind
                            </TableCell>                                                      
                            
                            </TableRow>
                        </TableHead>
                        <TableBody>            
                            {Object.entries(events).length ? Object.entries(events).map((yearItem) => (

                                <TableRow key={yearItem[1].year}>
                                    <TableCell
                                        sx={{
                                            position: "sticky",
                                            left: 0,
                                            background: "white",
                                            zIndex: 1,
                                            maxWidth: "100px", // Set maximum width to 50px
                                            width: "100px",   // Set width to 50px
                                            p: 0, height: 50
                                        }}   
                                        key={`year-${yearItem[1].year}`}             
                                    >
                                        <Box 
                                            sx={{ 
                                                display:' flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: '100%',
                                                height: '100%',

                                                "& > a": {
                                                    display:' flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                }
                                            }} 
                                        >
                                            <a href={`/history/year/${yearItem[1].year}`} className="text-black">{yearItem[1].year}</a>
                                        </Box>
                                    </TableCell>
                                    <TableCell
                                        style={{ background: getHeatmapColor(yearItem[1].sum_tornado_reports || 0, 2500), width: "200px" }}
                                    >
                                        <a href={`/history/year/${yearItem[1].year}`} className="text-black">{yearItem[1]?.sum_tornado_reports}</a>
                                    </TableCell>
                                    <TableCell
                                        style={{width: "200px"}}
                                    >
                                        <a href={`/history/year/${yearItem[1].year}`} className="text-black">{yearItem[1]?.sum_hail_reports}</a>
                                    </TableCell>  
                                    <TableCell
                                        style={{width: "200px"}}
                                    >
                                        <a href={`/history/year/${yearItem[1].year}`} className="text-black">{yearItem[1]?.sum_wind_reports}</a>
                                    </TableCell>                                                                       
                                </TableRow>
                            )) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={4}
                                        sx={{
                                            position: "sticky",
                                            left: 0,
                                            background: "white",
                                            zIndex: 1,
                                            maxWidth: "100px", // Set maximum width to 50px
                                            width: "100px",   // Set width to 50px
                                            p: 0, height: 50
                                        }}            
                                    >
                                        <Box sx={{ textAlign: 'center', py: 2 }}>
                                            <CircularProgress />
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        </Table>
                    </TableContainer>                            
                </div>

            </div>
        </>
    );
};

export default EventYearAll;