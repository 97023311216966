import axiosService from "utils/axios";

export default class SpotterService {

    async searchSpotters(query) {
        return (await axiosService.get(`/get-unrestricted-spotters`, {
            params: query
        })).data
    }

    async getAllSpotters(query) {
        return (await axiosService.get(`/get-active-spotters`, {
            params: query
        })).data
    }   
    
    async getAllStreamers() {
        return (await axiosService.get(`/get-all-streamers`)).data
    }       

    async getFavorites(favoriteIds) {        
        return (await axiosService.post(`/get-favorites`, {
            favorite_ids: favoriteIds.ids
        })).data
    }    

    async getSpotters() {
        return (await axiosService.post(`/get-spotters`)).data
    }

    async getSpotterItem(spotterSlug) {
        return (await axiosService.get(`/get-spotter-item`, {
            params: {spotterSlug}
        })).data
    }    

    async getTornadoesNearbyBySpotter(spotterId) {
        return (await axiosService.get(`/get-nearby-tornadoes-by-spotter`, {
            params: {spotterId}
        })).data
    }

    async getSpotterHighscore() {
        return (await axiosService.get(`/get-spotter-highscore`)).data
    }    

    
    
    async getSpottersByTime(query) {
        return (await axiosService.get(`/get-spotters-by-time`, {
            params: query
        })).data
    }

    async addSpotterFavorite(email, spotter_id) {
        return (await axiosService.post('/add-favorite', { email, spotter_id })).data
    }

    async deleteSpotterFavorite(email, spotter_id) {
        return (await axiosService.post('/remove-favorite', {email, spotter_id})).data
    }
}