
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import {Helmet} from "react-helmet";

import ArticleTemplate from "components/ArticleTemplate";
import { useAuth } from "contexts/AuthContext";
import AuthService from "services/AuthService";



const Settings = () => {
    const auth = new AuthService();
    const { state, updateSettings, logout } = useAuth();
    //console.log("LOCAL STORAGE", localStorage)
    const userSettings = JSON.parse(state.userSettings);
    const navigate = useNavigate();
    const currentURL = "https://stormcenter.app" + window.location.pathname;
    const [receiveNewsletter, setReceiveNewsletter] = useState(userSettings?.receive_newsletter);
    const [receiveMailSendOut, setReceiveMailSendout] = useState(userSettings?.receive_weather_alerts);
    const [alertTornadoLimit, setAlertTornadoLimit] = useState(userSettings?.alert_tornado_limit);
    const [alertCategoryLimit, setAlertCategoryLimit] = useState(userSettings?.alert_category_limit);
    const [riskArea, setRiskArea] = useState(userSettings?.risk_area);
    const [locationOfInterestLat, setLocationOfInterestLat] = useState(userSettings?.poi_latitude);
    const [locationOfInterestLong, setLocationOfInterestLong] = useState(userSettings?.poi_longitude);
    const [alertTornadoReports, setAlertTornadoReports] = useState(userSettings?.receive_tornado_reports);
    const [radiusLocationOfInterest, setRadiusLocationOfInterest] = useState(userSettings?.tornado_report_radius);
    const [defaultSlider, setDefaultSlider] = useState(userSettings?.default_slider ?? -12);
    const [defaultSpotters, setDefaultSpotters] = useState({
        spotters: userSettings?.default_spotters === 'spotters',
        favorites: userSettings?.default_spotters === 'favorites'
    });
    const [defaultRadar, setDefaultRadar] = useState(userSettings?.default_radar);
    const [defaultSatellite, setDefaultSatellite] = useState(userSettings?.default_satellite);
    const [defaultTornadoReports, setDefaultTornadoReports] = useState(userSettings?.default_tornado_reports);
    const [defaultHailReports, setDefaultHailReports] = useState(userSettings?.default_hail_reports);
    const [defaultWindReports, setDefaultWindReports] = useState(userSettings?.default_wind_reports);
    const [defaultOtherReports, setDefaultOtherReports] = useState(userSettings?.default_other_reports);
    const [defaultWarnings, setDefaultWarnings] = useState(userSettings?.default_warnings);
    const [defaultWatches, setDefaultWatches] = useState(userSettings?.default_watches);
    const [defaultTVChannels, setDefaultTVChannels] = useState(userSettings?.default_tv_channels);
    const [defaultLegend, setDefaultLegend] = useState(userSettings?.default_legend);
    const [defaultTimeIndicator, setDefaultTimeIndicator] = useState(userSettings?.default_time_indicator);
    const [defaultMapStyle, setDefaultMapStyle] = useState(userSettings?.default_map_style);
    const [defaultSpotterNetwork, setDefaultSpotterNetwork] = useState(userSettings?.spotter_network_public_id);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (state.isAuthenticated) {
            window.scrollTo(0, 0);
        } else {
            navigate('/login');
        }
    }, [state.isAuthenticated, navigate]);

    const handleSpotterCheckboxes = (e) => {
        const { name, checked } = e.target;
        setDefaultSpotters(prev => ({
            spotters: name === 'default_spotters' ? checked : false,
            favorites: name === 'default_favorites' ? checked : false
        }));
    };

    const handleLogout  = () => {
        logout();
    }    

    const handleSave = async (e) => {
        e.preventDefault();
        setIsSaving(true);

        // Validation for latitude and longitude
        if (riskArea === 'specific_location_of_interest') {
            if (!locationOfInterestLat || !locationOfInterestLong) {
                toast.error("Latitude and Longitude cannot be blank.");
                setIsSaving(false);
                return;
            }
            const lat = parseFloat(locationOfInterestLat);
            const long = parseFloat(locationOfInterestLong);
            if (lat < 25 || lat > 50) {
                toast.error("Latitude must be between 25 and 50.");
                setIsSaving(false);
                return;
            }
            if (long < -125 || long > -70) {
                toast.error("Longitude must be between -70 and -125.");
                setIsSaving(false);
                return;
            }
        }

        if (alertTornadoReports) {
            if (radiusLocationOfInterest < 1 || radiusLocationOfInterest > 100) {
                toast.error("Tornado report radius must be between 1 and 100.");
                setIsSaving(false);
                return;
            }            
        }

        const data = {
            receive_weather_alerts: receiveMailSendOut ?? false,
            receive_newsletter: receiveNewsletter,
            alert_tornado_limit: alertTornadoLimit,
            alert_category_limit: alertCategoryLimit,
            risk_area: riskArea ?? 'continental_usa',
            location_of_interest: locationOfInterestLat && locationOfInterestLong ? `${locationOfInterestLong}, ${locationOfInterestLat}` : null,
            poi_latitude: locationOfInterestLat,
            poi_longitude: locationOfInterestLong,
            receive_tornado_reports: alertTornadoReports ?? false,            
            tornado_report_radius: radiusLocationOfInterest ?? '10',
            default_slider: defaultSlider,
            default_spotters: !defaultSpotters.spotters && !defaultSpotters.favorites ? null : (defaultSpotters.spotters ? 'spotters' : 'favorites'),
            default_radar: defaultRadar ?? false,
            default_satellite: defaultSatellite ?? false,
            default_tornado_reports: defaultTornadoReports ?? false,
            default_hail_reports: defaultHailReports ?? false,
            default_wind_reports: defaultWindReports ?? false,
            default_other_reports: defaultOtherReports ?? false,
            default_warnings: defaultWarnings ?? false,
            default_watches: defaultWatches ?? false,
            default_tv_channels: defaultTVChannels ?? false,
            default_legend: defaultLegend ?? false,
            default_time_indicator: defaultTimeIndicator ?? 'relative',
            default_map_style: defaultMapStyle,
            spotter_network_public_id: defaultSpotterNetwork,
        };

        try {
            await auth.updateSetting({ ...data, email: state.user });
            toast.success("Settings updated successfully");
            localStorage.setItem("userSettings", JSON.stringify(data));
            localStorage.setItem("mainSettings", JSON.stringify(data));
            console.log("Updated with", data)
            updateSettings(data);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
            console.error('Update settings Failed', error);
            toast.error(error.response.data.error);
        }
    };

    return (
        <ArticleTemplate headline='SETTINGS'>
            <Helmet>
                <title>StormCenter Settings</title>
                <meta name="description" content="Customize your experience for StormCenter" />
                <link rel="canonical" href={currentURL} />
            </Helmet>     
            <h2>Mail notifications on severe weather events</h2>
            <p className="text-gray-400 text-xs mt-1">
                Activating this will send an email to your email account every day the limits you set are met. I.e. if you select 5% tornado risk, you will get an email when SPC issues a (minimum) 5% risk. You can use this to get notified on "Big Chase Days" either for Continental USA or for a specific position, e.g. your home.
            </p>
            <div>
                <div className="mb-4">
                    <input
                        type="checkbox"
                        name="receive_weather_alerts"
                        id="receive-mail-sendout"
                        onChange={(e) => setReceiveMailSendout(e.target.checked)}
                        className="leading-tight h-5 w-5"
                        checked={receiveMailSendOut}
                    />
                    <label className="text-white-700 text-base font-bold mb-2 ml-1" htmlFor="receive-mail-sendout">
                        Receive mail sendout on severe outlooks. Sendouts are based on either limit, in other words. If you have selected 5% tornado risk and Moderate Risk, you will receive a mail when SPC issues a 5% tornado risk even if it is a Slight Risk day, and vice versa.
                    </label>
                </div>
                <div className="mb-4 ml-8">
                    <label htmlFor="alert_tornado_limit" className="text-white-700 text-sm font-bold mb-2">
                        Tornado % limit for sendout
                    </label>
                    <select
                        id="alert_tornado_limit"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="alert_tornado_limit"
                        value={alertTornadoLimit}
                        onChange={(e) => setAlertTornadoLimit(e.target.value)}
                        required
                        disabled={!receiveMailSendOut}
                    >
                        <option value="2">2%</option>
                        <option value="5">5%</option>
                        <option value="10">10%</option>
                        <option value="15">15%</option>
                        <option value="30">30% and above</option>
                    </select>
                    <p className="text-gray-400 text-xs mt-1">
                        Select the lowest range tornado risk for which you want notifications. E.g. selecting "5%" will make you receive notifications for <br />
                        "5%", "10%", "15%", "30%" and above tornado risks.
                    </p>
                </div>
                <div className="mb-4 ml-8">
                    <label htmlFor="alert_category_limit" className="text-white-700 text-sm font-bold mb-2">
                        Outlook category limit for sendout
                    </label>
                    <select
                        id="alert_category_limit"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="alert_category_limit"
                        value={alertCategoryLimit}
                        onChange={(e) => setAlertCategoryLimit(e.target.value)}
                        required
                        disabled={!receiveMailSendOut}
                    >
                        <option value="3">Marginal Risk</option>
                        <option value="4">Slight Risk</option>
                        <option value="5">Enhanced Risk</option>
                        <option value="6">Moderate Risk</option>
                        <option value="8">High Risk</option>
                    </select>
                    <p className="text-gray-400 text-xs mt-1">
                        Select the lowest range risk for which you want notifications. Selecting "Slight Risk" will make you receive notifications for <br />
                        "Slight Risk", "Enhanced Risk", "Moderate Risk" and "High Risk" outlooks. Please note that selecting e.g. Marginal Risk will <br />
                        make you receive notifications more or less every day.
                    </p>
                </div>
                <div className="mb-4 ml-8">
                    <h3 className='mt-0'>Location for notifications</h3>
                    <label htmlFor="risk_area" className="text-white-700 text-sm font-bold mb-2 ml-1">
                        Select location for notifications
                    </label>
                    <select
                        id="risk_area"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="risk_area"
                        value={riskArea}
                        onChange={(e) => setRiskArea(e.target.value)}
                    >
                        <option value="continental_usa">Continental USA</option>
                        <option value="specific_location_of_interest">Specific point of interest</option>
                    </select>
                    <p className="text-gray-400 text-xs mt-1">
                        Select if you want to receive notifications for the continental USA or a specific location. If you select a specific location, <br />
                        please enter the latitude and longitude of that location. You will only receive notifications for that location according to the risk <br />
                        limits you have set above. <strong>If you activate a location of interest you will also receive notifications about nearby tornadoes within 10 miles.</strong>
                    </p>
                    <div className="mb-4 ml-8">
                        <label htmlFor="location_of_interest_lat" className="text-white-700 text-sm font-bold mb-2">
                            Latitude
                        </label>
                        <input
                            id="location_of_interest_lat"
                            type="number"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="location_of_interest_lat"
                            value={locationOfInterestLat}
                            onChange={(e) => setLocationOfInterestLat(e.target.value)}
                            required
                            disabled={riskArea === 'continental_usa'}
                        />
                    </div>
                    <div className="mb-4 ml-8">
                        <label htmlFor="location_of_interest_long" className="text-white-700 text-sm font-bold mb-2">
                            Longitude
                        </label>
                        <input
                            id="location_of_interest_long"
                            type="number"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="location_of_interest_long"
                            value={locationOfInterestLong}
                            onChange={(e) => setLocationOfInterestLong(e.target.value)}
                            required
                            disabled={riskArea === 'continental_usa'}
                        />
                    </div>
                    <div className="mb-4">
                        <input
                            type="checkbox"
                            name="receive_tornado_reports"
                            id="receive_tornado_reports"
                            onChange={(e) => setAlertTornadoReports(e.target.checked)}
                            className="leading-tight ml-8 mb-4 h-5 w-5"
                            checked={alertTornadoReports}
                            disabled={riskArea === 'continental_usa'}
                        />
                        <label htmlFor="receive_tornado_reports" className="text-white-700 text-sm font-bold mb-2 ml-1">
                            Receive tornado reports
                        </label>
                        <p className="ml-8 text-gray-400 text-xs mt-1">
                            If you activate this, you will also receive notifications about tornado reports within a given radius from of your location of interest. <br />
                        </p>
                    </div>
                    <div className="mb-4 ml-8">
                        <label htmlFor="radius_location_of_interest" className="text-white-700 text-sm font-bold mb-2">
                            Radius around location of interest (miles)
                        </label>
                        <input
                            id="radius_location_of_interest"
                            type="number"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="radius_location_of_interest"
                            value={radiusLocationOfInterest}
                            onChange={(e) => setRadiusLocationOfInterest(e.target.value)}
                            required
                            disabled={riskArea === 'continental_usa' || !alertTornadoReports}
                        />
                        <p className="text-gray-400 text-xs mt-1">
                            Specify how far from the location of interest point you want to receive alerts for tornado reports. E.g. 10 for a 10 mile radius around the lat/lon point you submitted.<br />
                        </p>
                    </div>
                </div>
                <h2>Default Map settings</h2>
                <div className='mb-4'>
                    <h3 className='mt-0'>Default Layers</h3>
                    <div className="space-y-4">
                        <div>
                            <input
                                type="checkbox"
                                name="default_spotters"
                                id="default_spotters"
                                onChange={handleSpotterCheckboxes}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultSpotters.spotters}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_spotters">
                                Spotters
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_favorites"
                                id="default_favorites"
                                onChange={handleSpotterCheckboxes}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultSpotters.favorites}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_favorites">
                                Favorites
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_radar"
                                id="default_radar"
                                onChange={(e) => setDefaultRadar(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultRadar}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_radar">
                                Radar
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_satellite"
                                id="default_satellite"
                                onChange={(e) => setDefaultSatellite(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultSatellite}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_satellite">
                                Satellite
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_tornado_reports"
                                id="default_tornado_reports"
                                onChange={(e) => setDefaultTornadoReports(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultTornadoReports}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_tornado_reports">
                                Tornado
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_hail_reports"
                                id="default_hail_reports"
                                onChange={(e) => setDefaultHailReports(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultHailReports}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_hail_reports">
                                Hail
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_wind_reports"
                                id="default_wind_reports"
                                onChange={(e) => setDefaultWindReports(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultWindReports}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_wind_reports">
                                Wind
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_other_reports"
                                id="default_other_reports"
                                onChange={(e) => setDefaultOtherReports(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultOtherReports}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_other_reports">
                                Other Reports
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_warnings"
                                id="default_warnings"
                                onChange={(e) => setDefaultWarnings(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultWarnings}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_warnings">
                                Warnings
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_watches"
                                id="default_watches"
                                onChange={(e) => setDefaultWatches(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultWatches}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_watches">
                                Watches
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_tv_channels"
                                id="default_tv_channels"
                                onChange={(e) => setDefaultTVChannels(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultTVChannels}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_tv_channels">
                                TV Channels
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                name="default_legend"
                                id="default_legend"
                                onChange={(e) => setDefaultLegend(e.target.checked)}
                                className="form-checkbox h-5 w-5 text-blue-600"
                                checked={defaultLegend}
                            />
                            <label className="text-white text-lg ml-2" htmlFor="default_legend">
                                Legend
                            </label>
                        </div>
                    </div>
                    <p className="text-gray-400 text-xs mt-1">
                        This will be the default layers shown on the map when it is loaded. It can always be temporarily changed in the layer settings on the map.
                    </p>
                </div>
                <div className="mb-4">
                    <label htmlFor="default_time_indicator" className="text-white-700 text-sm font-bold mb-2">
                        <h3>Primary Time Indicator</h3>
                    </label>
                    <select
                        id="default_time_indicator"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="default_time_indicator"
                        value={defaultTimeIndicator}
                        onChange={(e) => { setDefaultTimeIndicator(e.target.value) }}
                        required
                    >
                        <option value={0}>Relative time</option>
                        <option value={1}>CDT</option>
                        <option value={2}>UTC</option>
                        <option value={3}>Your time</option>
                    </select>
                    <p className="text-gray-400 text-xs mt-1">
                        This setting will decide how the time is displayed for the slider and, to some extent, other indicators in the app.
                    </p>
                </div>
                <div className="mb-4">
                    <label htmlFor="default_map_style" className="text-white-700 text-sm font-bold mb-2">
                        <h3>Map Style</h3>
                    </label>
                    <select
                        id="default_map_style"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="default_map_style"
                        value={defaultMapStyle}
                        onChange={(e) => setDefaultMapStyle(e.target.value)}
                        required
                    >
                        <option value="Navigation">Navigation</option>
                        <option value="Navigation Night">Navigation (Night)</option>
                        <option value="Dark">Dark</option>
                        <option value="Light">Light</option>
                        <option value="Satellite">Satellite</option>
                    </select>
                    <p className="text-gray-400 text-xs mt-1">
                        This will set the default Map style when you load the map. You can change it (temporarily) in the layer settings on the map.
                    </p>
                </div>
                <div className="mb-4">
                    <label htmlFor="default_slider" className="text-white-700 text-sm font-bold mb-2">
                        <h3>Time range slider (left handle): {defaultSlider}</h3>
                    </label>
                    <input
                        id="default_slider"
                        type="range"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="default_slider"
                        value={defaultSlider}
                        onChange={(e) => setDefaultSlider(e.target.value)}
                        required
                        min={-24}
                        max={-1}
                        onBlur={() => {
                            if (defaultSlider < -24) {
                                setDefaultSlider(-24);
                            }
                            if (defaultSlider > -1) {
                                setDefaultSlider(-1);
                            }
                        }}
                    />
                    <p className="text-gray-400 text-xs mt-1">
                        Drag the slider to set the value between -24 and -1. This will be the start (default) time for the slider when you open the app, <br />
                        for the history value, that is how far back in time you will see. Selecting -1 will show only the latest hour, select -24 will show <br />
                        the latest 24 hours.
                    </p>
                </div>
                <h2>Personal info</h2>
                <div className="mb-4">
                    <label htmlFor="spotter_network_public_id" className="text-white-700 text-sm font-bold mb-2">
                        Spotter Network ID
                    </label>
                    <input
                        id="spotter_network_public_id"
                        type="text"
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        name="spotter_network_public_id"
                        value={defaultSpotterNetwork}
                        onChange={(e) => { setDefaultSpotterNetwork(e.target.value) }}
                        required
                    />
                    <p className="text-gray-400 text-xs mt-1">
                        Adding your Spotter Network ID will show your specific information provided to Spotter Network
                    </p>
                </div>
                <h2>Newsletter</h2>
                <div className="mb-4">
                    <input
                        type="checkbox"
                        name="receive_newsletter"
                        id="receive_newsletter"
                        onChange={(e) => setReceiveNewsletter(e.target.checked)}
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={receiveNewsletter}
                    />
                    <label htmlFor="receive_newsletter" className="text-white-700 text-sm font-bold mb-2">
                        Receive newsletter
                    </label>   
                    <p className="text-gray-400 text-xs mt-1">
                        The newsletter will be sent out very rarely and will contain updates and new features on StormCenter.
                    </p>                               
                </div>
                <div className="mb-4 flex justify-between">
                    <button
                        type="button"
                        className={`cursor-pointer border-none rounded !mt-[15px] bg-[#3CA6A6] px-2 py-4 w-100 text-base font-semibold text-white shadow-sm hover:bg-[#35d2d2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative bottom-2 left-1 ${isSaving ? 'cursor-not-allowed' : ''}`}
                        onClick={handleSave}
                        disabled={isSaving}
                    >
                        Save Settings
                    </button>
                </div>
                <hr className="mt-4" />
                <div className="mb-4 flex justify-between">
                    <button
                        type="button"
                        className={`cursor-pointer border-none rounded !mt-[15px] bg-[#e33e02] px-3 py-3 w-["50px"] text-base font-semibold text-white shadow-sm hover:bg-[#f50202] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative bottom-2 left-1`}
                        onClick={handleLogout}
                        disabled={isSaving}
                    >
                        Log out
                    </button>
                </div>                

                
{/* 
                <h2 className="mt-4">Danger zone!</h2>
                <div className="mt-4 flex justify-between">
                    <button
                        type="button"
                        className="cursor-pointer border-none rounded !mt-[15px] bg-[#ff0000] px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-[#ff7700] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 relative bottom-2 left-1"
                        onClick={(e) => { console.log("delete account") }}
                    >
                        Delete Account
                    </button>
                </div> */}
            </div>
        </ArticleTemplate>
    );
};

export default Settings;
