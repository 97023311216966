import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";

import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";
import EventList from "components/storm-history-list/EventList";

export const CustomEvents = () => {
  const eventService = new EventService();

  const contentRef = useRef();

  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const [searchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [continueGettingData, setContinueGettingData] = useState(true);
  const { setEvents, setIsLoading } = useContext(StormContext);

  // const [firstDate, setFirstDate] = useState(null)
  // const [lastDate, setLastDate] = useState(null)

  const dateRange = useMemo(
    () => ({
      first_date: searchParams.get("first_date"),
      last_date: searchParams.get("last_date"),
      first_fixed_date: searchParams.get("first_fixed_date"),
      last_fixed_date: searchParams.get("last_fixed_date"),
    }),
    [searchParams]
  );

  useEffect(() => {
    (async () => {
      let response;

      if (
        (!!dateRange.first_date && !!dateRange.last_date) ||
        (!!dateRange.first_fixed_date && !!dateRange.last_fixed_date)
      )
        response = await eventService.searchEvents({
          ...dateRange,
          limit: 60,
          offset: currentPage * 60,
        });

      const sortedData = response.reduce((acc, event) => {
        const yearMonth = event.year_month;
        acc[yearMonth] = acc[yearMonth] || [];
        acc[yearMonth].push(event);
        return acc;
      }, {});

      if (response.length === 0) {
        setContinueGettingData(false);
      }

      setEvents((prevEvents) => {
        const updatedData = {...prevEvents}

        for (const key of Object.keys(sortedData)) {
          if ( updatedData[key] ) {
            updatedData[key] = [...updatedData[key], ...sortedData[key]];
          } else {
            updatedData[key] = sortedData[key];
          }
        }

        return updatedData;
      });

      setLoading(false);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dateRange, setEvents, setIsLoading]);

  // Infinite Scroll
  useEffect(() => {
    const element = contentRef.current;

    if (!element) {
      return;
    }

    function handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } = element;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        if (continueGettingData && !loading) {
          setCurrentPage((prevPage) => prevPage + 1);
          setLoading(true);
        }
      }
    }

    element.addEventListener("scroll", handleScroll);
    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, [continueGettingData, loading]);

  return (
    <>
      <Helmet>
        <title>Events stats about tornadoes since 2004</title>
        <meta
          name="description"
          content="Tornadoes (tornado reports) each year since 2004 with the possibility to get insights."
        />
        <link rel="canonical" href={currentURL} />
      </Helmet>

      {/* Custom Date Range Select */}
      <div className="h-full flex flex-col">
        {/* <div className="h-14 px-4 md:px-8 py-2">
          <DateRangePicker firstDate={firstDate} lastDate={lastDate} setFirstDate={setFirstDate} setLastDate={setLastDate} />
        </div> */}
        <div
          className="flex-1 overflow-y-auto h-full px-4 md:px-8"
          ref={contentRef}
        >
          <EventList loading={loading} />
        </div>
      </div>
    </>
  );
};
