import React, { useState, useEffect, useContext, useRef } from "react";
import {StormContext} from "contexts/StormContext";
import {
    calculateCDTTimeFromNow,
    calculateZuluTimeFromNow,
    calculateUserTimeFromNow,
    calculateRelativeTimeFromNow,
} from "utils/general-functions";    


export const SliderTimeIndicator = ({}) => {
    
    const {
        sliderValue,
        setTimeFormat,
        timeFormat,
        setCookie
    } = useContext(StormContext); 

    //console.log("timeFormat", timeFormat)

    const changeTimeFormat = (back) => {
        if (back) {
          if (timeFormat === 0) {
            setTimeFormat(1);
          } else if (timeFormat === 1) {
            setTimeFormat(2);
          } else if (timeFormat === 2) {
            setTimeFormat(3);
          } else {
            setTimeFormat(0);
          }
        } else {
          if (timeFormat === 0) {
            setTimeFormat(3);
          } else if (timeFormat === 3) {
            setTimeFormat(2);

          } else if (timeFormat === 2) {
            setTimeFormat(1);
          } else {
            setTimeFormat(0);
          }
        }
      };    

    return (
        <div
        className={
        "flex items-center bg-[#0d2e40] tracking-wider space-x-5 text-[14px] text-white !p-2 rounded-t-2xl"
        }
        >
          <div
          className={
              "bg-white !h-[15px] !w-[15px] rounded-full text-black flex items-center justify-center cursor-pointer"
          }
          onClick={() => changeTimeFormat(true)}
          >
          <span>&#60;</span>
          </div>
          <div className={"flex flex-col items-center"}>
          <div className={"flex items-center tracking-wider h-7"}>
              {timeFormat === 3 && (
              <div className={"min-w-[225px] text-center"}>
                  From &nbsp;
                  <span className={"font-semibold"}>
                  {calculateUserTimeFromNow(sliderValue[0])}
                  </span>
                  &nbsp;to&nbsp;
                  <span className={"font-semibold"}>
                  {calculateUserTimeFromNow(sliderValue[1])}
                  </span>
              </div>
              )}
              {timeFormat === 2 && (
              <div className={"min-w-[225px] text-center"}>
                  From &nbsp;
                  <span className={"font-semibold"}>
                  {calculateZuluTimeFromNow(sliderValue[0])}Z
                  </span>
                  &nbsp;To&nbsp;
                  <span className={"font-semibold"}>
                  {calculateZuluTimeFromNow(sliderValue[1])}Z
                  </span>
              </div>
              )}
              {timeFormat === 1 && (
              <div className={"min-w-[225px] text-center"}>
                  From&nbsp;
                  <span className={"font-semibold"}>
                  {calculateCDTTimeFromNow(sliderValue[0])}
                  </span>
                  &nbsp;to&nbsp;
                  <span className={"font-semibold"}>
                  {calculateCDTTimeFromNow(sliderValue[1])} CDT
                  </span>
              </div>
              )}
              {timeFormat === 0 && (
              <div className={"min-w-[225px] text-center"}>
                  From &nbsp;
                  <span className={"font-semibold"}>{calculateRelativeTimeFromNow(sliderValue[0])}</span>
                  &nbsp;to&nbsp;
                  <span className={"font-semibold"}>
                  {sliderValue[1] === 0 ? "Now" : calculateRelativeTimeFromNow(sliderValue[1])}
                  </span>
              </div>
              )}
          </div>
          <span
              className={
              "uppercase tracking-widest font-semibold text-[12px]"
              }
          >
              {timeFormat === 1
              ? "Central Daylight Time"
              : timeFormat === 2
              ? "UTC/ZULU"
              : timeFormat === 3
              ? "Your Time"
              : "Relative Time"}
          </span>
          </div>
          <div
          onClick={() => changeTimeFormat(false)}
          className={
              "bg-white !h-[15px] !w-[15px] rounded-full text-black flex items-center justify-center cursor-pointer"
          }
          >
            <span>&#62;</span>
          </div>
      </div>
    )
}