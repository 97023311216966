import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import AuthService from 'services/AuthService';
import Loader from 'components/loader-gifs/Loader';

const ResetPassword = () => {
  const auth = new AuthService();
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentURL = "https://stormcenter.app" + window.location.pathname;

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      await auth.reset({otp, password});
      setLoading(false);
      navigate('/login');
    } catch (error) {
      setLoading(false);
      console.error('Reset failed', error);
    }
  }

  return (
    <>
      <Helmet>
        <title>Reset your password</title>
        <meta name="description" content="Reset your password" />
        <link rel="canonical" href={currentURL} />
      </Helmet>       
      <section className="h-screen">
        <div className="flex items-center justify-center">
          <div className="w-full max-w-xl mt-24 px-4">
            <div className="bg-transparent p-8 shadow-lg rounded shadow-white">
              <h1 className="text-center text-3xl font-bold mb-6">Verification</h1>
              <form onSubmit={handleSubmit} className="space-y-6" noValidate>
                <div className="space-y-2">
                  <label htmlFor="otp" className="block text-sm font-medium text-white-700">
                    Enter Verification Code
                  </label>
                  <input
                    id="otp"
                    type="text"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="otp"
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    required
                  />
                  {/* <p className="text-red-500 text-xs italic">Your code is wrong</p> */}

                  <label htmlFor="password" className="block text-sm font-medium text-white-700">
                    Enter New Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="flex justify-center mt-4">
                  <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {
                      loading ? 
                      <Loader className="w-6 h-6">
                        Confirm
                      </Loader>
                      : "Confirm"
                    }
                  </button>
                </div>
                <div className="mt-4 text-center">
                  Already have an account? <Link to="/login">Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      </>
  );
};

export default ResetPassword;
