import React from "react";

import LoadingGif from "assets/images/loading.gif";

function Loader(props) {
  const { className, children } = props;
  return (
    <>
      <div id="events">
        <div className="flex justify-center">
          <img className={className} src={LoadingGif} alt="Loading" />
          <div className="flex items-center">
            {children}
          </div>
        </div>
      </div>
    </>
  );
}

export default Loader;
