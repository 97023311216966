import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ParentComponent({ name, icon, ml, url, isTwitter }) {
  return (
    <>
      <li className={`relative `} style={{ right: `${ml}px ` }}>
        <ul
          className="w-full space-y-1 list-none xl:w-[200px] xl:overflow-x-hidden"
        >
          <li className="">
            <a
              href={url}
              className="text-gray-300 hover:text-white hover:bg-gray-800 group flex item-center gap-x-3 rounded-md !px-2 text-sm leading-6 font-semibold items-center"
            >
              {/* <img src={icon} className="w-auto h-5" alt={""} /> */}
              <div
                className="w-5 h-5 bg-white rounded-full flex items-center justify-center"
                style={{
                  backgroundColor: `${isTwitter ? "#012533" : "white"}`,
                }}
              >
                {isTwitter ? (
                  <img src={icon} className="w-20" />
                ) : (
                  <FontAwesomeIcon
                    icon={icon}
                    className="text-[#012533] text-xs"
                  />
                )}
              </div>
              <span className="relative w-95"> {name}</span>
            </a>
          </li>
        </ul>
      </li>
    </>
  );
}

export default ParentComponent;
