import {
    utcDateToReadable
} from "utils/general-functions";

export const AlertPopupInfo = ({title, objectInfo}) => {
    return (
        <div className={"text-black font-bold"}>
        <h3 className={"text-black font-bold"}>
            {title}
        </h3>
        <div
        className="text-black text-xs"
        dangerouslySetInnerHTML={{
            __html: objectInfo.description.replaceAll('\n', '<br />'),
        }}
        />
            <ul>
                <li>Area: {objectInfo.area_desc}</li>
                <li>Certainty: {objectInfo.certainty}</li>
                <li>Onset time: {utcDateToReadable(objectInfo.onset_utc)}</li>
                <li>Expires time: {utcDateToReadable(objectInfo.expires_utc)}</li>
            </ul>
        </div>
    )
}