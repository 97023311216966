// GEOGRAPHY
export const US_STATES = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AS": "American Samoa",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FM": "Federated States Of Micronesia",
  "FL": "Florida",
  "GA": "Georgia",
  "GU": "Guam",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MH": "Marshall Islands",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "MP": "Northern Mariana Islands",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PW": "Palau",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VI": "Virgin Islands",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

// GENERAL
// Filter init
export const SELECTED_OPTION_FROM = "0";
export const SELECTED_OPTION_TO = "8";
export const TORNADO_PERCENTAGE = "0";
export const TORNADO_COUNT = 0;
export const CURRENT_PAGE = 0;

// OUTLOOKS
export const CATEGORICAL_RISK_FULL_NAME_FROM_CODE = {
  0: "No Thunderstorms",
  2: "Thunderstorm",
  3: "Marginal",
  4: "Slight",
  5: "Enhanced",
  6: "Moderate",
  8: "High Risk"
};


export const CATEGORICAL_INITS = {
  "NO TSTM": 0,
  "TSTM": 0,
  "MRGL": 0,
  "SLGT": 0,
  "ENH": 0,
  "MDT": 0,
  "HIGH": 0
}

export const TORNADO_RISK_INITS = {
  "0": 0,
  "2": 0,
  "5": 0,
  "10": 0,
  "15": 0,
  "30": 0,
  "45": 0
}

export const CATEGORICAL_COLORS = [
  "#ffffff", // NO TSTM  
  "#c0e8c0", // TSTM
  "#01c500", // MRGL
  "#f6f600", // SLGT
  "#e69600", // ENH
  "#ff0000", // MDT
  "#ff00ff", // MDT
];

export const TORNADO_COLORS = [
  "#fafafa",  // 0% - White
  "#FFFF00",  // 2% - Yellow
  "#FFD700",  // 5% - Gold
  "#FFA500",  // 10% - Orange
  "#FF8C00",  // 15% - Dark Orange
  "#FF4500",  // 30% - Red Orange
  "#FF0000",  // 45% - Red
]

// TIME AND DATE
export const MONTH_NAMES = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
}

export const MONTH_NAMES_ARR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const ABBREV_MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];