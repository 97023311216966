import React, {useState, useEffect} from 'react';
import {Helmet} from "react-helmet";
import './Tweets.css'
import {timeSince} from 'utils/general-functions'
import TweetService from "services/TweetService";


const Tweets = ({children}) => {
    return (
        <>
        <Helmet>
            <title>Tweets about storms and tornadoes last 24 hours</title>
            <meta name="description" content="See what storm chasers were tweeting about in the last 24 hours and read up on what happens as storms unfold." />
        </Helmet>        
        <h1>Storm related tweets in the last 24 hours</h1>
        <p>This service does unfortunately no longer exist on StormCenter.</p>
        </>
    )
    // //const [sliderValue, setSliderValue] = useState([-12, 0]);
    // //const now = new Date()
    // const [tweets, setTweets] = useState()
    

    // useEffect(() => {
    //     async function fetchTweets() {
    //         const tweetService = new TweetService();
    //         const data = await tweetService.getAllTweets();
    //         let dataJson = JSON.parse(data)
    //         let tweets
    //         if (dataJson.length > 0) {
    //             tweets = dataJson.filter((tweet) => {
    //                 return true //(new Date(tweet.created_at) > new Date(now.getTime() - (-sliderValue[0] * 60 * 60 * 1000)) && new Date(tweet.created_at) <= new Date(now.getTime() - (-sliderValue[1] * 60 * 60 * 1000)))
    //             })
    //         } else {
    //             tweets = dataJson
    //         }
    //         //console.log(tweets)
    //         setTweets(tweets);
    //     }

    //     fetchTweets();
    // }, []);


    // return (
    //     <div>
    //         <Helmet>
    //             <title>Tweets about storms and tornadoes last 24 hours</title>
    //             <meta name="description" content="See what storm chasers were tweeting about in the last 24 hours and read up on what happens as storms unfold." />
    //         </Helmet>        
    //         <div id="content-container" className="">
    //             <span className="text-center">
    //                 <div id='tweet-list' className='container mx-auto d-block'>
    //                 <h1>Storm related tweets in the last 24 hours</h1>
    //                 </div>
    //                 <div className="flex flex-col gap-2 px-4">
    //                 {tweets && tweets.map((tweet, index) => {
    //                     const tweetedTimeAgo = timeSince(tweet['created_at']) + ' ago'
    //                     return (<a
    //                         key={index}
    //                         title={tweet.twitter_numeric_id}
    //                         rel='noreferrer'
    //                         target="_blank"
    //                         href={"https://www.twitter.com/redirecttoaccount/statuses/" + tweet.tweet_numeric}
    //                         className="flex flex-col md:flex-row items-center gap-2 border-solid border-0 border-b-[2px] border-[#6b7280] px-4 py-2"
    //                     >
    //                         <div className='h-[80px] w-[80px] my-2 overflow-hidden'>
    //                             <img
    //                                 src={tweet.twitter_profile_pic_url}
    //                                 className="h-full w-full object-contain"
    //                                 alt={`${tweet.twitter} profile`}
    //                             />
    //                         </div>
    //                         <strong>
    //                             {tweet.twitter_name ?? ''}
    //                         </strong>
    //                         <span className='w-[70%] leading-5 text-[#c3c7ce] text-center md:text-start'>
    //                             @{tweet.twitter} - {tweetedTimeAgo} <br />
                            
    //                             <span className='text-xl text-white'>{tweet.text}</span>
                                
    //                         </span>
    //                         <span className='w-auto md:w-[20%]'>
    //                             {tweet.image_url ? <img src={tweet.image_url + ':thumb'} alt={`${tweet.twitter} tweet`} /> : null}
    //                         </span>
    //                     </a>)
    //                 })}
    //                 </div>
    //             </span>
    //         </div>
    //     </div>
    // )
}

export default Tweets