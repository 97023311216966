import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AuthService from 'services/AuthService';
import { useAuth } from 'contexts/AuthContext';
import Loader from 'components/loader-gifs/Loader';

const Login = () => {
  const auth = new AuthService();
  const { state, login } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isAuthenticated) {
      navigate('/');
    }
  }, [])

  const handleRememberMeClick = () => {
    setRememberMe((currentOption) => !currentOption)    
  }

  const handleSubmit = async e => {
    setLoading(true);
    //console.log("Login clicked")
    e.preventDefault();
    try {
      const data = await auth.login({email, password});
      setLoading(false);
      const { email: userEmail, token:userToken, userSettings } = data.user;
      //console.log("User data: ", userEmail, userToken, userSettings)
      // if (rememberMe) {
      localStorage.setItem("email", userEmail);
      localStorage.setItem("token", userToken);
      localStorage.setItem("userSettings", JSON.stringify(userSettings));
      localStorage.setItem("mainSettings", JSON.stringify(userSettings));
      // }

      login(userEmail, userToken, JSON.stringify(userSettings))
      console.log("Login successful")
      navigate('/');
    } catch (error) {
      setLoading(false);
      console.error('Login failed:', error);
      toast.error(error.response.data.error);
      if (error.response.data?.errorType === 'NOT_VERIFIED') {
        console.log("Email not verified")
        localStorage.setItem("email", JSON.parse(error.config.data)['email']);
        navigate('/verify');
      }
    }
  }

  return (
    <>
      <Helmet>
        <title>Login to StormCenter</title>
        <meta name="description" content="Login to StormCenter to be able to follow favorite storm chasers, and customize your experience." />
        <link rel="canonical" href={currentURL} />
      </Helmet>     
<section className="h-screen">
  <div className="container mx-auto px-4">
    <div className="flex items-center justify-center h-full">
      <div className="w-full max-w-lg mt-24">
        <div className=" shadow-md rounded px-8 pt-6 pb-8 mb-4 shadow-white">
          <h1 className="text-center text-3xl mt-3 mb-6 text-white">Login</h1>
          <form onSubmit={handleSubmit} className="mb-4" noValidate>
            <div className="mb-4">
              <label htmlFor="email" className="block text-white-700 text-sm font-bold mb-2">
                E-Mail Address
              </label>
              <input
                id="email"
                type="email"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
              />
              {/* <p className="text-red-500 text-xs italic">Email is invalid</p> */}
            </div>

            <div className="mb-6">
              <label htmlFor="password" className="block text-white-700 text-sm font-bold mb-2">
                Password
              </label>
              <div className="flex justify-between">
                <input
                  id="password"
                  type="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                <Link to="/forgot" className="inline-block align-baseline font-bold text-sm text-blue-200 hover:text-blue-800 ml-2">
                  Forgot Password?
                </Link>
              </div>
              {/* <p className="text-red-500 text-xs italic">Password is required</p> */}
            </div>

            <div className="mb-4">
              <input
                type="checkbox"
                name="remember"
                id="remember"
                onChange={handleRememberMeClick}
                className="leading-tight"
              />
              <label className="text-white-700 text-sm font-bold mb-2" htmlFor="remember">
                Remember Me
              </label>
            </div>

            <div className="flex items-center justify-center">
              <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                {loading ? <Loader className="w-6 h-6">Login</Loader> : "Login"}
              </button>
            </div>
            <div className="mt-4 text-center">
              Don't have an account? <Link to="/register" className="text-blue-500 hover:text-blue-800">Create One</Link>
            </div>
          </form>
        </div>
      </div>

    </div>
    <div className="justify-center">
          <h1>Features as a registered user</h1>
          <p>Registering is free!</p>
          <p>
          <ul>
            <li>Add favorite spotters to follow and see them on the map (with a trail)</li>
            <li>Save settings so you always have the same layer settings on startup, such as Radar, Tornado reports etc but also default slider settings etc.</li>
            <li>Receive email notifications on your choice of categorical and tornado risk. I.e. you can choose to get an email whenever there is e.g. a 15% tornado risk or Moderate Risk.</li>
            <li>Receive email notifications on categorical or tornado risk for a given (lat/lon) position, such as your home or target area.</li>
            <li>Receive email notifications on tornado reports nearby your given (lat/lon) position at a certain radius. This can be used to see if there is a tornado report near your home.</li>
          </ul>

          </p>
    </div>    
  </div>
</section>
</>

  );
};

export default Login;
