export const EventBox = ({formatedDate, significantClass, i, bg, dateStr, item, outlookLabelFull, affectedStates}) => {
    if (bg === 'NO TSTM') {
        bg = 'nostorms'
    }
    return item && (
        <a
            href={`/history/${formatedDate}`}
            className={`outlook-item block w-[48%] max-w-[100%] sm:w-fit ${significantClass ? "sm:min-w-[80%] md:min-w-[50%] lg:min-w-[30%]" : ""}`}
            key={i}
        >
            <h6
                id={dateStr}
                className={`btn block outlook-counter historical lvl-bg-${bg} min-w-full`}
            >
                <span className="eventdate">{dateStr}</span>

                {item.image_url && (<img
                    src={item.image_url}
                    className="significant-image"
                />)}

                {item.day1_max_categorical != null ? (<>
                    <br/>
                    <div className="day0MaxOutlook">
                        <h3 className="mt-2">
                            {outlookLabelFull}{item.day1_max_tornado > 0 ? "/" : null}
                            {item.day1_max_tornado > 0 && (<span className="day0MaxTor">
                                {item.day1_max_tornado}%{" "}
                            </span>)}
                        </h3>
                    </div>
                </>) : (<div className="day0MaxOutlook">
                    <h3>No event data</h3>
                </div>)}

                {item.tornado_reports != null ? (item.tornado_reports === 0 ? (
                    <div className="tornado-count">-</div>) : (
                    <div className="tornado-count">
                        &#127786; {item.tornado_reports}&nbsp;<br />
                        <span className="affected-states">
                            {affectedStates?.length > 0 ? `${affectedStates.join(", ")}` : ""}
                            </span>
                    </div>)) : (<i>Data unavailable</i>)}

                {item.tornado_reports != null && (<div className="hail_wind">
                    Hail: {item.hail_reports} &nbsp;&nbsp; Wind:{" "}
                    {item.wind_reports}
                </div>)}

                {item.name && <br/>}
                {item.name}
            </h6>
        </a>
    )
}