import { useQuery } from '@tanstack/react-query';
import MapService from './MapService'; // Adjust the import according to your file structure

const mapService = new MapService();

// Default query options
const defaultQueryOptions = {
  staleTime: 5 * 60 * 1000, // 5 minutes
  cacheTime: 10 * 60 * 1000, // 10 minutes
  refetchOnWindowFocus: false, // Refetch when window regains focus
  refetchInterval: 2 * 60 * 1000,
};


// Backend queries
const fetchMetaData = async () => {
  return await mapService.getMetaData();
};

const fetchWeatherAlerts = async () => {
  return await mapService.getWeatherAlerts();
};

const fetchHistoricalOutlook = async (eventDate) => {
  return await mapService.getHistoricalOutlook(eventDate);
};

const fetchHistoricalWarnings = async (eventDate) => {
  return await mapService.getHistoricalWarnings(eventDate);
};

const fetchActiveTvChannels = async () => {
  return await mapService.getActiveTvChannels();
};

const fetchSpotterReports = async () => {
  return await mapService.getSpotterReports();
};

const fetchSpcReports = async () => {
  // Will fetch both SPC and Spotter reports through a select union query
  return await mapService.getSpcReports()
};

const fetchHistoricalSpcReports = async (eventDate, reportType) => {
  return await mapService.getHistoricalSpcReports(eventDate, reportType);
};

const fetchSpotterPositions = async (spotters, upperTime, lowerTime) => {
  return await mapService.getSpotterPositions(spotters, upperTime, lowerTime);
};

const fetchChristoffer = async () => {
  return await mapService.getChristoffer();
};

// Query hooks

const useFetchMetaDataCached = () => {
  return useQuery({
    queryKey: ['metaData'],
    queryFn: fetchMetaData,
    ...defaultQueryOptions,
  });
};

const useFetchWeatherAlertsCached = () => {
  //console.log('Using cache for warnings/watches');
  return useQuery({
    queryKey: ['weatherAlerts'],
    queryFn: fetchWeatherAlerts,
    ...defaultQueryOptions,
  });
};

const useFetchHistoricalOutlookCached = (eventDate) => {
  return useQuery({
    queryKey: ['historicalOutlook', eventDate],
    queryFn: () => fetchHistoricalOutlook(eventDate),
    ...defaultQueryOptions,
  });
};

const useFetchHistoricalWarningsCached = (eventDate) => {
  return useQuery({
    queryKey: ['historicalWarnings', eventDate],
    queryFn: () => fetchHistoricalWarnings(eventDate),
    ...defaultQueryOptions,
  });
};

const useFetchActiveTvChannelsCached = () => {
  return useQuery({
    queryKey: ['activeTvChannels'],
    queryFn: fetchActiveTvChannels,
    ...defaultQueryOptions,
  });
};

const useFetchSpotterReportsCached = () => {
  //console.log('Using cache for SpotterReports');
  return useQuery({
    queryKey: ['spotterReports'],
    queryFn: fetchSpotterReports,
    ...defaultQueryOptions,
  });
};

const useFetchSpcReportsCached = () => {
  //console.log('Using cache for SPC');
  return useQuery({
    queryKey: ['spcReports'],
    queryFn: fetchSpcReports,
    ...defaultQueryOptions,    
  });
};

const useFetchHistoricalSpcReportsCached = (eventDate, reportType) => {
  return useQuery({
    queryKey: ['historicalSpcReports', eventDate, reportType],
    queryFn: () => fetchHistoricalSpcReports(eventDate, reportType),
    ...defaultQueryOptions,
  });
};

const useFetchSpotterPositionsCached = (spotters, upperTime, lowerTime) => {
  return useQuery({
    queryKey: ['spotterPositions', spotters, upperTime, lowerTime],
    queryFn: () => fetchSpotterPositions(spotters, upperTime, lowerTime),
    ...defaultQueryOptions,
  });
};

const useFetchChristoffer = () => {
  return useQuery({
    queryKey: ['christoffer'],
    queryFn: fetchChristoffer,
    ...defaultQueryOptions,
  });
};

// Export
const MapServiceCache = {
  useFetchMetaDataCached,
  useFetchWeatherAlertsCached,
  useFetchHistoricalOutlookCached,
  useFetchHistoricalWarningsCached,
  useFetchActiveTvChannelsCached,
  useFetchSpotterReportsCached,
  useFetchSpcReportsCached,
  useFetchHistoricalSpcReportsCached,
  useFetchSpotterPositionsCached,
  useFetchChristoffer,
};

export default MapServiceCache;
