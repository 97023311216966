import React from 'react'
import {Helmet} from "react-helmet";

import ArticleTemplate from 'components/ArticleTemplate';

const Feedback = () => {
    return (
        <ArticleTemplate headline='Feedback about StormCenter'>
        <Helmet>
        <title>Give feedback about StormCenter</title>
        <meta name="description" content="Help me by providing feedback about your experience using StormCenter." />
        </Helmet>               
            <p>
                It is difficult to develop blindly and your constructive feedback would be greatly appreciated. I would love feedback on:
                <ul>
                    <li>Bugs and error messages you experience</li>
                    <li>Ideas on new features that would be useful and cool</li>
                    <li>Design or functions that does not seem to be working properly</li>
                </ul>
            </p>
            <p>
                Please provide feedback to StormCenter by:
                <ul>
                    <li>emailing christoffer@stormchasingusa.com</li>
                    <li>Sending a tweet to @stormchasingusa</li>
                </ul>
            </p>     
               
        </ArticleTemplate>
    )
}

export default Feedback
