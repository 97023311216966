import axiosService from "utils/axios";

export default class GeneralService {

    async getTornadoReports() {
        //console.log("Retrieving Tornado data from backend...(not cache)")
        return (await axiosService.post(`/get-tornado-reports`)).data
    }

    async getTornadoReportsList() {
        //console.log("Retrieving Tornado data from backend...(not cache)")
        return (await axiosService.post(`/get-tornado-reports-list`)).data
    }

    async getTornadoReportItem(tornadoReportId) {
        return (await axiosService.get(`/get-tornado-report-item`, {
            params: {tornadoReportId}
        })).data
    }

    async getNearbyTornadoReports(tornadoReportId) {
        //console.log("Retrieving Tornado data from backend...(not cache)")
        return (await axiosService.get(`/get-nearby-tornado-reports`, {
            params: {tornadoReportId}
        })).data
    }    
}