import React from 'react';
import { getTimeFromTimestamp, getSunlightIcon } from 'utils/general-functions';
import { CATEGORICAL_RISK_FULL_NAME_FROM_CODE } from 'utils/constants';


function TornadoRow({ tornadoReport, comparedDate = '1970-01-01', spottersNearbyCount = "-" }) {
    return (
        <tr className={` hover:bg-gray-500 ${String(comparedDate) === String(tornadoReport.datetime_utc).slice(0,10) ? 'bg-red-900 even:bg-red-900' : 'even:bg-[#ffffff1a]'}`}>
            <td className='p-[8px] min-w-[120px] align-baseline'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.datetime_utc.slice(0,10)}</a>
            </td>
            <td className='p-[8px] min-w-[90px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{getTimeFromTimestamp(tornadoReport.datetime_utc)} Z</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{getSunlightIcon(tornadoReport.sunlight)}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.county}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.state}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.comments}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.data_source === "spc" ? "SPC" : tornadoReport.reporter}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.tags ? tornadoReport.tags.join(", ") : ""}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.f_scale_enhanced ? tornadoReport.f_scale_enhanced.replaceAll("EF-U", "") : ""}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.injuries ? `🩹 ${tornadoReport.injuries}` : ""} {tornadoReport.fatalities ? `💀 ${tornadoReport.fatalities}` : ""}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{CATEGORICAL_RISK_FULL_NAME_FROM_CODE[tornadoReport.max_categorical_risk]}</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.max_tornado_risk }%</a>
            </td>
            <td className='p-[8px] align-top'>
                <a href={`/tornado-reports/${tornadoReport.report_id}`} className="no-underline text-current">{tornadoReport.spotters_nearby?.length ?? "-"}</a>
            </td>
        </tr>
    );
}

export default TornadoRow;
