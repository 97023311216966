import {
    ScatterplotLayer,
    ArcLayer
  } from "@deck.gl/layers";

import {
    hexToRgb
} from "utils/general-functions";
import SpotterPopUp from "components/storm-map/SpotterPopup";


// Used to show arcs of decreasing opacity with age.
function getArcOpacityAndColor(d, arcPos) {
    //console.log("D", d, (d.index.toFixed(2)))
    let nbrIndex = d.index.toFixed(2)
    const opacity = Math.max(255 * (1 - (nbrIndex / d.arc_length)),100) ;
    //console.log("d.index", nbrIndex, "OPACITY", opacity, "trail_color", d.trail_color)
    let spotterTrailColorArr = hexToRgb(d.trail_color, opacity)      
    //spotterTrailColorArr.push(opacity);
    //console.log("Color original", d.trail_color, "colorarr", spotterTrailColorArr, "opa", opacity)
    if (arcPos==='source') {
        return [255,255,255,opacity]; // White
    } else {        
        return spotterTrailColorArr // Not sure why this has to be reversed. Source should be white, but it gets wrong.
    }
    
 }

export const createSpottersLayer = ({
    activeSpotters,
    spottersCheck,
    spotterFavoritesCheck,
    displayPopup,
    setModalType
  }) => {
    return new ScatterplotLayer({
        id: "scatterplot-layer",
        data: activeSpotters,
        getPosition: (d) => d.position,
        zIndex: 2, //getRadius: d => d.size,
        radiusScale: 10, //calculateRadiusScale(viewport.zoom),
        radiusMinPixels: 4,
        lineWidthMinPixels: 2,
        getLineWidth: 2,
        stroked: true,
        getLineColor: (d) => {
          if (d.youtube_active_livestream) {
            return [0, 0, 0, 256];
          } else {
            return [0, 0, 0, 44];
          }
        },
        getFillColor: (d) => {
          if (d.youtube_active_livestream != null) {
            return [124, 252, 0, 255];
          }
          else if (d.twitter) {
            return [52, 164, 235, 255];
          } else {
            return [255, 0, 0, 255];
          }
        },
        visible: (spottersCheck || spotterFavoritesCheck),
        pickable: true,
        onClick: (d) => {
          setModalType("Spotter");
          displayPopup(<SpotterPopUp d={d}/>
          );
        },
      })
}    


/// ARC LAYERS ///
export const createArcLayerSpotterPos = ({
    favoritesPositions,
    spotterFavoritesCheck,
  }) => {
    //console.log("createArcLayerSpotterPos favoritesPositions", favoritesPositions)
    return new ArcLayer({
        id: 'arc-layer-favorites',
        data: favoritesPositions,
        pickable: true,
        getWidth: 4,
        visible: spotterFavoritesCheck,
        onClick: (d) => {
        <>
        <div className={"text-black font-bold"}>
            <h3 className="text-black">
            Spotter trail
            </h3>
        </div>
        </>
        },
        getSourcePosition: (d) => d.source,
        getTargetPosition: (d) => d.target,
        getSourceColor: (d) => {
          //return [0, 0, 0, 255]
          return getArcOpacityAndColor(d, 'source') 
        },
        getTargetColor: (d) => {
          //return [0, 0, 0, 255]
          return getArcOpacityAndColor(d, 'target')
        }
    })
}