import React from "react";
/*          <img
            src="/images/stormcenter-logo.png"
            alt="Article Image"
            className="h-140 w-full object-cover"
          />
          <div
            className="absolute inset-0 bg-black opacity-40"
            style={{ zIndex: 1 }}
          ></div>          
*/          
const ArticleTemplate = ({ children, headline }) => {
  return (
    <>
      <div id='article-template' className="shadow-md">
        <div className="relative">
          {/* Adding an overlay for the image */}

          <div
            className="relative inset-0 flex items-center justify-left p-3"
            style={{ zIndex: 2 }}
          >
            <div
              className="text-white  p-4 "
              style={{ backgroundColor: "#012E40" }}
            >
              <h1 className="text-3xl font-bold">{headline}</h1>
              <p className="text-sm text-gray-300">{children}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleTemplate;
