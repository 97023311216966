import SpottersFavorites from "pages/spotters/SpottersFavorites"
import Spotters from "pages/spotters/Spotters"
import SpotterItem from "pages/spotters/SpotterItem"
import SpotterHighscore from "pages/spotters/Highscore"

export const spottersRoutes = [
    {
        path: '/spotters',
        children: [
            {index: true, element: <Spotters />},
            {path: '/spotters/highscore', element: <SpotterHighscore />},
            {path: ':spotterSlug', element: <SpotterItem />},
            {path: 'favorites', element: <SpottersFavorites />},
        ]
    }
]