import React, { useEffect, useContext, useState } from "react";
import {Helmet} from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";

import NotFound from "pages/NotFound";
import Tooltip from '@mui/material/Tooltip';

import { EventBox } from "components/storm-history-list/EventBox";
import Pie from "components/Chart";

import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";
import { formatDate } from "utils/general-functions";
import { MONTH_NAMES, TORNADO_RISK_INITS, CATEGORICAL_INITS, CATEGORICAL_COLORS, TORNADO_COLORS } from "utils/constants";

import tornadoSimpleIcon from "assets/images/icons/tornado-simple.webp";
import hailIcon from "assets/images/icons/map/hail-round.webp";
import windWhiteIcon from "assets/images/icons/wind-icon-white.webp";

const EventYearMonth = () => {    
    const currentURL = "https://stormcenter.app" + window.location.pathname;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);        
    const eventService = new EventService();
    const {setEvents, events} = useContext(StormContext);

    const yyyyMmPattern = /^\d{4}-\d{2}$/;
    const { eventYearMonth } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const response = await eventService.getEventsByYearMonth({
                year_month: eventYearMonth,            
                limit: 1000, // Hack to not fall into default limit
            });

            const sortedData = response.reduce((acc, event) => {
                const yearMonth = event.year_month;
                acc[yearMonth] = acc[yearMonth] || [];
                acc[yearMonth].push(event);
                return acc;
            }, {});

            setEvents((prev) => {
                if (!prev[eventYearMonth]) {
                    return sortedData
                } else if ( sortedData[eventYearMonth] ) {
                    return { [eventYearMonth]: [...prev[eventYearMonth], ...sortedData[eventYearMonth]]} 
                } else {
                    return prev
                }
            })
            
        })();
    }, [eventYearMonth]);

    function getNextYearMonth(currentYearMonth) {
        const [year, month] = currentYearMonth.split('-').map(Number);
      
        // Check if the input is valid
        if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
          throw new Error('Invalid input year-month format');
        }
      
        // Calculate the next year and month
        let nextYear = year;
        let nextMonth = month + 1;
      
        if (nextMonth > 12) {
          nextYear++;
          nextMonth = 1;
        }
      
        // Format the result as 'YYYY-MM'
        return `${nextYear}-${nextMonth.toString().padStart(2, '0')}`;
      }    


      function getPreviousYearMonth(currentYearMonth) {
        const [year, month] = currentYearMonth.split('-').map(Number);
      
        // Check if the input is valid
        if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
          throw new Error('Invalid input year-month format');
        }
      
        // Calculate the previous year and month
        let previousYear = year;
        let previousMonth = month - 1;
      
        if (previousMonth < 1) {
          previousYear--;
          previousMonth = 12;
        }
      
        // Format the result as 'YYYY-MM'
        return `${previousYear}-${previousMonth.toString().padStart(2, '0')}`;
      }
  


    const PreviousMonth = () => {
        return (
            eventYearMonth > '2005-03' && (
          <button
            className={
              "border border-white cursor-pointer bg-transparent text-white rounded-[4px] p-1 "
            }
            onClick={() => {navigate(`/history/month/${getPreviousYearMonth(eventYearMonth)}`)}}
          >
            &lt; Previous month
        </button>   
            )   
        )
      }
    
      const NextMonth = () => {
        return (
            eventYearMonth < String(new Date().getFullYear()) + String(new Date().getMonth()) && (
            <button
                className={
                "border border-white cursor-pointer bg-transparent text-white rounded-[4px] p-1 "
                }
                onClick={() => {navigate(`/history/month/${getNextYearMonth(eventYearMonth)}`)}}
            >
                Next month &gt;
            </button>      
            )
        )
      }     
    
    
    if (!yyyyMmPattern.test(eventYearMonth)) {
        return <NotFound /> // Return null to prevent rendering EventDate content
    }        
    
    const [year, month] = eventYearMonth.split('-');

    return (
        <>
            <Helmet>
                <title>Stats about storms and tornadoes in {eventYearMonth}</title>
                <meta name="description" content={`How was ${eventYearMonth} in terms of tornadoes and storms in the USA? See distribution of outlooks, tornado risks etc`} />
                <link rel="canonical" href={currentURL} />
            </Helmet> 
            <div className="px-4 md:px-8 pb-8">
                {windowWidth >= 640 ?  
                    // DESKTOP
                    <div className={"flex flex-col sm:flex-row sm:justify-between items-center text-center gap-2"}>

                    <div className={"order-1 sm:mr-0 sm:order-1"}>
                        <PreviousMonth />
                    </div>
                    <div className={"order-3 sm:order-2"}>
                        <h1>Storms and tornadoes in the USA in {MONTH_NAMES[month]} {year}</h1>
                    </div>
                    <div className={"order-2 sm:ml-0 sm:order-3"}>
                        <NextMonth />
                    </div>
                    </div>
                    : // MOBILE
                    <div className={"grid grid-cols-1 text-center"}>
                    <div className='flex justify-around'>
                        <div className={""}>
                        <PreviousMonth />
                        </div>
                        <div className={""}>
                        <NextMonth />
                        </div>
                    </div>
                    <div className={"my-3"}>
                        <h1>Storms and tornadoes in the USA in {eventYearMonth}</h1>
                    </div>
                    </div>
                }                  
                
                {/* Other content for EventDate */}
                {
                    Object.entries(events).map(([year_month, event], i) => {
                        const [year, month] = year_month.split('-');

                        const monthName = MONTH_NAMES[month]
                        let totalTornadoReports = 0;
                        let totalHailReports = 0;
                        let totalWindReports = 0;
                        let tornadoRiskList = TORNADO_RISK_INITS
                        let categoricalList = CATEGORICAL_INITS
                        const filteredEvent = event.filter((day_event) => !!day_event.day1_max_categorical )

                        for (const ev of filteredEvent) {
                            //console.log(ev.day1_max_categorical, categoricalList)
                            totalTornadoReports += ev.tornado_reports;
                            totalHailReports += ev.hail_reports;
                            totalWindReports += ev.wind_reports;
                            categoricalList[ev.day1_max_categorical] += 1;
                            tornadoRiskList[ev.day1_max_tornado] += 1;
                        }
                        
                        const outlooksPieData = {
                            labels: Object.keys(categoricalList).filter((_, index) => Object.values(categoricalList)[index] !== 0),
                            datasets: [{
                                data: Object.values(categoricalList).filter(value => value !== 0), // Filter out values equal to 0
                                backgroundColor: [...CATEGORICAL_COLORS].filter((_, index) => Object.values(categoricalList)[index] !== 0), // Filter corresponding colors
                                hoverOffset: 6,
                            }],
                        }
                        
                        const tornadoPieData = {
                            labels: Object.keys(tornadoRiskList).filter((_, index) => Object.values(tornadoRiskList)[index] !== 0).map(key => `${key}%`),
                            datasets: [{
                                data: Object.values(tornadoRiskList).filter(value => value !== 0), // Filter out values equal to 0
                                backgroundColor: [...TORNADO_COLORS].filter((_, index) => Object.values(tornadoRiskList)[index] !== 0), // Filter corresponding colors
                                hoverOffset: 6,
                            }],
                        };

                        //console.log(outlooksPieData)

                        return (
                            <div className="block" key={i}>
                                
                                <div className="flex items-center">
                                    <h5 className="w-[150px] hidden sm:inline">SPC Reports</h5>
                                    {/* Tornado Reports */}
                                    <Tooltip title="SPC Tornado reports" className="text-xl">
                                        <div className="flex items-center mr-2 sm:mr-4">
                                            <div className="bg-[#ff0000] border-2 border-solid text-black rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-2xl text-black text-bold">{totalTornadoReports}</span>
                                            </div>
                                            <span className="ml-2 mr-4">
                                                <img src={tornadoSimpleIcon} width="40px" alt="tornado" />
                                            </span>
                                        </div>
                                    </Tooltip>

                                    {/* Hail Reports */}
                                    <Tooltip title="SPC Hail reports" className="text-xl">
                                        <div className="flex  items-center mr-2 sm:mr-4">
                                            <div className="bg-[#e3e3e3] border-2 border-solid  text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-xl text-black text-bold">{totalHailReports}</span>
                                            </div>
                                            <span className="ml-2 mr-4">
                                                <img src={hailIcon} width="40px" alt="hail" />
                                            </span>
                                        </div>
                                    </Tooltip>

                                    {/* Wind Reports */}
                                    <Tooltip title="SPC Wind reports" className="text-xl">
                                        <div className="flex items-center">                                    
                                            <div className="bg-[#e3e3e3] border-2 border-solid text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-xl text-black font-bold">{totalWindReports}</span>
                                            </div>
                                            <span className="ml-2 mr-4">
                                                <img src={windWhiteIcon} width="40px" alt="wind" />
                                            </span>
                                        </div>
                                    </Tooltip>
                                </div>
                                <div className="flex flex-col lg:flex-row items-start justify-between mb-10">
                                    <div className="w-full lg:w-1/2">
                                        <div className="flex mt-3 items-center">                                           
                                        {/* Pie Chart */}
                                        <div className="max-w-[320px] lg:max-w-[400px] ml-0 lg:mx-auto w-full">
                                            <Pie data={outlooksPieData} />
                                            <h2 className="block text-center">Outlooks</h2>
                                        </div>
                                        </div>


                                            
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <div className="flex items-center mt-2">                                            
                                            {/* Pie Chart */}
                                            <div className="max-w-[320px] lg:max-w-[400px] ml-0 lg:mx-auto w-full">
                                                <Pie data={tornadoPieData} />
                                                <h2 className="text-center">Tornado risks</h2>
                                            </div>
                                        </div>


                                    </div>                            
                                </div>
                                <div className={'flex w-full flex-wrap gap-2 mt-2'}>
                                <h1 className="block w-full mb-3"> {monthName} {year} </h1>

                                    {event.map((item, i) => {
                                        item.event_date = String(new Date(item.event_date).toLocaleString("CDT"));

                                        let bg = item.day1_max_categorical ? item.day1_max_categorical : item.day1_max_categorical ? item.day1_max_categorical : "nostorms";

                                        let dateStr = item.event_date.slice(0, 10).replace(",", "");
                                        let formatedDate = formatDate(item.event_date);
                                        let significantClass = "";

                                        if (item.tornado_reports && parseFloat(item.tornado_reports) > 20) {
                                            significantClass = "significant";
                                        } else if (item.significance_rank >= 2) {
                                            significantClass = "significant";
                                        }

                                        var outlookLabelFull = item.day1_max_categorical ? item.day1_max_categorical : "NO TSTM";

                                        const affectedStates = item.affected_states && item.affected_states[0].tornadoReports.map((report) => report.state);
                                        
                                        return (                                
                                            <EventBox 
                                                key={i}
                                                formatedDate        = {formatedDate}
                                                significantClass    = {significantClass}
                                                i                   = {i}
                                                bg                  = {bg}
                                                dateStr             = {dateStr}
                                                item                = {item}
                                                outlookLabelFull    = {outlookLabelFull}
                                                affectedStates      = {affectedStates}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </>
    );
};

export default EventYearMonth;