import { GeoJsonLayer } from '@deck.gl/layers';

import { OutlookPopupInfo } from 'components/storm-map/OutlookPopupInfo'; 
import {
    hexToRgb,
} from "utils/general-functions";


// CATEGORICAL OUTLOOK
export const createOutlookDaysCategoricalLayer = ({
    outlookDaysData,
    checkOutlookDays,
    displayPopup,
    setModalType
  }) => {
    const outlookDaysFeatureCollection = {
      type: "FeatureCollection",
      features: outlookDaysData,
    };
    //console.log("outlookDaysFeatureCollection", outlookDaysFeatureCollection.features.categorical)
    return new GeoJsonLayer({
      id: "geojsoncategoricaldays",
      data: outlookDaysFeatureCollection.features.categorical,
      filled: true,
      pickable: true,
      opacity: 0.1,
      visible: checkOutlookDays,
      stroked: true,
      lineWidthScale: 20,
      lineWidthMinPixels: 4,
      getFillColor: (d) => hexToRgb(d.properties.fill),
      getLineColor: (d) => hexToRgb(d.properties.stroke),
      getLineWidth: 4,
      getPointRadius: 100,
      onClick: (d) => {
        setModalType("Outlook");
        displayPopup(
          <OutlookPopupInfo 
            outlookGeneral={outlookDaysFeatureCollection.features}
            clickedOutlook={d.object}
          />
        );
      },
    });
  };


// TORNADO OUTLOOK
export const createOutlookDaysTornadoLayer = ({
    outlookDaysData,
    checkOutlookDays,
    displayPopup,
    setModalType
  }) => {
    const outlookDaysTornadoFeatureCollection = {
      type: "FeatureCollection",
      features: outlookDaysData,
    };
  
    return new GeoJsonLayer({
      id: "geojsondayscategorical",
      data: outlookDaysTornadoFeatureCollection.features.tornado,
      filled: true,
      pickable: true,
      opacity: 0.3,
      visible: checkOutlookDays,
      stroked: true,
      lineWidthScale: 20,
      lineWidthMinPixels: 2,
      getFillColor: (d) => hexToRgb(d.properties.fill),
      getLineColor: (d) => hexToRgb(d.properties.stroke),
      getLineWidth: 2,
      getPointRadius: 100,
      onClick: (d) => {
        setModalType("Outlook");
        displayPopup(
          <OutlookPopupInfo 
            outlookGeneral={outlookDaysTornadoFeatureCollection.features}
            clickedOutlook={d.object}
          />
        );
      },
    });
  };