import React, {useContext, useState} from "react";
import {useEffect} from "react";

import {StormContext} from "contexts/StormContext";

function CheckboxComponent({
    label, checkboxName, mt, checkbox, setCheckBox, defaultChecked, checked,
}) {
    const {
        setSpotters,
        setRadarCheck,
        setSatelliteCheck,
        setTweetCheck,
        setSpottersCheck,
        setSpotterFavoritesCheck,
        setTornadoReportsCheck,
        setHailReportsCheck,
        setWindReportsCheck,
        setOtherReportsCheck,        
        setWarningsCheck,
        setSpcOutlookCheck,
        setTvChannelsCheck,
        setWatchesCheck,
        setLegendChecks
    } = useContext(StormContext);

    useEffect(() => {
        setSpotters("Spotter");
    }, []);

    const changeHandler = (e) => {
        let userChecks = JSON.parse(localStorage.getItem("mainSettings")) || {}
        if (e.target.name === "spotters") {
            setSpotters(e.target.name); // Why is this different than the others?
        } else if (e.target.name === "radarCheck") {
            userChecks.default_radar = e.target.checked;
            setRadarCheck(e.target.checked);
        } else if (e.target.name === "satelliteCheck") {
           userChecks.default_satellite = e.target.checked;
            setSatelliteCheck(e.target.checked);
        } else if (e.target.name === "tweetsCheck") {
            setTweetCheck(e.target.checked);
        } else if (e.target.name === "spotterCheck") {
            userChecks.default_spotters = e.target.checked ? "spotters" : "";
            setSpottersCheck(e.target.checked);
            if (e.target.checked) {
                setSpotterFavoritesCheck(false)
            }            
        } else if (e.target.name === "spotterFavoritesCheck") {
            userChecks.default_spotters = e.target.checked ? "favorites" : "";
            setSpotterFavoritesCheck(e.target.checked);        
            if (e.target.checked) {
                setSpottersCheck(false)
            }
        } else if (e.target.name === "tornadoReportsCheck") {
            userChecks.default_tornado_reports_reports = e.target.checked;
            setTornadoReportsCheck(e.target.checked);
        } else if (e.target.name === "hailReportsCheck") {
            userChecks.default_hail_reports = e.target.checked;
            setHailReportsCheck(e.target.checked);
        } else if (e.target.name === "windReportsCheck") {
            userChecks.default_wind_reports = e.target.checked
            setWindReportsCheck(e.target.checked);
        } else if (e.target.name === "otherReportsCheck") {
            userChecks.default_other_reports = e.target.checked;
            setOtherReportsCheck(e.target.checked);                                                
        } else if (e.target.name === "spcOutlookCheck") {
            setSpcOutlookCheck(e.target.checked);
        } else if (e.target.name === "warningsCheck") {
            userChecks.default_warnings = e.target.checked;
            setWarningsCheck(e.target.checked);
        } else if (e.target.name === "tvChannelsCheck") {
            userChecks.default_tv_channels = e.target.checked;
            setTvChannelsCheck(e.target.checked);
        } else if (e.target.name === "watchesCheck") {
            userChecks.default_watches = e.target.checked;
            setWatchesCheck(e.target.checked)
        } else if(e.target.name === "legendCheck"){
            userChecks.default_legend = e.target.checked;
            setLegendChecks(e.target.checked)
        } else {
        }
        localStorage.setItem("mainSettings", JSON.stringify(userChecks));

    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    
    return (<>
        <div className={`relative flex  !mt-${mt} items-center`} onClick={stopPropagation}>
            <div className="flex h-10 sm:h-6 items-center">
                <input
                    id={checkboxName}
                    aria-describedby="comments-description"
                    name={checkboxName}
                    type="checkbox"
                    value={checkbox}
                    // defaultChecked={defaultChecked}
                    className="h-7 w-7 sm:h-4 sm:w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 cursor-pointer"
                    onChange={changeHandler}
                    checked={checked}
                />
            </div>
            <div className="ml-3 text-sm leading-6 cursor-pointer">
                <label
                    htmlFor={checkboxName}
                    className="lg:inline-block lg:w-[100px] lg:text-sm text-gray-300 hover:text-white text-base leading-6 cursor-pointer"
                >
                    {label}
                </label>
            </div>
        </div>
    </>);
}

export default CheckboxComponent;
