import React, {createContext, useEffect, useState, useRef, useCallback} from "react";
import { useCookies } from 'react-cookie';

import OutlookService from "services/OutlookService";
import MapService from "services/MapService";
import MapServiceCache from "services/MapServiceCache";
import { MAP_SETTINGS } from "utils/settings";
import { useAuth } from "./AuthContext";
import SpotterService from "services/SpotterService";
import { LegendToggleRounded } from "@mui/icons-material";

export const StormContext = createContext();

const selectableMapStyles = {
    "Navigation": "mapbox://styles/mapbox/navigation-day-v1?optimize=true",
    "Nav. Night": "mapbox://styles/mapbox/navigation-night-v1?optimize=true",
    "Dark": "mapbox://styles/mapbox/dark-v10?optimize=true",
    "Light": "mapbox://styles/mapbox/light-v10?optimize=true",
    "Satellite": "mapbox://styles/mapbox/satellite-streets-v10?optimize=true",
}

export const StormProvider = (props) => {

    const outlookService = new OutlookService();
    const mapService = new MapService();
    const spotterService = new SpotterService();
    const { state } = useAuth();
    const userSettings = JSON.parse(state.userSettings)
    
    // Cookies
    // Cookie inits
    const [cookie, setCookie] = useCookies(['StormCenter.app']);
    const [followChristofferCookie, setFollowChristofferCookie] = useCookies('StormCenter-Christoffer'); 
    const initialMapStyle = userSettings?.default_map_style || Object.keys(selectableMapStyles)[0];
    const initialTimeFormat = parseInt(userSettings?.default_time_indicator) || 0;
    const initialSpotterFavorites = cookie.spotter_favorites || [];     
    const [timeTrigger, setTimeTrigger] = useState(null);
    const [spcTornadoReports, setSpcTornadoReports] = useState(null);
    const [spc24hTornadoReports, setSpc24hTornadoReports] = useState([]);
    const [centerOfMaxOutlook, setCenterOfMaxOutlook] = useState({});
    const [spcHailReports, setSpcHailReports] = useState(null);
    const [spcWindReports, setSpcWindReports] = useState(null);
    const [spcEventReports, setSpcEventReports] = useState(null);
    const [sliderValue, setSliderValue] = useState(userSettings?.default_slider === undefined ? [-12, 0] : [userSettings?.default_slider, 0]);
    const [timeFormat, setTimeFormat] = useState(initialTimeFormat);
    const [warnings, setSpcWarnings] = useState("");
    const [tornadoWarnings, setTornadoWarnings] = useState(null);
    const [severeWarnings, setSevereWarnings] = useState(null);
    const [watches, setWatches] = useState([]);
    const [tornadoWatches, setTornadoWatches] = useState([]);
    const [severeWatches, setSevereWatches] = useState([]);
    const [spcOutlooks, setSpcOutlooks] = useState([]);

    //Left Sidebar variables:
    const [spotters, setSpotters] = useState("");
    const [spotterFavorites, setSpotterFavorites] = useState(initialSpotterFavorites);
    const [tweetPhotos, setTweetPhotos] = useState("");
    const [spotterTrails, setSpotterTrails] = useState("");
    const [radar, setradar] = useState("");
    const [mapStyle, setMapStyle] = useState(initialMapStyle); // First is default
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [allStreamers, setAllStreamers] = useState("")
    const [allActiveStreamers, setAllActiveStreamers] = useState("")

    const [isToggleTornadoDays, setIsToggleTornadoDays] = useState(false)

    // Right Sidebar
    const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(false);
    const [rightSidebarComponent, setRightSidebarComponent] = useState(<div/>);

    // Overlay Popup
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState(null);
    const [modalComponent, setModalComponent] = useState(<div/>);
    const [isLayerMenuOpen, setIsLayerMenuOpen] = useState(false);
    const [isTopbarOpen, setIsTopbarOpen] = useState(false);
    const [isSpotterMenuOpen, setIsSpotterMenuOpen] = useState(false);
    const [isTimeIndicatorOpen, setIsTimeIndicatorOpen] = useState(false);
    const [isPositionIconOpen, setIsPositionIconOpen] = useState(false);
    const [isSearchFieldOpen, setIsSearchFieldOpen] = useState(false)
    const layerGroupRef1 = useRef(null);
    const layerGroupRef2 = useRef(null);
    const [satellite, setSatellite] = useState("");
    const [spcReports, setSpcReports] = useState("");
    const [spotterReports, setSpotterReports] = useState("");

    const [tvChannels, setTvChannels] = useState("");
    const [legend, setLegend] = useState("");

    // Checkboxes in sidebar
    const [satelliteCheck, setSatelliteCheck] = useState(userSettings?.default_satellite);
    const [tweetCheck, setTweetCheck] = useState(true);
    const [spottersCheck, setSpottersCheck] = useState(userSettings ? userSettings?.default_spotters === 'spotters': true);
    const [spotterFavoritesCheck, setSpotterFavoritesCheck] = useState(userSettings ? userSettings?.default_spotters === 'favorites' : false);    
    const [tornadoReportsCheck, setTornadoReportsCheck] = useState(userSettings ? userSettings?.default_tornado_reports : true);
    const [hailReportsCheck, setHailReportsCheck] = useState(userSettings ? userSettings?.default_hail_reports: true);
    const [windReportsCheck, setWindReportsCheck] = useState(userSettings ? userSettings?.default_wind_reports : false);
    const [otherReportsCheck, setOtherReportsCheck] = useState(userSettings ? userSettings?.default_other_reports : true);
    const [warningsCheck, setWarningsCheck] = useState(userSettings ? userSettings?.default_warnings : true);
    const [spcOutlookCheck, setSpcOutlookCheck] = useState(false);
    const [tvChannelsCheck, setTvChannelsCheck] = useState(userSettings ? userSettings?.default_tv_channels : false);
    const [radarCheck, setRadarCheck] = useState(userSettings ? userSettings?.default_radar : true);
    const [watchesCheck, setWatchesCheck] = useState(userSettings ? userSettings?.default_watches : true);
    const [legendChecks, setLegendChecks] = useState(userSettings ? userSettings?.default_legend : true);
    // Other
    const [events, setEvents] = useState({})
    const [isLoading, setIsLoading] = useState(true)
    const [metaData, setMetaData] = useState("");
    const maxZoomVar = followChristofferCookie['StormCenter-Christoffer'] ? 19 : MAP_SETTINGS.max_zoom;
    const [viewport, setViewport] = useState({
        latitude: MAP_SETTINGS.default_latitude,
        longitude: MAP_SETTINGS.default_longitude,
        zoom: MAP_SETTINGS.zoom_continental,
        projection: MAP_SETTINGS.default_projection,
        bearing: MAP_SETTINGS.default_bearing,
        pitch: MAP_SETTINGS.default_pitch,
        maxZoom: maxZoomVar
    });

    const [outlookDay, setOutlookDay] = useState("off");
    const now = new Date();

    const {
        data: spcData,
        error: spcError,
        isLoading: spcIsLoading,
        isStale,
        dataUpdatedAt,
      } = MapServiceCache.useFetchSpcReportsCached();

    const {
        data: spotterReportCachedData,
        error: spotterReportCacheError,
        isLoading: spotterReportCacheIsLoading,
        isStale: spotterReportCacheIsStale,
        dataUpdatedAt: spotterReportCacheDataUpdatedAt,
    } = MapServiceCache.useFetchSpotterReportsCached()    
    
    const {
        data: weatherAlertCachedData,
        error: weatherAlertCacheError,
        isLoading: weatherAlertCacheIsLoading,
        isStale: weatherAlertCacheIsStale,
        dataUpdatedAt: weatherAlertCacheDataUpdatedAt,
    } = MapServiceCache.useFetchWeatherAlertsCached()    
    
    const {
        data: tvChannelsCachedData,
        error: tvChannelsCacheError,
        isLoading: tvChannelstCacheIsLoading,
        isStale: tvChannelsCacheIsStale,
        dataUpdatedAt: tvChannelsCacheDataUpdatedAt,
    } = MapServiceCache.useFetchActiveTvChannelsCached()      

    // // Log caching information
    // useEffect(() => {
    //     if (spcData) {
    //     const now = Date.now();
    //     const dataAge = (now - dataUpdatedAt) / 1000; // Data age in seconds
    //     console.log(`spcData is ${isStale ? 'stale' : 'fresh'}, age: ${dataAge} seconds`);
    //     }
    // }, [spcData, isStale, dataUpdatedAt]);      

    // Zoom in on the map to a given position, set slider to ~1 hour around the timestamp
    // const zoomToPositionAndTime = async (lat, lon, relativeTime) => {
    //     setSliderValue([relativeTime - 1, relativeTime + 0.1])
    //     setViewport(prevViewport => ({
    //         ...prevViewport,
    //         latitude: Number(lat),
    //         longitude: Number(lon),
    //         zoom: MAP_SETTINGS.zoom_local,
    //         maxZoom: MAP_SETTINGS.max_zoom,
    //         bearing: MAP_SETTINGS.default_bearing,
    //         pitch: MAP_SETTINGS.default_pitch,
    //     }), () => {
    //         console.log("Updated viewport:", viewport);
    //     });
    //     console.log("viewport", viewport)
    // }

    const mapInstance = useRef(null);

    const zoomToPositionAndTime = useCallback((lat, lon, relativeTime, relativeTimeRangeArr) => {
        console.log("zoomToPositionAndTime", lat, lon, relativeTime)
        if (!relativeTimeRangeArr) {
            setSliderValue([relativeTime - 1, relativeTime + 0.1]);
        } else {
            setSliderValue(relativeTimeRangeArr);
        }
  
        if (mapInstance.current) {
            console.log("Fly to", lat, lon      )
            mapInstance.current.flyTo({
            center: [Number(lon), Number(lat)],
            zoom: MAP_SETTINGS.zoom_local,
            bearing: MAP_SETTINGS.default_bearing,
            pitch: MAP_SETTINGS.default_pitch,
            essential: true // This ensures the animation is not interrupted
            });
        } else {
            console.log("No map instance")
        }
    }, []);    

    // Show Popup/overlay in map (for icons etc)
    const showPopup = useCallback((content) => {
        setModalComponent(content);
        setIsModalOpen(true);
      }, [setModalComponent, setIsModalOpen]);

    // Brings out the right sidebar
    const showRightSidebar = (content) => {
        setRightSidebarComponent(content);
        setIsRightSidebarOpen(true);
    }
    

    // Cookie (setting variables to cookie on startup and when cookie changes)
    /*useEffect(() => {
        console.log("Setting mapstyle to cookie",cookie.mapStyle  )

        //mapStyle
        const initialMapStyle = cookie.mapStyle || Object.values(selectableMapStyles)[0];
        setMapStyle(initialMapStyle);

        console.log("Setting mapstyle to cookie",cookie.TimeFormat  )
        // TimeIndicatoe
        setTimeFormat(cookie.TimeFormat || 0 )

        console.log("Setting favorites to cookie", cookie.SpotterFavorites)
        setSpotterFavorites(cookie.SpotterFavorites || [])
      }, [cookie]);*/
      

    useEffect(() => {
        setCookie("map_style", mapStyle)
    }, [mapStyle]);


    useEffect(() => {
        setCookie("time_format", timeFormat)
    }, [timeFormat]);    


    useEffect(() => {
        setCookie("spotter_favorites", spotterFavorites)
    }, [spotterFavorites]);    
    

    useEffect(() => {
        async function getAllStreamersNow() {
            const allStreamers = await spotterService.getAllStreamers()
            const allActiveStreamers = allStreamers.filter((streamer) => streamer.youtube_active_livestream)            
            setAllStreamers(allStreamers)
            setAllActiveStreamers(allActiveStreamers)
        }

        const timeoutId = setTimeout(() => {
            getAllStreamersNow();
        }, 500);
        return () => {
        clearTimeout(timeoutId);
        };
    }, []);    


    useEffect(() => {
        async function getWeatherAlerts() {
            if (weatherAlertCachedData) {
            //const data = await mapService.getWeatherAlerts();
            const data = weatherAlertCachedData
            let allAlertsTimeFiltered = JSON.parse(data)
                .filter((alert) => {
                    return (alert.expires_utc >= new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).toISOString());
                })
                .filter((alert) => {
                    return (alert.onset_utc <= new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).toISOString());
                });

            // Tornado warnings
            const tornadoWarningsJson = allAlertsTimeFiltered
                .map((alertData) => {
                    if (alertData["alert_type"] === "warning" && alertData["severity_type"] === "Tornado Warning") {
                        if (alertData["warning_polygon_json"]) {
                            return {...alertData, map_item: "tornado_warning"};
                        }
                    }
                })
                .filter((value) => value !== undefined);

            // Severe warnings
            const severeWarningsJson = allAlertsTimeFiltered
                .map((alertData) => {
                    if (alertData["alert_type"] === "warning" && alertData["severity_type"] === "Severe Thunderstorm Warning") {
                        if (alertData["warning_polygon_json"]) {
                            return {...alertData, map_item: "severe_warning"};
                        }
                    }
                })
                .filter((value) => value !== undefined);

            // Watches
            const tornadoWatchesJson = allAlertsTimeFiltered
                .map((alertData) => {
                    if (alertData["alert_type"] === "watch" && alertData["severity_type"] === "Tornado Watch") {
                        if (alertData["watch_polygon_json_slim"]) {
                            return {...alertData["watch_polygon_json_slim"], map_item: "tornado_watch"};
                        }
                    }
                })
                .filter((value) => value !== undefined);

            // Severe warnings
            const severeWatchesJson = allAlertsTimeFiltered
                .map((alertData) => {
                    if (alertData["alert_type"] === "watch" && alertData["severity_type"] === "Severe Thunderstorm Watch") {
                        if (alertData["watch_polygon_json_slim"]) {
                            return {...alertData, map_item: "severe_watch"} //["watch_polygon_json_slim"];
                        }
                    }
                })
                .filter((value) => value !== undefined);
            setTornadoWarnings(tornadoWarningsJson);
            setSevereWarnings(severeWarningsJson);
            setTornadoWatches(tornadoWatchesJson);
            setSevereWatches(severeWatchesJson);
            //setWatches(watchesJson);
            }
        }

        const timeoutId = setTimeout(() => {
            getWeatherAlerts();
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [sliderValue, weatherAlertCachedData]);

    useEffect(() => {
        const email = localStorage.getItem("email");
        if (email) {
            getSpotterFavorites(email);
        }
    }, [])

    const getSpotterFavorites = async (email) => {
        const spotterFavorites = await mapService.getSpotterFavorites(email);
        setSpotterFavorites(spotterFavorites.map(favorite => favorite.spotter_id))
    }

    // Not sure what this does (Nas). 
    useEffect(() => {
        const handleWindowResize = () => {
            setIsSidebarOpen(false)

            if (window.innerWidth < 768) setIsToggleTornadoDays(false)
                else setIsToggleTornadoDays(true)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    }, [])


    // Makes sure the application updates with recent data, by setting upperslider to 0 every minute - which triggers fetching
    useEffect(() => {
        async function updateSliderUpperToLive() {
            // If slider value is set to 0, it triggers SliderValue which will run every depending
            //  function (which will read 0 as current.)
            if (sliderValue[1] === 0) {
               
                setSliderValue(prevSliderValue => {
                    //console.log("prevSliderValue", prevSliderValue)
                    //console.log(">---   Setting slider values", [parseInt(prevSliderValue), 0])
                    return [parseInt(prevSliderValue), 0]
                });
            }
        }
        // Initial call
        updateSliderUpperToLive();

    }, [timeTrigger]);

    
    // Pings timeTrigger every 60 seconds
    useEffect(() => {
        async function timeTrigger() {
            setTimeTrigger(new Date().toISOString());
        }
        timeTrigger();

        // Set up the interval
        const intervalId = setInterval(() => {
            timeTrigger();
        }, 60 * 1000); // 60000 milliseconds = 1 minute

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);

    }, []);    


    // Go to Action Area (center of max outlook)
    useEffect(() => {
        async function getCenterOfMaxOutlook() {
            const activeOutlook = await outlookService.getActiveOutlook();
            //console.log("CENTER OF MAX OUTLOOK", activeOutlook)
            let maxOutlookTornado = activeOutlook.tornado[activeOutlook.tornado.length - 1]
            let maxOutlookCategorical = activeOutlook.categorical[activeOutlook.categorical.length - 1]
            if (activeOutlook.action_center_lat) {
                setCenterOfMaxOutlook({latitude: activeOutlook.action_center_lat, longitude: activeOutlook.action_center_lon})
                //goToPositionOnMap(activeOutlook.action_center_lat, activeOutlook.action_center_lon, 7) // Goes to a given coordinate with zoom: 7
                //showOutlookDay(getMap(), dayUsed) // Displays the outlook polygons on the map
            } else {
                console.log("No active outlook (or action area) defined at the moment")
            }
        }

        getCenterOfMaxOutlook()
    }, []);


    // MetaData for last updated
    useEffect(() => {
        async function getMetaData() {
            //console.log("Retrieving  metadata")
            const metaData = await mapService.getMetaData();
            //console.log("Updating meta data", data)
            setMetaData(metaData)
        }
        // Is this really needed considering the sliderValue?
        const intervalId = setInterval(() => {
            getMetaData();
        }, 60 * 1000);

        const timeoutId = setTimeout(() => {
            getMetaData();
        }, 500);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };        
        
    }, []);


    // TV Channels
    useEffect(() => {
        async function getTvChannels() {
            if (tvChannelsCachedData) {
                const data = await mapService.getActiveTvChannels();
                try {
                    const tvChannelsJson = JSON.parse(data)
                        .map((tvChannel) => {
                            return {
                                ...tvChannel, position: [Number(tvChannel.tmp_longitude), Number(tvChannel.tmp_latitude)], map_item: "tv_channel",
                            };
                        });
                    //console.log("tvChannelsJson", tvChannelsJson)
                    setTvChannels(tvChannelsJson);
                } catch (e) {
                    // Bad coding, should not use try/catch to filter TV Channels!
                    //console.log("No TV channels to find")
                }
            }            
        }
        getTvChannels()
    }, [tvChannelsCachedData]);

    
    // Get filtered spotter reports
    useEffect(() => {
        async function getSpotterReports() {
            //console.log("Get spotter reports")
            if (spotterReportCachedData) {
                //const data = await mapService.getSpotterReports();
                const data = spotterReportCachedData
                //console.log("spotterReportdata", data)
                const spotterReportsJson = data
                    .filter((report) => {                        
                        return (report.report_time_utc >= new Date(now.getTime() - -sliderValue[0] * 60 * 60 * 1000).toISOString());
                    })
                    .filter((report) => {
                        return (report.report_time_utc <= new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).toISOString());
                    })
                    .map((reportData) => {
                        return {
                            ...reportData, 
                            sliderRelativeOpacity: 1-((new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).getTime() - (new Date(reportData.datetime_utc).getTime())) / (1000 * 60 * 60)) / (24 - -sliderValue[1]),
                            position: [Number(reportData.longitude), Number(reportData.latitude)], 
                            map_item: "spotter_report"
                        };
                    });
                //console.log("spotterReportsJson", spotterReportsJson)                
                setSpotterReports(spotterReportsJson);
            }
        }

        // Is this really needed considering the sliderValue?
        const intervalId = setInterval(() => {
            getSpotterReports();
        }, 60 * 1000);

        const timeoutId = setTimeout(() => {
            getSpotterReports();
        }, 500);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [sliderValue, spotterReportCachedData]);


    // Helper function to filter and sort SPC reports
    function filterAndSortSpcReports(reportData) {
        //console.log("reportData", reportData)
        const filteredReportData = reportData
        .filter((report) => {
            return (report.datetime_utc >= new Date(now.getTime() - -sliderValue[0] * 60 * 60 * 1000).toISOString());
        })
        .filter((report) => {
            return (report.datetime_utc <= new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).toISOString());
        })
        .map((reportData) => {
            return {
                ...reportData, 
                position: [Number(reportData.longitude), Number(reportData.latitude)], map_item: reportData.report_type + "_report",
                //sliderVsReportDifference: (new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).getTime() - (new Date(reportData.datetime_utc).getTime())) / (1000 * 60 * 60),
                sliderRelativeOpacity: 1-((new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).getTime() - (new Date(reportData.datetime_utc).getTime())) / (1000 * 60 * 60)) / (24 - -sliderValue[1]),
                //sliderRangeTotal: (24 - -sliderValue[1]) 
            };
        });      
        //console.log("filteredReportData",filteredReportData)
        //console.log("filteredReportData", "lower:", new Date(now.getTime() - -sliderValue[0] * 60 * 60 * 1000).toISOString(), "upper:", new Date(now.getTime() - -sliderValue[1] * 60 * 60 * 1000).toISOString(), filteredReportData)
        return filteredReportData.sort((a, b) => new Date(b.datetime_utc) - new Date(a.datetime_utc))
    }

    // SPC Reports
    useEffect(() => {
        if (spcIsLoading) {
            //console.log("...loading SPC")
        }
        if (spcError) {
            console.log("ERROR SPC", spcError)
        }          
        async function getSpcReportsNow() {
            if (spcData) {
                //const data = await mapService.getSpcReportsJson();    
                const data = spcData
                //console.log("spcData cached StormContext", spcData)

                const tornadoReports24hJson = JSON.parse(data["tornado_reports"])
                    .map((reportData) => {
                        //console.log("RepD",reportData)
                        return {
                            ...reportData, position: [Number(reportData.longitude), Number(reportData.latitude)],
                        };
                    });
                const tornadoReportsJsonSorted    = filterAndSortSpcReports(JSON.parse(data["tornado_reports"]))
                const hailReportsJsonSorted       = filterAndSortSpcReports(JSON.parse(data["hail_reports"]))
                const windReportsJsonSorted       = filterAndSortSpcReports(JSON.parse(data["wind_reports"]))
                
                //console.log("tornadoReportsJsonSorted", tornadoReportsJsonSorted)
                setSpcTornadoReports(tornadoReportsJsonSorted);
                setSpc24hTornadoReports(tornadoReports24hJson);
                setSpcHailReports(hailReportsJsonSorted);
                setSpcWindReports(windReportsJsonSorted);
            }
        }

        const intervalId = setInterval(() => {
            getSpcReportsNow();
        }, 60000);

        const timeoutId = setTimeout(() => {
            getSpcReportsNow();
        }, 500);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, [sliderValue, spcData]);

    useEffect(() => {
        async function getCurrentEvents() {
            const data = await mapService.getCurrentEvents();    
            //const dataJson = JSON.parse(data)
            //console.log("DATA", JSON.parse(data))

            const eventData = JSON.parse(data)
                .map((reportData) => {
                    //console.log("RepD",reportData)
                    return {
                        ...reportData, position: [Number(reportData.longitude), Number(reportData.latitude)],
                    };
                });
            setSpcEventReports(eventData);
        }

        const intervalId = setInterval(() => {
            getCurrentEvents();
        }, 60000);

        const timeoutId = setTimeout(() => {
            getCurrentEvents();
        }, 500);

        // Cleanup function to clear the interval when the component unmounts
        return () => {
            clearTimeout(timeoutId);
            clearInterval(intervalId);
        };
    }, []);


    // Exports everything to be used in other parts of the code
    return (<StormContext.Provider
        value={{
            spcTornadoReports,
            spc24hTornadoReports,
            centerOfMaxOutlook,
            spcHailReports,
            spcWindReports,
            spcEventReports,
            spotterReports,
            tornadoWarnings,
            severeWarnings,
            warnings,
            watches,
            tornadoWatches,
            severeWatches,
            sliderValue,
            setSliderValue,
            spotters,
            allStreamers,
            allActiveStreamers,
            tweetPhotos,
            spotterTrails,
            radar,
            satellite,
            spcReports,
            spotterFavorites,
            setSpotterFavorites,
            tvChannels,
            legend,
            setSpotters,
            setSpcWarnings,
            setradar, 
            // outlookCategoricalLayer,
            // outlookTornadoLayer,

            radarCheck,
            setRadarCheck,
            satelliteCheck,
            setSatelliteCheck,
            tweetCheck,
            setTweetCheck,
            spottersCheck,
            setSpottersCheck,
            spotterFavoritesCheck,
            setSpotterFavoritesCheck,

            tornadoReportsCheck,
            setTornadoReportsCheck,
            hailReportsCheck,
            setHailReportsCheck,
            windReportsCheck,
            setWindReportsCheck,
            otherReportsCheck,
            setOtherReportsCheck,
            setWarningsCheck,
            setWatchesCheck,
            setTvChannelsCheck,

            warningsCheck,
            watchesCheck,
            setSpcOutlookCheck,
            spcOutlookCheck,
            tvChannelsCheck,

            events,
            metaData,
            setEvents,
            isLoading,
            setIsLoading,
            setOutlookDay,
            outlookDay,
            spcOutlooks,
            setSpcOutlooks,
            isRightSidebarOpen,
            setIsRightSidebarOpen,
            rightSidebarComponent,
            setRightSidebarComponent,
            showRightSidebar,
            isModalOpen,
            modalComponent,
            setIsModalOpen,
            modalType,
            setModalType,
            showPopup,
            
            setViewport,
            viewport,            
            zoomToPositionAndTime,
            mapInstance,

            legendChecks,
            setLegendChecks,
            selectableMapStyles,
            setMapStyle,
            mapStyle,
            setTimeFormat,
            timeFormat,

            isSidebarOpen,
            setIsSidebarOpen,

            isToggleTornadoDays, 
            setIsToggleTornadoDays,
            isLayerMenuOpen,
            setIsLayerMenuOpen,
            isTopbarOpen,
            setIsTopbarOpen,
            isTimeIndicatorOpen,
            setIsTimeIndicatorOpen,
            isPositionIconOpen,
            setIsPositionIconOpen,
            isSearchFieldOpen,
            setIsSearchFieldOpen,
            isSpotterMenuOpen,
            setIsSpotterMenuOpen,
            layerGroupRef1,
            layerGroupRef2,
            setCookie
        }}
    >
        {props.children}
    </StormContext.Provider>);
};
