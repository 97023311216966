import React, {useContext} from 'react';

import {StormContext} from "contexts/StormContext";

const RightBar = () => {
    const {isRightSidebarOpen, setIsRightSidebarOpen, rightSidebarComponent} = useContext(StormContext);

    return (
        <div
            onMouseEnter={() => setIsRightSidebarOpen(true)} 
            onMouseLeave={(e) => {
                if (e.relatedTarget && !e.relatedTarget?.className?.includes('header-topbar-container'))
                    setIsRightSidebarOpen(false)
            }}
            className={`fixed right-0 z-[80] top-[52px] flex flex-col bg-[#012E40] space-y-1 !p-2 text-white w-[300px] h-[calc(100vh-180px)] -translate-y-full ${isRightSidebarOpen ? "!translate-y-0 visible" : "invisible"} transition-all duration-300 overflow-y-auto`}
        >
            {rightSidebarComponent}
        </div>
    );
};

export default RightBar;
