import axiosService from "utils/axios";

export default class EventService {

    async getEventTweets(date) {
        return (await axiosService.get(`/get-event-date-tweets`, {
            params: {
                date
            }
        })).data
    }

    async getEventData(date) {
        return (await axiosService.get(`/get-event-day-data`, {
            params: {
                date
            }
        })).data
    }

    async getEventNews(date) {
        return (await axiosService.get(`/get-event-date-news`, {
            params: {
                date
            }
        })).data
    }

    async getEventReports(date) {
        return (await axiosService.get(`/get-event-day-reports`, {
            params: {
                date
            }
        })).data
    }

    async getWikiEvents() {
        return (await axiosService.post(`/get-wiki-events`)).data
    }

    async getSignificantEvents() {
        return (await axiosService.post(`/get-significant-events`)).data
    }

    async searchEvents(query) {
        return (await axiosService.get(`/search-events`, {
            params: query
        })).data
    }

    async searchEventsByFixedDate(query) {
        return (await axiosService.get(`/search-events-by-fixed-date`, {
            params: query
        })).data
    }

    async getEventsByYearMonth(query) {
        return (await axiosService.get(`/get-events-yearmonth`, {
            params: query
        })).data
    }

    async getEventsByYearMonthAll(query) {
        return (await axiosService.get(`/get-events-yearmonth-all`)).data
    }    

    async getEventsByYear(query) {
        return (await axiosService.get(`/get-events-year`, {
            params: query
        })).data
    }    

    async getEventsByYearAll(query) {
        return (await axiosService.get(`/get-events-year-all`, {
            params: query
        })).data
    }      
}