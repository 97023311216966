import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from "react";
import {Link} from "react-router-dom";

import {StormContext} from 'contexts/StormContext';
import {getRelativeTimeFromDateTime} from "utils/general-functions";
import { MAP_SETTINGS } from "utils/settings";

function ChildComponent({name, icon, mapFunction, link = "#", onClick}) {
    const {
        spc24hTornadoReports, centerOfMaxOutlook, setSliderValue, setViewport, showPopup, zoomToPositionAndTime
    } = useContext(StormContext);

    const handleChange = (e) => {
        // LAST TORNADO
        if (mapFunction === "LastTornado" && spc24hTornadoReports.length > 0) {
            console.log("Last Tornado", spc24hTornadoReports[spc24hTornadoReports.length - 1])
            zoomToPositionAndTime(
                spc24hTornadoReports[spc24hTornadoReports.length - 1].position[1],
                spc24hTornadoReports[spc24hTornadoReports.length - 1].position[0], 
                getRelativeTimeFromDateTime(spc24hTornadoReports[spc24hTornadoReports.length - 1].datetime_utc)
            
            );
            // setSliderValue([-24, 0]);
            // console.log("Last Tornado", spc24hTornadoReports[spc24hTornadoReports.length - 1])
            // //console.log("Spc",spc24hTornadoReports[0].position)
            // setViewport({
            //     latitude: spc24hTornadoReports[spc24hTornadoReports.length - 1]?.position[1],
            //     longitude: spc24hTornadoReports[spc24hTornadoReports.length - 1]?.position[0],
            //     zoom: MAP_SETTINGS.zoom_focus,
            //     bearing: MAP_SETTINGS.default_bearing,
            //     pitch: MAP_SETTINGS.default_pitch
            //})
        } else if (mapFunction === "LastTornado" && spc24hTornadoReports.length === 0) {
            showPopup(<p className="text-base mt-1 text-gray-600">
                No tornado reports in the last 24h
            </p>);
        }

        // GO TO ACTION AREA
        if (mapFunction === "ActionArea") {            
            if (centerOfMaxOutlook.latitude && centerOfMaxOutlook.longitude) {
                zoomToPositionAndTime(
                    centerOfMaxOutlook.latitude,
                    centerOfMaxOutlook.longitude, 
                    null,
                    [-24,0]                
                );       
                // console.log("GOING TO ACTION AREA", centerOfMaxOutlook)
                // setViewport({
                //     latitude: centerOfMaxOutlook.latitude,
                //     longitude: centerOfMaxOutlook.longitude,
                //     zoom: MAP_SETTINGS.zoom_local,
                //     bearing: MAP_SETTINGS.default_bearing,
                //     pitch: MAP_SETTINGS.default_pitch
                // })
            } else {
                showPopup(<p className="text-base mt-1 text-gray-600">
                    No action area defined at this moment
                </p>);
            }
        }
    }

    return (<>

        <li onClick={onClick}>
            {onClick ?
                <div onClick={handleChange}
                     className="w-[180px] pl-3 cursor-pointer text-gray-300 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md !px-2 text-sm leading-6 font-semibold items-center">
                    <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
                        <FontAwesomeIcon icon={icon} className="text-[#012533] text-xs"/>
                    </div>
                    <span className="relative"> {name}</span>
                </div>
                : <Link to={link} onClick={handleChange}
                        className="w-[180px] pl-3 text-gray-300 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md !px-2 text-sm leading-6 font-semibold items-center">
                    <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
                        <FontAwesomeIcon icon={icon} className="text-[#012533] text-xs"/>
                    </div>
                    <span className="relative whitespace-nowrap"> {name}</span>
                </Link>}
        </li>
    </>);
}

export default ChildComponent;
