import React from 'react'
import {Helmet} from "react-helmet";

import ArticleTemplate from 'components/ArticleTemplate';

const About = () => {
    const currentURL = "https://stormcenter.app" + window.location.pathname;
    return (
        <ArticleTemplate headline='About StormCenter'>
        <Helmet>
        <title>About StormCenter</title>
        <meta name="description" content="StormCenter is a web based application by Christoffer Björkwall that shows information of storms from the last 24 hours on a map." />
        <link rel="canonical" href={currentURL} />
        </Helmet> 
            <h2>What is StormCenter?</h2>
            <p>StormCenter is a free map based tool to get a good overview look of ongoing storms in the USA, mainly from a storm chasing perspective. 
                The overview is a window of 24 hours where one can zoom in geographically but also in specific time windows, such as 3 p.m. to 7 p.m. in Kansas. 
                Basically, a One-Stop-Shop when you want to follow a storm event while it is developing and get it
                all in one place. The question StormCenter is intended to answer is: "What is happening right now, and what has happened in the last 24h, in terms of severe storms in the USA?". It is also a place to learn about what happened, in terms of storms, at a certain time in history (the
                Storm History-section). Here you can find out what SPC forecasted about a certain date, see the outcome
                etc.
            </p>
            <h3>Features</h3>
            <ul>
                <li>Be able to analyze the last 24 hours of storms. Zoom in on a geographical area, time section, and pick the layers you want to see for that time and period - with a lot of different overlays.</li>

                <li>Easily get an overview of the tornado reports: if they were landspouts, night time tornadoes, recent etc.</li>

                <li>See who was nearby any given tornado when it happened</li>

                <li>Find a given storm chaser (storm spotter) and see where he/she is chasing - and how far he/she</li>

                <li>Check out the last 20 years of storms and filter on such like: "all days with 10% tornado risk and above or see accumulated data for certain years or months</li>
                <ul>
                    <li>Analyze any given date and see tornado reports etc in an interactive map</li>
                    <li>Read historical outlooks and news</li>
                    <li>Find references to mentions on Wikipedia, StormTrack threads etc from that day.</li>
                </ul>

                <li>Follow the position and chase track of your favorite storm chasers. Was he/she near that big tornado yesterday?</li>

                <li>Get notifications on email about tornado reports near a given position</li>

                <li>Get customized notifications on email about outlooks (categorical and tornado risk) for a given position or USA as a whole.</li>

                <li><a href='/forecast/text'>SPC Outlook, without abbreviations, to be used for text-to-speech</a></li>
            </ul>
            <p>
                
            </p>

            <h3>What it is not?</h3>
            <p>
                The application is not to be used as a tool to actively assist chasing. There are numerous
                applications that are more optimized, and with greater resolution when it comes to analyzing
                current storms.
            </p>
            <p>
                It is not aimed towards providing information for public safety.
            </p>
            <p>
                It is not a forecasting tool (use Pivotal Weather, SPC etc for that) but it does provide som AI-assisted interpretation of the SPC outlooks.
            </p>
            <h3>Why I made it</h3>
            <p>
                I have always thought about building a tool in general for the storm chasing community, and one idea has
                always been to have one map in which you can see "everything". When I started learning Node.js this idea
                seemed perfect to pursue and build. After a while, I realized the application was quite useful and
                started expanding the features. It is also a great way for me to learn new technologies.
            </p>

            <h3>API:s and mentions</h3>
            <p>
                This website is heavily based on free and open API:s, from sources such as:
            </p>
            <ul>
                <li>Storm Prediction Center: Storm reports, Outlooks, Warnings etc</li>
                <li>Spotter Network: Storm chaser locations and reports</li>                
                <li>Nomaintim.org: Geocoding</li>
                <li>Iowa State radar and satellite maps</li>
                <li>Wikipedia: Certain tornado events</li>
                <li>Weather.gov API: Weather alerts</li>
                <li>Sunset and Sunrise Times API</li>
                <li><s>Twitter API: Tweets and images</s> - the Tweets/Twitter images has unfortunately been removed due to changes in Twitter API (i.e. insane costs!). I hope this will come back in the future again.</li>
            </ul>

            <h3>News and updates</h3>
            <p>If you want to read more about new features and what's going on with StormCenter, I usually write an update on <a href="https://www.stormchasingusa.com/blog/category/stormcenter/">StormChasingUSA.com blog</a> about news and general thoughts.</p>

            <h2>Improvement and ideas?</h2>
            <p>
                If you find the application useful and have any ideas on how it can be improved, please send me a
                message over Twitter (@stormchasingusa) or email christoffer@stormchasingusa.com.
            </p>
            <h2>About me</h2>
            <p>
                My name is Christoffer Björkwall and I am a Swedish storm chaser with a great passion for chasing and
                photographing storms. I am also a programmer and a data engineer. I run <a
                href="https://www.stormchasingusa.com">StormChasingUSA.com, a website that explains, compares and
                promotes storm chasing tours</a>.
            </p>
        </ArticleTemplate>
    )
}

export default About
