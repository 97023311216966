import {
    timeSince,
} from "utils/general-functions";

import tornadoSimpleIcon from "assets/images/icons/tornado-simple.webp";
import tornadoIcon from "assets/images/icons/map/tornado-red-inside.webp";
import tornadoNightIcon from "assets/images/icons/map/tornado-night.webp";
import tornadoSunsetIcon from "assets/images/icons/map/tornado-sunset.webp";
import tornadoEf0Icon from "assets/images/icons/map/tornado-simple-ef0.webp";
import tornadoEf1Icon from "assets/images/icons/map/tornado-simple-ef1.webp";
import tornadoEf2Icon from "assets/images/icons/map/tornado-simple-ef2.webp";
import tornadoEf3Icon from "assets/images/icons/map/tornado-simple-ef3.webp";
import tornadoEf4Icon from "assets/images/icons/map/tornado-simple-ef4.webp";
import tornadoEf5Icon from "assets/images/icons/map/tornado-simple-ef5.webp";
import rotatingWallCloud from "assets/images/icons/map/rotating-wallcloud.webp";
import windIcon from "assets/images/icons/map/wind-icon.webp";
import hailIcon from "assets/images/icons/map/hail-round.webp";
import spotterOtherIcon from "assets/images/icons/map/spotter-doc-color.webp";
import funnelcloudIcon from "assets/images/icons/map/funnelcloud.webp";
import landspoutIcon from "assets/images/icons/map/landspout-red.webp";
import waterspoutIcon from "assets/images/icons/map/waterspout.webp";
import landspoutNightIcon from "assets/images/icons/map/landspout-night.webp";

export const getSpotterReportName = (reportType) => {
const spotterReportNameLookup = {
    other: "Other",
    tornado: "Tornado", 
    wallcloud: "Rotating wall cloud",
    funnelcloud: "Funnel cloud",
    hail: "Hail",
    wind: "Wind",
};
return spotterReportNameLookup[reportType];
}

export const getToolTipFromObject = (object, source="map") => {
    // MAP TOOLTIPS: Tooltips on different types of objects in map. Hack: Looks at unique data properties to determine type of object.
    // Beware, if object.map_item doesn't exist (as for warnings), it will not fail. Thus the order of if's is important.
    //console.log("TOOLTIP OBJECT", object, source)
    if (source === "map") {
        if (object) {
            //console.log("Object Tooltip", object)
            if (object.sn_first_name) {
                // Spotters        
                let tooltip = `${object.sn_first_name} ${object.sn_last_name}`;
                if (object.twitter) {
                    tooltip += `\n@${object.twitter}`;
                }
                if (object.youtube_active_livestream) {
                    tooltip += `<br />🟢 Livestreaming NOW!`;
                } 
                //console.log(tooltip)
                return tooltip;
            } else if (object.map_item === 'spotter_report') {
            // Spotter report
                return `Spotter report  (${timeSince(new Date(object.datetime_utc))} ago): ${getSpotterReportName(object.report_type)}`;
            } else if (object.map_item === 'hail_report') {
                // Hail report
                return `Hail report (${timeSince(new Date(object.datetime_utc))} ago):<br /> ${formatHailSize(object.size)}`;
            } else if (object.map_item === 'wind_report') {
                //console.log("WIND", object)
                return `Wind report (${timeSince(new Date(object.datetime_utc))} ago): ${object.wind_speed === "UNK" ? "" : ": " + object.wind_speed + "mph"}`;
            } else if (object.map_item === 'arc') {
                // ARC
                return `Spotter trail ${object.spotter_name} at ${object.timestamp}`
            } else if (object.map_item === 'tornado_report') {
                return `Tornado report (${timeSince(new Date(object.datetime_utc))} ago): \n ${object.comments}  `;
            } else if (object.map_item === 'tv_channel') {
                return `TV Channel: ${object.name}  `;                
            } else if (object.warning_info && (object.warning_info.map_item === 'tornado_warning' || object.warning_info.map_item === 'severe_warning')) {
                // Warning
                return object.warning_info.severity_type;    
            } else if (object.watch_info && (object.watch_info.map_item === 'severe_watch' || object.watch_info.map_item === 'tornado_watch')) {
                // Watch
                return object.watch_info.severity_type;      
            } else if (
                // SPC categorical outlook
                object.properties &&
                object.properties.LABEL2 &&
                object.properties.LABEL2.length > 2 // Hack: all cat have long labels
                ) {
                return object.properties.LABEL2;
                } else if (
                object.properties &&
                object.properties.LABEL2 &&
                object.properties.LABEL2.length <= 2
            ) {
                // SPC tornado risk
                return object.properties.LABEL2 + " tornado risk";
            }
        }

    } else {        
        // Historical maps (based on db features)
        if (object && object.report_type === "tornado") {
            return `Tornado report: ${object.comments}`;
        } else if (object && object.report_type === "hail") {
            return `Hail report: ${formatHailSize(object.size)}`;
        } else if (object && object.report_type === "wind") {
            return `Wind report: ${object.comments}`;
        } else if (object && object.warning_info) {
            // Warning
            return object.warning_info.severity_type;                         
        } else if (
            // SPC categorical outlook
            object &&
            object.properties &&
            object.properties.LABEL2 &&
            object.properties.LABEL2.length > 2 // Hack: all cat have long labels
            ) {
            return object.properties.LABEL2;
        } else if (
            object &&
            object.properties &&
            object.properties.LABEL2 &&
            object.properties.LABEL2.length <= 2
            ) {
            // SPC tornado risk
            return object.properties.LABEL2 + " tornado risk";
        }
    }

  }



export  const describeHailSize = (diameter) => {
    if (diameter < 0.25) {
        return "Pea";
    } else if (diameter < 0.5) {
        return "Marble";
    } else if (diameter < 0.75) {
        return "Dime";
    } else if (diameter < 1) {
        return "Quarter";
    } else if (diameter < 1.5) {
        return "🏓 Ping pong ball";
    } else if (diameter < 1.75) {
        return "⛳ Golf ball";
    } else if (diameter < 2.5) {
        return "🎾 Tennis ball";
    } else if (diameter < 3) {
        return "⚾ Baseball";
    } else if (diameter < 4) {
        return "☕ Tea cup";
    } else if (diameter < 4.5) {
        return "🍊 Grapefruit";
    } else {
        return "🥎 Softball";
    }
}



export const formatHailSize = (hailSize, short=false) => {
  // Check if the string is empty
  if (!hailSize || hailSize === "") {
      return "";
  }
  // Ensure the string has a minimum of 3 characters by padding with zeros if necessary
  if (hailSize.length === 1) {
      hailSize = "00" + hailSize;
  } else if (hailSize.length === 2) {
      hailSize = "0" + hailSize;
  }

  let hailSizeFloat= parseFloat(hailSize) / 100
  let hailSizeEquivalent = describeHailSize(hailSizeFloat)

  // Insert the decimal point two places from the end
  const formattedSize = hailSize.slice(0, -2) + '.' + hailSize.slice(-2) + " inches (" + hailSizeEquivalent + ")"
  
  // Remove any trailing zeros after the decimal point and extra quotes if not needed
  const longFormattedSize = formattedSize.replace(/"00$/, '"').replace(/"0$/, '"');
  const shortFormattedSize = describeHailSize(hailSizeFloat).slice(0, 2)
  return (short? shortFormattedSize : longFormattedSize);
}  




export const hailIconSize = (reportedSize) => {
    const baseSize = 18;
    if (reportedSize === "") {
        return baseSize;
    } else {
        const calculatedSize = parseFloat((reportedSize) / 100)**2.2
        //console.log("calculatedSize", calculatedSize)
        return baseSize + calculatedSize;
    }
}

export const getSpotterReportIconSize = (reportEvent)   => {
    //console.log("reportEvent", reportEvent)
    if (reportEvent === "tornado") {
        return 27;
    } else if (reportEvent === "wallcloud") {
        return 27;        
    } else if (reportEvent === "funnelcloud") {
        return 27;        
    } else if (reportEvent === "hail") {
        return 20;
    } else if (reportEvent === "wind") {
        return 20;
    } else {
        return 18;
    }
}


export const getEventIcon = (reportType, subset="") => {
    if (reportType === 'tornado') {
        return tornadoIcon       
    } else if (reportType === 'wallcloud') {
        return rotatingWallCloud
    } else if (reportType === 'waterspout') {
        return waterspoutIcon
    } else if (reportType === 'landspout') {
        return landspoutIcon
    } else if (reportType === 'hail') {
        return hailIcon
    } else if (reportType === 'wind') {
        return windIcon
    } else if (reportType === 'funnelcloud' || reportType === 'funnel') {  // Refactor this. Using two terms.
        return funnelcloudIcon
    } else if (reportType === 'other') {
        return spotterOtherIcon
    } else {
        if (reportType === 'tornado_night') {
            return tornadoNightIcon
        } else if (reportType === 'tornado_sunset') {
            return tornadoSunsetIcon
        } else if (reportType === 'landspout_night') {
            return landspoutNightIcon     
        } else if (reportType === 'tornado_ef5') {
            return tornadoEf5Icon
        }
    }
}


export const getTornadoIconName = (tornadoData, defaultMap=true) => {
    //console.log("TornadoData", tornadoData, "defaultmap", defaultMap)
    const efScale = tornadoData.f_scale_enhanced
    let tornadoBaseIconName = "tornado-simple"
    // Dual fetch from Storage / Database
    if (tornadoData?.tags?.includes('waterspout') || tornadoData.comments?.includes('aterspout')) {
        return waterspoutIcon
    } else if (tornadoData?.tags?.includes('landspout') || tornadoData.comments?.includes('andspout')) {
        if (tornadoData?.sunlight === 'night') {
            return landspoutNightIcon
        } else {
            return landspoutIcon
        }
    } else if (tornadoData) {          
        if (tornadoData?.sunlight === 'night') {
            return tornadoNightIcon
        } else if (tornadoData?.sunlight === 'sunset') {
            return tornadoSunsetIcon
        } else if (efScale && efScale !== "UNK") {
            //console.log("tname", tornadoBaseIconName + "-ef" + efScale.toLowerCase().slice(-1) + ".webp")
            let efScaleNbr = efScale.toLowerCase().slice(-1)
            if (efScaleNbr === "0") {
                return tornadoEf0Icon
            } else if (efScaleNbr === "1") {
                return tornadoEf1Icon
            } else if (efScaleNbr === "2") {
                return tornadoEf2Icon
            } else if (efScaleNbr === "3") {
                return tornadoEf3Icon
            } else if (efScaleNbr === "4") {
                return tornadoEf4Icon
            } else if (efScaleNbr === "5") {
                return tornadoEf5Icon
            } else {
                return tornadoIcon
            }                
        } else {            
            return tornadoIcon
        }        
    } else {
        //console.log("standard")
        // This should probably never happen...
        return tornadoSimpleIcon
    }
}

export const getTornadoIconSize = (efScale) => {

    //console.log("efScale size", efScale)
    const tornadoBaseIconSize = 30
    if (efScale && efScale !== "UNK") {
        //console.log(parseFloat((efScale.replace("EF-", ""))))
        const calculatedSize = tornadoBaseIconSize + parseFloat((efScale.replace("EF-", "")))**2.6
        //console.log("calculatedSize", calculatedSize)
        return calculatedSize
    } else {
        return tornadoBaseIconSize
    }
}