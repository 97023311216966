import React, { useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

import AuthService from 'services/AuthService';
import Loader from 'components/loader-gifs/Loader';
import TermsAndConditionsModal from 'components/texts/TermsAndConditionsModal';


const Register = () => {
  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const auth = new AuthService();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newsletter, setNewsletter] = useState(false);  
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY 
  console.log('recaptchaSiteKey', recaptchaSiteKey)
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const handleAcceptedTerms = e => {
    setAcceptedTerms(e.target.checked);
  }

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  }  

  const handleNewsletter = e => {
    setNewsletter(e.target.checked);
  }

  const sendVerifyEmail =async ()=>{
    try {
      console.log('Sending verification code (sendVerifyEmail) ...')
      await auth.verifyEmail(localStorage.getItem('email'));
    } catch (error) {
      console.error('Send verify failed', localStorage.getItem('email'));
      setError("Verification code cannot be sent");
    }
    toast.success('Verification code sent successfully, please check your inbox')
  }  

  const handleSubmit = async e => {
    console.log("Registering...");
    setLoading(true);
    e.preventDefault();
    if (!email) {
      toast.error('Email cannot be blank');
      setLoading(false);
      return;
    }
    if (!password) {
      toast.error('Password cannot be blank');
      setLoading(false);
      return;
    }
    if (!acceptedTerms) {
      toast.error('You need to accept Terms and Conditions to register');
      setLoading(false);
      return;
    }
    if (!recaptchaToken) {
      toast.error('Please complete the reCAPTCHA');
      setLoading(false);
      return;
    }
    try {
      
      await auth.register({email, password, newsletter, recaptchaToken});
      localStorage.setItem('email', email);
      setLoading(false);
      setError(false);
      toast.info('Sending verification code ...')
      sendVerifyEmail(email);
      navigate('/verify');
    } catch (error) {
      console.error('Register failed:', error);
      toast.error(error.response.data.error)
      setLoading(false);
      setError(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>Register an account at StormCenter</title>
        <meta name="description" content="Register an account at StormCenter to customize your experience and be able to receive notifications on tornadoes and significant outlooks." />
        <link rel="canonical" href={currentURL} />
      </Helmet> 
    <section className="h-screen">
    <div className="container mx-auto px-4">
      <div className="flex items-center justify-center h-full">
        <div className="w-full max-w-lg mt-24">
          <div className="bg-transparent p-8 shadow-lg rounded shadow-white">
            <h1 className="text-center text-3xl mt-3 mb-6 text-white">Register</h1>
            <form onSubmit={handleSubmit} className="mb-4" noValidate>
              <div className="mb-4">
                <label htmlFor="email" className="block text-white text-sm font-bold mb-2">
                  E-Mail Address
                </label>
                <input
                  id="email"
                  type="email"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                {/* <p className="text-red-500 text-xs italic">Your email is invalid</p> */}
              </div>
  
              <div className="mb-6">
                <label htmlFor="password" className="block text-white text-sm font-bold mb-2">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  className="appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
                {/* <p className="text-red-500 text-xs italic">Password is required</p> */}
              </div>
              <div className="mb-4">
                <label htmlFor="agree" className="block text-white text-sm font-bold mb-2">
                  <input
                    type="checkbox"
                    name="newsletter"
                    id="newsletter"
                    className="leading-tight"
                    value={newsletter}
                    onChange={handleNewsletter}                    
                  />
                  &nbsp; Receive newsletter with updates and new features on StormCenter (very rarely)
                </label>
                {/* <p className="text-red-500 text-xs italic">
                  You must agree with our Terms and Conditions
                </p> */}
              </div>  
              <div className="mb-4">
                <label htmlFor="agree" className="block text-white text-sm font-bold mb-2">
                  <input
                    type="checkbox"
                    name="agree"
                    id="agree"
                    className="leading-tight"
                    value={acceptedTerms}
                    onChange={handleAcceptedTerms}
                    required
                  />
                  &nbsp; I agree to the <a href="#" onClick={() => setShowTermsAndConditionsModal(true)} className="text-blue-500 hover:text-blue-800">Terms and Conditions</a>
                </label>
                {/* <p className="text-red-500 text-xs italic">
                  You must agree with our Terms and Conditions
                </p> */}
              </div>
              <div className="mb-4">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchaSiteKey}
                    onChange={handleRecaptchaChange}
                  />                
                </div>              
  
              <div className="flex items-center justify-center">
                <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  {loading ? <Loader className="w-6 h-6">Register</Loader> : "Register"}
                </button>
              </div>
              <div className="mt-4 text-center text-white">
                Already have an account? <Link to="/login" className="text-blue-500 hover:text-blue-800">Login</Link>
              </div>
            </form>
            <TermsAndConditionsModal open={showTermsAndConditionsModal} onClose={() => setShowTermsAndConditionsModal(false)} className={'text-black'}/>
          </div>
        </div>
         
      </div>
      <div className="justify-center mt-5">
          <h1>reCaptcha is currently not working and thus not registering</h1>
          <p>I am working on fixing this (2024-09-28)!</p>
      </div>
      <div className="justify-center mt-5">
          <h1>Features as a registered user</h1>
          <p>Registering is free!</p>
          <p>
            <ul>
              <li>Add favorite spotters to follow and see them on the map (with a trail)</li>
              <li>Save settings so you always have the same layer settings on startup, such as Radar, Tornado reports etc but also default slider settings etc.</li>
              <li>Receive email notifications on your choice of categorical and tornado risk. I.e. you can choose to get an email whenever there is e.g. a 15% tornado risk or Moderate Risk.</li>
              <li>Receive email notifications on categorical or tornado risk for a given (lat/lon) position, such as your home or target area.</li>
              <li>Receive email notifications on tornado reports nearby your given (lat/lon) position at a certain radius. This can be used to see if there is a tornado report near your home.</li>
            </ul>

          </p>
        </div>        
    </div>
     
  </section>
  </>
  
  );
};

export default Register;
