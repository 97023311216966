import React, { useEffect, useContext, useState } from "react";
import {Helmet} from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

import { EventBox } from "components/storm-history-list/EventBox";
import Pie from "components/Chart";
import NotFound from "pages/NotFound";

import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";
import { formatDate } from "utils/general-functions";
import { CATEGORICAL_INITS, TORNADO_RISK_INITS, CATEGORICAL_COLORS, TORNADO_COLORS } from "utils/constants";

import tornadoSimpleIcon from "assets/images/icons/tornado-simple.webp";
import hailIcon from "assets/images/icons/map/hail-round.webp";
import windWhiteIcon from "assets/images/icons/wind-icon-white.webp";

const EventYear = () => {    
    const currentURL = "https://stormcenter.app" + window.location.pathname;
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);    
    const eventService = new EventService();
    const {setEvents, events} = useContext(StormContext);

    const yyyyPattern = /^\d{4}$/;
    const { eventYear } = useParams();
    const navigate = useNavigate();

    let yearlyTotalTornadoReports = 0;
    let yearlyTotalHailReports = 0;
    let yearlyTotalWindReports = 0;    

    let yearlyTornadoRiskList = TORNADO_RISK_INITS
    let yearlyCategoricalList = CATEGORICAL_INITS

    Object.entries(events).forEach(([_, event]) => {
        const filteredEvent = event.filter((day_event) => !!day_event.day1_max_categorical )
        for (const ev of filteredEvent) {
          yearlyTotalTornadoReports += ev.tornado_reports;
          yearlyTotalHailReports += ev.hail_reports;
          yearlyTotalWindReports += ev.wind_reports;
          yearlyCategoricalList[ev.day1_max_categorical] += 1;
          yearlyTornadoRiskList[ev.day1_max_tornado] += 1;          
        }
      });    

    const outlooksPieData = {
        labels: Object.keys(yearlyCategoricalList).filter((_, index) => Object.values(yearlyCategoricalList)[index] !== 0),
        datasets: [{
            data: Object.values(yearlyCategoricalList).filter(value => value !== 0), // Filter out values equal to 0
            backgroundColor: [...CATEGORICAL_COLORS].filter((_, index) => Object.values(yearlyCategoricalList)[index] !== 0), // Filter corresponding colors
            hoverOffset: 6,
        }],
    }
    
    const tornadoPieData = {
        labels: Object.keys(yearlyTornadoRiskList).filter((_, index) => Object.values(yearlyTornadoRiskList)[index] !== 0).map(key => `${key}%`),
        datasets: [{
            data: Object.values(yearlyTornadoRiskList).filter(value => value !== 0), // Filter out values equal to 0
            backgroundColor: [...TORNADO_COLORS].filter((_, index) => Object.values(yearlyTornadoRiskList)[index] !== 0), // Filter corresponding colors
            hoverOffset: 6,
        }],
    };      

    useEffect(() => {
        (async () => {
            const response = await eventService.getEventsByYear({
                year: eventYear
            });

            const sortedData = response.reduce((acc, event) => {
                const yearMonth = event.year_month;
                acc[yearMonth] = acc[yearMonth] || [];
                acc[yearMonth].push(event);
                return acc;
            }, {});

            //console.log(sortedData)

            setEvents((prev) => {
                if (!prev[eventYear]) {
                    return sortedData
                } else if ( sortedData[eventYear] ) {
                    return { [eventYear]: [...prev[eventYear], ...sortedData[eventYear]]} 
                } else {
                    return prev
                }
            })
            
        })();
    }, [eventYear]);



    const PreviousYear = () => {
        return (
            Number(eventYear) > 2005 && (
          <button
            className={
              "border border-white cursor-pointer bg-transparent text-white rounded-[4px] p-1 "
            }
            onClick={() => {navigate(`/history/year/${Number(eventYear)-1}`)}}
          >
            &lt; Previous year
        </button>   
            )   
        )
      }
    
      const NextYear = () => {
        return (
            Number(eventYear) < new Date().getFullYear() && (
            <button
                className={
                "border border-white cursor-pointer bg-transparent text-white rounded-[4px] p-1 "
                }
                onClick={() => {navigate(`/history/year/${Number(eventYear)+1}`)}}
            >
                Next year &gt;
            </button>      
            )
        )
      }    
    
    if (!yyyyPattern.test(eventYear)) {
        return <NotFound /> // Return null to prevent rendering EventDate content
    }        

    const sortedYearMonth = Object.keys(events).sort((a, b) => new Date(a) - new Date(b));
    console.log("sortedYearMonth: ", sortedYearMonth)
    
    return (
        <>
            <Helmet>
                <title>Stats about storms and tornadoes in {eventYear}</title>
                <meta name="description" content={`How was ${eventYear} in terms of tornadoes and storms in the USA. Check out and see all the stats.`} />
                <link rel="canonical" href={currentURL} />
            </Helmet> 
            <div className="px-4 md:px-8 pb-8">
            {windowWidth >= 640 ?  
                // DESKTOP
                <div className={"flex flex-col sm:flex-row sm:justify-between items-center text-center gap-2"}>

                    <div className={"order-1 sm:mr-0 sm:order-1"}>
                        <PreviousYear />
                    </div>
                    <div className={"order-3 sm:order-2"}>
                    <h1>Storms in {eventYear}</h1>
                    </div>
                    <div className={"order-2 sm:ml-0 sm:order-3"}>
                        <NextYear />
                    </div>
                    </div>
                    : // MOBILE
                    <div className={"grid grid-cols-1 text-center"}>
                    <div className='flex justify-around'>
                        <div className={""}>
                        <PreviousYear />
                        </div>
                        <div className={""}>
                        <NextYear />
                        </div>
                    </div>
                    <div className={"my-3"}>
                    <h1>Storms in {eventYear}</h1>
                    </div>
                </div>
            }                
            {/* Other content for EventDate */}
            <div className="flex items-center">
                <h5 className="w-[150px] hidden sm:inline">SPC Reports</h5>
                {/* Tornado Reports */}
                <Tooltip title="SPC Tornado reports" className="text-xl">
                    <div className="flex items-center mr-2 sm:mr-4">
                        <div className="bg-[#ff0000] border-2 border-solid text-black rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                            <span className="text-2xl text-black text-bold">{yearlyTotalTornadoReports}</span>
                        </div>
                        <span className="ml-2 mr-4">
                            <img src={tornadoSimpleIcon} width="40px" alt="tornado" />
                        </span>
                    </div>
                </Tooltip>

                {/* Hail Reports */}
                <Tooltip title="SPC Hail reports" className="text-xl">
                    <div className="flex  items-center mr-2 sm:mr-4">
                        <div className="bg-[#e3e3e3] border-2 border-solid  text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                            <span className="text-xl text-black text-bold">{yearlyTotalHailReports}</span>
                        </div>
                        <span className="ml-2 mr-4">
                            <img src={hailIcon} width="40px" alt="hail" />
                        </span>
                    </div>
                </Tooltip>

                {/* Wind Reports */}
                <Tooltip title="SPC Wind reports" className="text-xl">
                    <div className="flex items-center">                                    
                        <div className="bg-[#e3e3e3] border-2 border-solid text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                            <span className="text-xl text-black font-bold">{yearlyTotalWindReports}</span>
                        </div>
                        <span className="ml-2 mr-4">
                            <img src={windWhiteIcon} width="40px" alt="tornado" />
                        </span>
                    </div>
                </Tooltip>
            </div>     

            <div className="flex flex-col lg:flex-row items-start justify-between mb-10">
                <div className="w-full lg:w-1/2">
                    <div className="flex mt-3 items-center">                                           
                    {/* Pie Chart */}
                    <div className="max-w-[320px] lg:max-w-[400px] ml-0 lg:mx-auto w-full">
                        <Pie data={outlooksPieData} />
                        <h2 className="block text-center">Outlooks</h2>
                    </div>
                    </div>


                        
                </div>
                <div className="w-full lg:w-1/2">
                    <div className="flex items-center mt-2">                                            
                        {/* Pie Chart */}
                        <div className="max-w-[320px] lg:max-w-[400px] ml-0 lg:mx-auto w-full">
                            <Pie data={tornadoPieData} />
                            <h2 className="text-center">Tornado risks</h2>
                        </div>
                    </div>


                </div>                            
            </div>

            {
                sortedYearMonth.map((year_month, i) => {
                    const event = events[year_month]
                    
                    const [year, month] = year_month.split('-');

                    // Convert the month number to an integer (removing leading zeros)
                    const monthNumber = parseInt(month, 10);

                    let totalTornadoReports = 0;
                    let totalHailReports = 0;
                    let totalWindReports = 0;

                    // Not sure why it bugs when I fetch the constants here instead
                    const tornadoRiskList = {
                        "0": 0,
                        "2": 0,
                        "5": 0,
                        "10": 0,
                        "15": 0,
                        "30": 0,
                        "45": 0
                    }
                    const categoricalList = {
                        "NO TSTM": 0,
                        "TSTM": 0,
                        "MRGL": 0,
                        "SLGT": 0,
                        "ENH": 0,
                        "MDT": 0,
                        "HIGH": 0
                    }
                    // Array of month names starting from January (index 0) to December (index 11)
                    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                    const monthName = monthNames[monthNumber - 1];
                    const filteredEvent = event.filter((day_event) => !!day_event.day1_max_categorical )

                    for (const ev of filteredEvent) {
                        totalTornadoReports += ev.tornado_reports;
                        totalHailReports += ev.hail_reports;
                        totalWindReports += ev.wind_reports;
                        categoricalList[ev.day1_max_categorical] += 1;
                        tornadoRiskList[ev.day1_max_tornado] += 1;
                    }
                    
                    return (
                        <div className="block" key={i}>
                            <h1 className="block w-full mb-3"> 
                                <a href={`/history/month/${year}-${month}`}>{monthName} {year} </a>
                            </h1>
                            <div className="flex items-center">
                                <h5 className="w-[150px] hidden sm:inline">SPC Reports</h5>
                                {/* Tornado Reports */}
                                <Tooltip title="SPC Tornado reports" className="text-xl">
                                    <div className="flex items-center mr-2 sm:mr-4">
                                        <div className="bg-[#ff0000] border-2 border-solid text-black rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                            <span className="text-2xl text-black text-bold">{totalTornadoReports}</span>
                                        </div>
                                        <span className="ml-2 mr-4">
                                            <img src={tornadoSimpleIcon} width="40px" alt="tornado" />
                                        </span>
                                    </div>
                                </Tooltip>

                                {/* Hail Reports */}
                                <Tooltip title="SPC Hail reports" className="text-xl">
                                    <div className="flex  items-center mr-2 sm:mr-4">
                                        <div className="bg-[#e3e3e3] border-2 border-solid  text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                            <span className="text-xl text-black text-bold">{totalHailReports}</span>
                                        </div>
                                        <span className="ml-2 mr-4">
                                            <img src={hailIcon} width="40px" alt="hail" />
                                        </span>
                                    </div>
                                </Tooltip>

                                {/* Wind Reports */}
                                <Tooltip title="SPC Wind reports" className="text-xl">
                                    <div className="flex items-center">                                    
                                        <div className="bg-[#e3e3e3] border-2 border-solid text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                            <span className="text-xl text-black font-bold">{totalWindReports}</span>
                                        </div>
                                        <span className="ml-2 mr-4">
                                            <img src={windWhiteIcon} width="40px" alt="tornado" />
                                        </span>
                                    </div>
                                </Tooltip>
                            </div>
                            <div className="flex mt-3 items-center">
                                <h5 className="w-[150px] hidden sm:inline">Outlooks</h5>
                                {Object.keys(categoricalList).map(key => (
                                    <div className="flex flex-col items-center mr-3 sm:mr-4" key={key}>
                                        <div 
                                            className={`${key === 'NO TSTM' ? 'bg-[#fff]' : `lvl-bg-${key}`} 
                                                border-2 border-solid border-[#afb200] text-black text-xl font-bold rounded shadow hover:shadow-md outline-none w-9 h-9 sm:w-10 sm:h-10 flex items-center justify-center cursor-pointer`}
                                        >
                                            {categoricalList[key]}
                                        </div>
                                        <span className="">
                                            {key === 'NO TSTM' ? 'NONE' : key}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-center mt-2">
                                <h5 className="w-[150px] hidden sm:inline">Tornado risks</h5>
                                {Object.keys(tornadoRiskList).map(key => (
                                    <div className="flex flex-col items-center mr-3 sm:mr-4" key={key}>
                                        <div
                                            className="bg-[#e3e3e3] border-2 border-solid border-[#afb200] text-black text-xl font-bold rounded shadow hover:shadow-md outline-none w-9 h-9 sm:w-10 sm:h-10 flex items-center justify-center cursor-pointer"
                                        >
                                            {tornadoRiskList[key]}
                                        </div>
                                        <span className="">
                                            {key} %
                                        </span>
                                    </div>
                                ))}
                            </div>                            
                            <div className={'flex w-full flex-wrap gap-2 mt-2'}>

                                {event.map((item, i) => {
                                    item.event_date = String(new Date(item.event_date).toLocaleString("CDT"));

                                    let bg = item.day1_max_categorical ? item.day1_max_categorical : item.day1_max_categorical ? item.day1_max_categorical : "nostorms";

                                    let dateStr = item.event_date.slice(0, 10).replace(",", "");
                                    let formatedDate = formatDate(item.event_date);
                                    let significantClass = "";

                                    if (item.tornado_reports && parseFloat(item.tornado_reports) > 20) {
                                        significantClass = "significant";
                                    } else if (item.significance_rank >= 2) {
                                        significantClass = "significant";
                                    }

                                    var outlookLabelFull = item.day1_max_categorical ? item.day1_max_categorical : "NO TSTM";

                                    const affectedStates = item.affected_states && item.affected_states[0].tornadoReports.map((report) => report.state);
                                    
                                    return (                                
                                        <EventBox 
                                            key={i}
                                            formatedDate        = {formatedDate}
                                            significantClass    = {significantClass}
                                            i                   = {i}
                                            bg                  = {bg}
                                            dateStr             = {dateStr}
                                            item                = {item}
                                            outlookLabelFull    = {outlookLabelFull}
                                            affectedStates      = {affectedStates}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    );
                })
            }
        </div>            
        </>

    );
};

export default EventYear;