import {Helmet} from "react-helmet";
import React, { useState, useEffect } from "react";

import ArticleTemplate from "components/ArticleTemplate";
import OutlookService from "services/OutlookService";
import { getUtcTimestampFromOnlyNumbersTimestamp } from "utils/general-functions";

const outlookService = new OutlookService();

const ForecastText = () => {
    const [outlookData, setOutlookData] = useState(null);
    const [forecastDate, setForecastDate] = useState("")

    useEffect(() => {
        
        async function fetchOutlooks() {
            const data = await outlookService.getSpcOutlooks();
            const todayData = data[1] // I.e. Day 1
            console.log("todayData", todayData)
            setOutlookData(todayData);

            let validStartDate = new Date(getUtcTimestampFromOnlyNumbersTimestamp(todayData.categorical[1].properties.VALID)).toLocaleString('en-US', {
            timeZone: 'America/Chicago',
            })  
            setForecastDate(new Date(validStartDate).toISOString().slice(0, 10))    
            //let expire = new Date(getUtcTimestampFromOnlyNumbersTimestamp(todayData.categorical[1].properties.EXPIRE))
            //    console.log("AVG",avgTimestamp)
            //const avgTimestamp = (valid.getTime() + expire.getTime()) / 2;
        }
    
        fetchOutlooks();
      }, []);

    console.log("outlookData", outlookData)

    return (        
        <ArticleTemplate headline={`SPC Outlook valid for ${forecastDate}`} >
            <Helmet>
                <title>SPC Outlook for Text to Speech {forecastDate}</title>
                <meta name="description" content="Current SPC Outlook Text in simple text format, without abbreviations, to be used for text-to-speech." />
            </Helmet>   
            {outlookData && outlookData.outlook_summary?.unabbreviated_outlook_text ? (
                <>
                <p
                    dangerouslySetInnerHTML={{
                    __html: outlookData?.outlook_summary?.unabbreviated_outlook_text?.replaceAll("\n", "<br/>").replaceAll("MLCAPE", "Mid Level CAPE"),
                    }}
                ></p>
                </>
            ) : (
                <p>No outlook text exist for this date (at the moment). This should be updated soon.</p>
            )}  
        </ArticleTemplate>
    );
};

export default ForecastText;