import React, {useContext, useEffect, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import { Tooltip } from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCircleInfo,
    faRotate
} from "@fortawesome/free-solid-svg-icons";

import "./Events.css";
import EventService from "services/EventService";
import {StormContext} from "contexts/StormContext";

import {CATEGORICAL_RISK_FULL_NAME_FROM_CODE} from "utils/constants"
import { getCurrentDate, getFromDate } from "utils/general-functions";

// Components
import {SignificantEvents} from "components/storm-history-list/SignificantEvents";
import {WikiOutbreaks} from "components/storm-history-list/WikiOutbreaks";
import EventList from "components/storm-history-list/EventList";

const Events = () => {
    const eventService = new EventService();

    const contentRef = useRef()

    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [continueGettingData, setContinueGettingData] = useState(true);
    const {setEvents, setIsLoading} = useContext(StormContext);
    const [wikiEvents, setWikiEvents] = useState([]);
    const [significantEvents, setSignificantEvents] = useState([]);

    const [filterApplied, setFilterApplied] = useState(false);
    const [selectedOptionFrom, setSelectedOptionFrom] = useState("0");
    const [selectedOptionTo, setSelectedOptionTo] = useState("8");
    const [tornadoPercentage, setTornadoPercentage] = useState("0");
    const [tornadoCount, setTornadoCount] = useState(0)
    const [dateFrom, setFromDate] = useState(getFromDate());
    const [dateTo, setDateTo] = useState(getCurrentDate());

    const isInitialRender = useRef(true);

    const resetEventFilter = () => {
        setSelectedOptionFrom("0")
        setSelectedOptionTo("8")
        setTornadoCount(0)
        setTornadoPercentage("0")
        setFromDate(getFromDate())
        setDateTo(getCurrentDate())

        setCurrentPage(0);
        setFilterApplied(false)
        searchEvents()
    }

    useEffect(() => {
        (async () => {
            Promise.all([eventService.getWikiEvents(), eventService.getSignificantEvents(),]).then(([wiki_events, significant_events]) => {
                setWikiEvents(JSON.parse(wiki_events));
                setSignificantEvents(JSON.parse(significant_events).slice(0, 12));
            });
        })();
    }, []);

    const searchEvents = async () => {
        console.log("Searching general...")
        setIsLoading(true)
        const response = await eventService.searchEvents({
            outlook_min: selectedOptionFrom,
            outlook_max: selectedOptionTo,
            tornado_percentage: tornadoPercentage,
            tornado_reports: tornadoCount,
            first_date: dateFrom,
            last_date: dateTo,
            limit: 60,
            offset: (currentPage) * 60
        });
        //console.log("EventsResponse: ", response)
        const sortedData = response.reduce((acc, event) => {
            const yearMonth = event.year_month;
            acc[yearMonth] = acc[yearMonth] || [];
            acc[yearMonth].push(event);
            return acc;
        }, {});
        //console.log("SortedData: ", sortedData)
        setIsLoading(false);
        setEvents(sortedData);
    }

    useEffect(() => {
        (async () => {
            console.log("FilterSearching...")
            if(dateFrom && dateTo) {
                if (
                    selectedOptionFrom !== '0' ||
                    selectedOptionTo !== '8' ||
                    tornadoPercentage !== '0' ||
                    tornadoCount !== 0 ||
                    dateFrom !== getFromDate() ||
                    dateTo !== getCurrentDate()
                ) {
                    console.log("Filter is not default")
                    setFilterApplied(true);
                    searchEvents()                
                } else {
                    console.log("Filter is default")
                    setFilterApplied(false);
                    searchEvents()
                }
            }                  
            setContinueGettingData(true);
            setCurrentPage(0);
        })();
    }, [selectedOptionFrom, selectedOptionTo, tornadoPercentage, tornadoCount, dateFrom, dateTo]);


    useEffect(() => {
        (async () => {
            const response = await eventService.searchEvents({
                outlook_min: selectedOptionFrom,
                outlook_max: selectedOptionTo,
                tornado_percentage: tornadoPercentage,
                tornado_reports: tornadoCount,
                first_date: dateFrom,
                last_date: dateTo,
                limit: 60,
                offset: (currentPage) * 60
            });

            const sortedData = response.reduce((acc, event) => {
                const yearMonth = event.year_month;
                acc[yearMonth] = acc[yearMonth] || [];
                acc[yearMonth].push(event);
                return acc;
            }, {});

            if (response.length === 0) {
                setContinueGettingData(false);
            }
            console.log("Current page", dateFrom, dateTo)
            setEvents((prevEvents) => {
                const updatedData = {...prevEvents}
                
                // ChatGPT fix for removing duplicate events
                Object.keys(sortedData).forEach((key) => {
                    // Initialize the array for the key if it doesn't exist
                    if (!updatedData[key]) {
                        updatedData[key] = [];
                    }
                    
                    // Create a Set of existing IDs for quick lookup
                    const existingIds = new Set(updatedData[key].map(event => event.id));
                    
                    // Merge, ensuring no duplicate IDs
                    sortedData[key].forEach(event => {
                        if (!existingIds.has(event.id)) {
                            updatedData[key].push(event);
                            existingIds.add(event.id); // Update the Set with the new ID
                        }
                    });
                });
    
                return updatedData;
            });

            setLoading(false)
        })();
    }, [currentPage]);

    // Infinite Scroll
    useEffect(() => {
        const element = contentRef.current;

        if ( !element ) { return; }

        function handleScroll() {
            const { scrollTop, clientHeight, scrollHeight } = element;
            if (scrollTop + clientHeight + 10 >= scrollHeight) {
                if (continueGettingData && !loading) {
                    setCurrentPage((prevPage) => prevPage + 1);
                    setLoading(true);
                }
            }
        }

        element.addEventListener("scroll", handleScroll);
        return () => {
            element.removeEventListener("scroll", handleScroll);
        };
    }, [continueGettingData, loading]);


    return (
        <>
            <Helmet>
                <title>All SPC Outlooks & tornado stats since 2004</title>
                <meta name="description" content="Explore historical storms through SPC Outlooks, tornado risks, resulting tornado reports etc since 2004, e.g. What were the predictions the week around May 31st 2013?" />
            </Helmet>
            <div className='px-4 md:px-8 h-full overflow-y-scroll' ref={contentRef}>
                <h1 className={"pl-1.5"}>Storm events in history</h1>
                <p className="m-1 text-justify text-sm md:text-base">
                    Maximal categorical (and tornado) outlook per day based on the forecast
                    with the highest values by SPC, as well as the reported tornadoes (T),
                    hail (H) and wind (W) for that day. Data is available from April 2004.
                    Note that tornadoes (T) here represent tornado <em>reports</em> and not
                    confirmed tornadoes by SPC. Several tornado reports could be reported on
                    the same tornado and a tornado report could be false.
                </p>

                <div className="events-history-section m-[0.5rem]">
                    <div className="events-date-section min-[1169px]:grid min-[1169px]:grid-cols-12">
                        <SignificantEvents significantEvents={significantEvents}/>
                        <WikiOutbreaks wikiEvents={wikiEvents}/>
                    </div>
                    <h2 className={'w-full block'}>Filter <Tooltip title="Reset filter"><FontAwesomeIcon icon={faRotate} onClick={resetEventFilter} className="cursor-pointer" /></Tooltip></h2>
                    <div
                        className="grid grid-cols-2 md:grid-cols-3 min-[1169px]:grid-cols-6 gap-x-8 justify-center mt-5 mb-10">                
                        <div className={'w-full'}>
                            <Tooltip title="Minimum Outlook risk">
                            <label
                                htmlFor="from"
                                className="block text-sm font-medium leading-6 text-white"
                            >
                                From <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>                        
                            </label>
                            </Tooltip>
                            <select
                                id="from"
                                name="from"
                                className="mt-2 block w-full min-[1169px]-full rounded-md border-0 py-1.5 pl-3 pr-5 lg:pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs leading-6"
                                value={selectedOptionFrom}
                                onChange={(e) => setSelectedOptionFrom(e.target.value)}
                            >
                                {Object.entries(CATEGORICAL_RISK_FULL_NAME_FROM_CODE).map(([key, value]) => (<option
                                    key={key}
                                    value={key}
                                >
                                    {value}
                                </option>))}
                            </select>
                        </div>
                        <div className={'w-full'}>
                            <Tooltip title="Maximum Outlook risk">
                            <label
                                htmlFor="to"
                                className="block text-sm font-medium leading-6 text-white"
                            >
                                To <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>
                            </label>
                            </Tooltip>
                            <select
                                id="to"
                                name="to"
                                className="mt-2 block w-full min-[1169px]:w-full rounded-md border-0 py-1.5 pl-3 pr-5 lg:pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs leading-6"
                                value={selectedOptionTo}
                                onChange={(e) => setSelectedOptionTo(e.target.value)}
                            >
                                {Object.entries(CATEGORICAL_RISK_FULL_NAME_FROM_CODE).map(([key, value]) => (<option
                                    key={key}
                                    value={key}
                                >
                                    {value}
                                </option>))}
                            </select>
                        </div>
                        <div className="my-2 md:my-0 min-[1169px]:my-0 w-full">
                            <Tooltip title="Minimum SPC tornado risk percentage">
                                <label
                                    htmlFor="dropdown"
                                    className="block text-sm font-medium leading-6 text-white"
                                >
                                    Tornado <span className="hidden l:inline-block">percentage</span><span className="sm:inline-block hidden">%</span> <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>
                                </label>
                            </Tooltip>
                            <select
                                id="dropdown"
                                value={tornadoPercentage}
                                onChange={(e) => setTornadoPercentage(e.target.value)}
                                className="min-[1169px]:my-2 block w-full mt-2 rounded-md border-0 py-[6.3px] pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs sm:leading-6"
                            >
                                <option value="0">0</option>
                                <option value="2">2</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                            </select>
                        </div>
                        <div className="my-2 md:my-0 w-full">
                            <Tooltip title="Minimum tornado count">                    
                            <label className=" block text-sm font-medium leading-6 text-white">
                                Tornado count <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>
                            </label>
                            </Tooltip>
                            <input type="number"
                                value={tornadoCount}
                                className="mt-2 w-[-webkit-fill-available] rounded-md border-0 py-[3.5px] px-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm leading-6"
                                onChange={(e) => setTornadoCount(e.target.value)}
                            />
                        </div>

                        {/* // dates portion */}
                        <div className="w-full">
                            <Tooltip title="First date">
                            <label
                                className="block text-sm font-medium leading-6 text-white"
                                htmlFor="dateFromInput"
                            >

                                Date from <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>
                            </label>
                            </Tooltip>
                            <input
                                type="date"
                                value={dateFrom}
                                id="dateFromInput"
                                onChange={(e) => setFromDate(e.target.value)}
                                max={dateTo}
                                className="w-[-webkit-fill-available] mt-2 block min-[1169px]:w-11/12 rounded-md border-0  py-[3px] pl-3 pr-6 lg:pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs leading-6 sm:leading-5"
                            />
                        </div>
                        <div className="w-full">
                            <Tooltip title="Last date">
                            <label className="block text-sm font-medium leading-6 for text-white ">
                                Date to <FontAwesomeIcon icon={faCircleInfo} className="text-[#fff] text-xs"/>
                            </label>
                            </Tooltip>
                            <input
                                type="date"
                                value={dateTo || 0}
                                onChange={(e) => setDateTo(e.target.value)}
                                min={dateFrom}
                                className="w-[-webkit-fill-available] min-[1169px]:w-11/12 mt-2 block  rounded-md border-0  py-[3px] pl-3 pr-6 lg:pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-xs leading-6 sm:leading-5"
                            />
                        </div>
                    </div>
                </div>
                <EventList loading={loading} filterApplied={filterApplied}/>
            </div>
        </>);
};

export default Events;