import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";

import EventService from "services/EventService";
import { StormContext } from "contexts/StormContext";
import { getHeatmapColor } from "utils/general-functions";
import {
  ABBREV_MONTH_NAMES,
  CATEGORICAL_INITS,
  TORNADO_RISK_INITS,
} from "utils/constants";

const EventYearMonthAll = () => {
  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const eventService = new EventService();
  const { setEvents, events } = useContext(StormContext);
  const [eventsLoaded, setEventsLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await eventService.getEventsByYearMonthAll();

      const sortedData = response.reduce((acc, event) => {
        const yearMonth = event.year_month;
        acc[yearMonth] = acc[yearMonth] || [];
        acc[yearMonth].push(event);
        return acc;
      }, {});

      const sortedArray = Object.entries(sortedData);

      sortedArray.sort(function (a, b) {
        return a[0].localeCompare(b[0]);
      });

      //console.log(sortedArray)

      // Convert the sorted array back to an object
      const sortedDataByIndex = Object.fromEntries(sortedArray);
      console.log("sortedDataByIndex", sortedDataByIndex);

      setEvents(sortedDataByIndex);
      setEventsLoaded(true);
    })();
  }, []);

  Object.entries(events).map(([year_month, event], i) => {
    let totalTornadoReports = 0;
    let totalHailReports = 0;
    let totalWindReports = 0;
    let tornadoRiskList = TORNADO_RISK_INITS;
    let categoricalList = CATEGORICAL_INITS;

    const filteredEvent = event.filter((day_event) => !!day_event.day1_max_categorical )
    
    for (const ev of filteredEvent) {
      totalTornadoReports += ev.tornado_reports;
      totalHailReports += ev.hail_reports;
      totalWindReports += ev.wind_reports;
      categoricalList[ev.day1_max_categorical] += 1;
      tornadoRiskList[ev.day1_max_tornado] += 1;
    }
  });

  const generateTableData = () => {
    const tableData = {};
    Object.entries(events).map((item) => {
      //console.log("item", item)
      const [year, month] = item[0].split("-");
      const totalTornadoReports = item[1][0].sum_tornado_reports;
      if (!tableData[year]) {
        tableData[year] = {};
      }
      tableData[year][month] = totalTornadoReports;
    });
    //console.log(tableData)
    return tableData;
  };

  const tableData = generateTableData();
  // const years = Object.keys(tableData);
  const years = Object.keys(tableData).sort(
    (a, b) => parseInt(b) - parseInt(a)
  );

  // Create an array of months (1-12)
  const months = Array.from({ length: 12 }, (_, index) =>
    String(index + 1).padStart(2, "0")
  );

  // Render conditionally based on whether events are loaded
  if (!eventsLoaded) {
    // You can render a loading indicator or return null
    return <p>Loading...</p>;
  }

  return (
    <>
      <Helmet>
        <title>Monthly stats (heatmap) of tornadoes since 2004</title>
        <meta
          name="description"
          content="Heatmap showing number of tornadoes in the USA since 2004 on a monthly aggregate."
        />
        <link rel="canonical" href={currentURL} />
      </Helmet>
      <div className="overflow-y-auto h-full px-4 md:px-8">
        <h1>Tornadoes by month</h1>
        <p>
          This is a table showing the amount of tornado (reports) to SPC by
          month, since 2004.
        </p>
        <TableContainer component={Paper} sx={{ mb: 3 }}>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="Tornado Reports Table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    position: "sticky",
                    left: 0,
                    background: "white",
                    zIndex: 1,
                  }}
                >
                  Year
                </TableCell>
                {Array.from({ length: 12 }, (_, index) => (
                  <TableCell key={index}>{ABBREV_MONTH_NAMES[index]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(tableData).length ? (
                years.map((year) => (
                  <TableRow key={year}>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: 0,
                        background: "white",
                        zIndex: 1,
                      }}
                    >
                      {year}
                    </TableCell>
                    {months.map((month) => (
                      <TableCell
                        key={month}
                        style={{
                          background: getHeatmapColor(
                            tableData[year]?.[month] || 0
                          ),
                        }}
                        sx={{
                          p: 0,
                          height: 50,
                          "& > div": { height: "inherit" },
                        }}
                      >
                        <Box
                          sx={{
                            display: " flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",

                            "& > a": {
                              display: " flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            },
                          }}
                        >
                          <a
                            className="text-black text-center"
                            href={`/history/month/${year}-${month}`}
                          >
                            {tableData[year]?.[month] || "-"}
                          </a>
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={13}
                    sx={{
                      position: "sticky",
                      left: 0,
                      background: "white",
                      zIndex: 1,
                      maxWidth: "100px", // Set maximum width to 50px
                      width: "100px", // Set width to 50px
                      p: 0,
                      height: 50,
                    }}
                  >
                    <Box sx={{ textAlign: "center", py: 2 }}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default EventYearMonthAll;
