import React from 'react'
import {Helmet} from "react-helmet";

import ArticleTemplate from 'components/ArticleTemplate';

const Privacy = () => {
    return (
        <ArticleTemplate headline='Privacy Policy'>
        <Helmet>
            <title>Privacy</title>
            <meta name="description" content="Read on how to protect your privacy and the privacy terms in regards to StormCenter" />
        </Helmet>               
            <h2>This privacy policy in short</h2>
            <p>
                <ul>
                    <li>StormCenter uses Google Analytics to analyze visitor statistics etc.</li>
                    <li>StormCenter only saves spotter position data for maximum 48 hours, as per SpotterNetwork.org's Terms
                        of Use
                    </li>
                    <li>Data collected by StormCenter from other sources will never be sold or given to any third parties.
                    </li>
                    <li>StormCenter uses privacy related data mainly from two open data sources (API:s): SpotterNetwork.org
                        and Twitter
                    </li>
                    <li>You can always request a removal of your data from StormCenter by emailing info@stormchasingusa.com.
                        Please note, however, that your data is still public on SpotterNetwork.org.
                    </li>
                    <li>Your GPS data will be broadcasted to SpotterNetwork if you have selected to report your location in
                        SpotterNetwork affiliated software applications such as RadarScope and GRLevelX. This data
                        broadcast, and privacy concerns regarding it, is under NO control of StormCenter. See the settings
                        of your software applications to see if you are broadcasting/reporting your location.
                    </li>
                    <li>Being able to report your location is important while submitting storm reports but DO consider
                        turning off the report of your location when you are not actively chasing storms.
                    </li>
                </ul>
            </p>
            <h2>Data used from SpotterNetwork.org</h2>
            <p>
                <ul>
                    <li>GPS positions, timestamps, directions and elevation</li>
                    <li>Name</li>
                    <li>Reports</li>
                    <li>Spotter ID</li>
                    <li>Twitter account</li>
                </ul>
            </p>
            <h2>Data used from Twitter</h2>
            <p>
                <ul>
                    <li>Twitter profile information and profile photo</li>
                    <li>Tweets</li>
                    <li>Tweet geolocation (if available)</li>
                    <li>Images/photos in tweets</li>
                </ul>
            </p>
            <h2>Persistency of data</h2>
            <p>The entire idea of the application is to give an overview of <strong>the last 24 hours</strong>. Only
                data (such as positions and tweets) related to the last 24 hours is published. Positions of any storm
                spotters are kept for maximum 48 hours and are then deleted.</p>

            <h2>Removing your data</h2>
            <h3>Choose when to publish your GPS data - and when not to</h3>
            <p>Your GPS positions are broadcasted from software applications such as RadarScope and GRLevelX (see
                SpotterNetwork.org for a full list of applications broadcasting GPS data to SpotterNetwork) on your
                phone or computer to SpotterNetwork, where it is stored and made public. StormCenter uses this (public)
                data to show positions of chasers. GPS data is only broadcasted if you have accepted to 'Report
                location' (or equivalent setting) in these software applications.</p>
            <p>In order to only show GPS while actively chasing, choose to activate the 'Report location'-setting only
                when you head out to chase. Turn it off when this information is no longer needed to be broadcasted.</p>

            <h3>Remove your data from StormCenter</h3>
            <p>You can always request a removal of your data from StormCenter by emailing info@stormchasingusa.com with
                the name (as given in SpotterNetwork) and your Spotter Network public ID. In order to attain your
                Spotter Network public ID log in to https://www.spotternetwork.org/login and you will see this
                information in the 'Spotter Information' table.</p>
            <p>By choosing to completely remove your data, your SpotterNetwork account will also be ignored in further
                retrieval of data to StormCenter. Please NOTE, however, that this will NOT stop GPS data from being
                broadcasted from software applications to SpotterNetwork.org. Your GPS data could still be public (only
                not used in the StormCenter application). In order to not broadcast your GPS data, you need to adjust
                the 'Report location'-settings of SpotterNetwork affiliated software applications.</p>
            <h3>Removing Twitter data</h3>
            <p>In order to not have your tweets being shown publicly you can choose to make your Twitter account
                'Protected' (refer to Twitter documentation). If you want your tweets to not be published on StormCenter
                without making your Twitter account 'Protected', please contact @stormchasingusa in the Twitter
                messaging system and ask for a removal. If you do not want to broadcast the geolocation of your tweets,
                make sure you do not actively choose to provide this information while publishing a tweet (refer to
                Twitter documentation).</p>
            <h2>Cookie Usage and Data Collection</h2>
            
            <p>We value your privacy and are committed to providing transparency regarding the use of cookies on our website. This Privacy Statement explains how we use cookies and what data we collect through them.</p>

            <h3>1. Google Analytics</h3>

            <p>We use Google Analytics to better understand how visitors interact with our website. Google Analytics is a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to collect information about your visit, such as the pages you view, the time you spend on our site, and the website that referred you to us. We use this information to improve our website and provide you with a better user experience.</p>

            <p>Google Analytics cookies do not collect personally identifiable information. We do not merge any personally identifiable information with the data collected through Google Analytics.</p>

            <p>You can learn more about Google Analytics' privacy practices and opt-out options by visiting Google's Privacy & Terms page: Google Privacy & Terms</p>

            <h3>2. User Choices</h3>

            <p>We use cookies to save certain choices you make on our website, such as your time indicator preferences and map style preferences. These cookies help us provide you with a customized experience during your visit and remember your preferences for future visits. These cookies do not collect personal information, and the data they store is used solely to enhance your user experience.</p>

            <p>By using our website, you consent to the use of these cookies for saving your preferences.</p>

            <p>You can manage your cookie preferences at any time by adjusting your browser settings or using the cookie consent tool provided on our website.</p>

            <h3>3. Your Consent</h3>

            <p>By continuing to use our website, you consent to the use of cookies as described in this Privacy Statement. If you do not agree to the use of cookies or have any concerns about their use, you may choose to disable cookies in your browser settings. However, please note that disabling cookies may affect the functionality and user experience of our website.</p>
        
            <p>If you have any questions or concerns about our use of cookies or this Privacy Statement, please contact me at [christoffer@stormchasingusa.com].</p>

            <h2>Further information</h2>
            <p>If you have any questions about this privacy policy, please do contact info@stormchasingusa.com for
                further information.</p>
        </ArticleTemplate>
    )
}

export default Privacy
