// AuthContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Initial state
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  userSettings: null
};

// Reducer
const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        userSettings: action.payload.userSettings
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        userSettings: null
      };
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        userSettings: JSON.stringify(action.payload.settings)
      };
    default:
      return state;
  }
};

// Create context
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const email = localStorage.getItem("email");
    const token = localStorage.getItem("token");
    const userSettings = localStorage.getItem("userSettings");

    if (email && token) {
      dispatch({ type: 'LOGIN', payload: { user: email, token, userSettings } });
    }
  }, []);

  const login = (user, token, userSettings) => {
    localStorage.setItem("email", user);
    localStorage.setItem("token", token);
    localStorage.setItem("userSettings", userSettings);

    dispatch({ type: 'LOGIN', payload: { user, token, userSettings } });
  };

  const logout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("token");
    localStorage.removeItem('userSettings');
    dispatch({ type: 'LOGOUT' });
  };

  const updateSettings = (settings) => {
    localStorage.setItem("userSettings", JSON.stringify(settings));
    dispatch({ type: 'UPDATE_SETTINGS', payload: { settings } });
  };

  return (
    <AuthContext.Provider value={{ state, login, logout, updateSettings }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
