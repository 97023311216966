import axiosService from "utils/axios";

export default class OutlookService {

    async getSpcOutlooks() {
        return (await axiosService.post(`/get-outlooks`)).data
    }

    async getActiveOutlook(){
        return (await axiosService.post(`/get-active-outlook`)).data
    }
}