import axios from "axios";

const SERVER_URL = process.env.REACT_APP_BACKEND_URL

//console.log("Backend:", SERVER_URL)

const axiosService = axios.create({
    baseURL: SERVER_URL,
});

export default axiosService;