import { useContext } from 'react';
import { StormContext } from 'contexts/StormContext';
import SpotterService from 'services/SpotterService';
import { useAuth } from 'contexts/AuthContext';

export const useToggleFavorite = () => {
  const { state } = useAuth();
  const {
    spotterFavorites,
    setSpotterFavorites
  } = useContext(StormContext);
  const spotterService = new SpotterService();

  const toggleSpotterFavorite = (spotterId) => {
    const isFavorite = spotterFavorites.includes(spotterId);
    const { user } = state;

    if (spotterId !== null && user) {
      if (isFavorite) {
        const updatedFavorites = spotterFavorites.filter((id) => id !== spotterId);
        setSpotterFavorites(updatedFavorites);
        deleteSpotterFavorite(spotterId, user);
      } else {
        setSpotterFavorites([...spotterFavorites, spotterId]);
        addSpotterFavorite(spotterId, user);
      }
    }
  };

  const addSpotterFavorite = async (spotter_id, email) => {
    await spotterService.addSpotterFavorite(email, spotter_id);
  };

  const deleteSpotterFavorite = async (spotter_id, email) => {
    await spotterService.deleteSpotterFavorite(email, spotter_id);
  };

  return { toggleSpotterFavorite };
};
