import React from 'react'
import {Helmet} from "react-helmet";
// import {spotters} from './Spotters'
import {SpotterRow} from 'components/spotters/SpotterRow'

const spotters = []
function SpottersFavorites() {
    return (
    <>
            <Helmet>
        <title>Favorite spotters</title>
        <meta name="description" content="Follow your favorite storm chasers." />
        </Helmet> 
    <div className='w-[95%]  mx-auto'>
            <h1 className='border border-white'>
                Favorites list
            </h1>
            <p>
                Add spotters to your Favorites list to be able to follow/filter them in the map. To add a spotter as a
                favorite, click the Star icon after either a Search or after clicking his/her marker in the map. </p>

            <div className="Torando-report-list pb-6 ">
                <div className=" overflow-x-auto">
                    <table className='w-full table border-collapse border-spacing-0.5 bg-[#012432ba] rounded-md'>
                        <thead>
                        <tr className='bg-transparent border-solid border-0 border-b border-[#ffffff54]'>
                            <th className=' font-bold text-left p-[8px] sm:min-w-[150px]  lg:min-w-[230px]'>
                                Name
                            </th>
                            <th className=' font-bold text-left p-[8px] sm:min-w-[120px] lg:min-w-[200px]'>
                                Twitter
                            </th>
                            <th className=' font-bold text-left p-[8px] min-w-[200px] lg:min-w-[300px]'>
                                Last SN ping
                            </th>
                            <th className=' font-bold text-left p-[8px] min-w-[130px] lg:min-w-[150px]'>
                                Last known position
                            </th>
                            <th className=' font-bold text-left p-[8px] '>
                                Miles last 24h
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {spotters.length > 0 ? spotters.map((item) => 
                            <SpotterRow key={item.id}
                                name={item.name}
                                lastName={item.lastName}
                                twitter={item.twitter}
                                lastSnPing={item.lastSnPing}
                                lastPosition={item.lastPosition}
                                distanceCovered={item.distanceLast24h}
                            />
                        ) :
                            <tr className='bg-transparent  text-white border-solid border-0 border-b border-[#ffffff54]'>
                                <td className='p-[8px] align-baseline text-center' colSpan="4">
                                    No favorites added
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </>
    )
}

export default SpottersFavorites