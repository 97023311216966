import {
    utcDateToReadable,
    getUtcTimestampFromOnlyNumbersTimestamp,
    getRelativeTimeFromDateTime,
    getDayOfTheWeekCdt,
  
  } from "utils/general-functions";

export const OutlookPopupInfo = ({outlookGeneral, clickedOutlook}) => {
    const maxCategoricalOutlook = outlookGeneral.categorical[outlookGeneral.categorical.length -1].properties
    const maxTornadolOutlook = outlookGeneral.tornado && outlookGeneral.tornado[outlookGeneral.tornado.length -1].properties
    return (
        <div className="text-black overflow-y-scroll max-h-[450px]">
            <h3 className="text-black">
            {outlookGeneral.dayName} Convective Outlook: {maxCategoricalOutlook && getDayOfTheWeekCdt(utcDateToReadable(getUtcTimestampFromOnlyNumbersTimestamp(maxCategoricalOutlook.VALID), 'cdt'), 'long') }
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-2 gap-4">
                <div className="text-left inline-block">
                    <span className="font-bold">Max outlook</span>
                </div>
                <div className="maxCategoricalOutlook-left ">
                    <span>{maxCategoricalOutlook && maxCategoricalOutlook.LABEL2} {maxTornadolOutlook && "/ " + maxTornadolOutlook.LABEL2} </span>
                </div>

                <div className="text-left inline-block">
                    <span className="font-bold">Clicked outlook</span>
                </div>
                <div className="text-left">
                    <span>{clickedOutlook.properties.LABEL2}</span>
                </div>

                <div className="text-left inline-block">
                    <span className="font-bold">Affected cities (in max area)</span>
                </div>
                <div className="text-left">
                    <span>{outlookGeneral.affected_cities}</span>
                </div>         

            </div>
            {
                outlookGeneral.outlook_summary && (
                <div className="text-black border">
                    <h2 className="text-black">AI Summary of SPC Outlook</h2>                
                    <h3 className="text-black">{outlookGeneral.outlook_summary["summary_headline"]}</h3>
                    <p className="text-sm text-black">{outlookGeneral.outlook_summary["summary_intro"]}</p>
                    <div className="grid grid-cols-2 gap-1">
                        <div className="text-left inline-block text-base">Major risks</div>
                        <div className="text-sm">{outlookGeneral.outlook_summary["major_risks"]}</div>

                        <div className="text-left inline-block text-base">Stormchasing opportunities</div>
                        <div className="text-sm">{outlookGeneral.outlook_summary["stormchasing_possibilities"]}</div>                    
                    </div>
                </div>)
            }
            <h5 className="text-black text-lg">Time when outlook is, or was, valid</h5>
            <div className="grid grid-cols-3 gap-4">
                <div className="text-left inline-block text-base">Time</div>
                <div className="text-base">From</div>
                <div className="text-base">To</div>


                <div className="text-left inline-block">Relative (apprx)</div>
                <div className="">{getRelativeTimeFromDateTime(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.VALID
                            )
                            )}{" "}
                            h from now</div>
                <div className="">{getRelativeTimeFromDateTime(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.EXPIRE
                            )
                            )}
                            h from now</div>

                
                <div className="text-left inline-block">ZULU</div>
                <div className="">
                {" "}
                            {utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.VALID
                            ),
                            "zulu"
                            )}{" "}                    
                </div>
                <div className="">
                {utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.EXPIRE
                            ),
                            "zulu"
                            )}                    
                </div>

                <div className="text-left inline-block">CDT</div>
                <div className="">{" "}
                            {utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.VALID
                            ),
                            "cdt"
                            )}{" "}</div>
                <div className="">{utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.EXPIRE
                            ),
                            "cdt"
                            )}</div>        

                <div className="text-left inline-block">Your local time</div>
                <div className="">{" "}
                            {utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.VALID
                            ),
                            "client"
                            )}{" "}</div>
                <div className="">
                    {utcDateToReadable(
                            getUtcTimestampFromOnlyNumbersTimestamp(
                                maxCategoricalOutlook.EXPIRE
                            ),
                            "client"
                            )}                    
                </div>                                           
            </div>

            <h2 className="text-black">SPC TEXT</h2>
            <div
            dangerouslySetInnerHTML={{
                __html: (
                    outlookGeneral.outlook_text ? outlookGeneral.outlook_text.replaceAll("\n", "<br />") : "No text available."
                ),
            }}
            />
        </div>
    )
}