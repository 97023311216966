import React from 'react';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the datalabels plugin
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js"

ChartJS.register(RadialLinearScale, PointElement, LineElement, ArcElement, Filler, Tooltip, Legend)
ChartJS.register(ChartDataLabels);


const PieChart = ({ data }) => {
  
  //console.log(data.datasets)
  // Data for the donut chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the external legend
      },
      datalabels: {
        display: true,
        color: 'black', // Label text color
        anchor: 'center', // Position of the label inside the segment
        align: 'center', // Alignment of the label inside the segment
        formatter: (value, context) => {
          const label = data.labels[context.dataIndex];
          return label;
        },        
      },        
    },
  }  

  return (
    <Pie data={data} options={options} />
  );
};

export default PieChart;
