import TornadoReports from "pages/tornado-reports/TornadoReports"
import TornadoReportItem from "pages/tornado-reports/TornadoReportItem"


export const tornadoReportRoutes = [
    {
        path: '/tornado-reports',
        children: [
            {index: true, element: <TornadoReports />},
            {path: ':tornadoReportId', element: <TornadoReportItem />},
        ]
    }
]