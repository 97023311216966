import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";

import AuthService from 'services/AuthService';
import Loader from 'components/loader-gifs/Loader';

const ForgotPassword = () => {
  const auth = new AuthService();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const currentURL = "https://stormcenter.app" + window.location.pathname;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      await auth.forgot({email});
      setLoading(false);
      navigate('/reset');
    } catch (error) {
      setLoading(false);
      setError("There has been some error and I cannot send reset code.");
      console.error(' failed', error);
    }
  }

  return (
    <>
      <Helmet>
        <title>Retrieve forgotten password</title>
        <meta name="description" content="Retrieve your forgotten password." />
        <link rel="canonical" href={currentURL} />
      </Helmet>   
      <section className="h-screen">
        <div className="flex items-center justify-center">
          <div className="w-full max-w-xl mt-24 px-4">
            <div className="bg-transparent p-8 shadow-lg rounded shadow-white">
              <h1 className="text-center text-3xl font-bold mb-6">Forgot Password</h1>
              <form onSubmit={handleSubmit} className="space-y-6" noValidate>
                <div className="space-y-2">
                  <label htmlFor="email" className="block text-sm font-medium text-white-700">
                    E-Mail Address
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    autoFocus
                  />
                  {/* <p className="text-red-500 text-xs italic">Email is invalid</p> */}
                </div>

                <div className="flex justify-center mt-4">
                  <button type="submit" disabled={loading} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    {
                      loading ? 
                      <Loader className="w-6 h-6">
                        Reset Password
                      </Loader>
                      : "Reset Password"
                    }
                  </button>
                </div>
                {error && <p className='text-center text-red-500 text-xs mt-1'>{error}</p>}
              </form>
            </div>
          </div>
        </div>
      </section>
      </>
  );
};

export default ForgotPassword;
