import React, { useContext } from 'react'
import Slider from "react-slick";
import { StormContext } from 'contexts/StormContext';
import classNames from 'classnames'

export const WikiOutbreaks = ({wikiEvents}) => {
    const {isToggleTornadoDays} = useContext(StormContext)

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        className: "relative ml-10 w-[calc(100%-90px)] [&>div>div]:!flex [&>div>div>div]:!h-[inherit] [&>div>div>div>div]:!h-full",
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,

    }

    return (
        <div className={classNames("mt-5 min-[1170px]:my-0 ml-1rem min-[1170px]:col-span-4 h-fit md:block", {
            'hidden': !isToggleTornadoDays
        })}>
            <h3 className={'mt-0'}>Tornado Outbreaks in History</h3>
            <Slider
                {...sliderSettings}
            >
                {wikiEvents.map((event, i) => {
                    return (
                        <div key={i} className={`bg-[#012432ba] rounded-md h-full ${i !== 0 ? 'ps-2' : ''}`}>
                            <div className="text-sm min-h-[70px] font-black p-[10.5px] flex flex-col justify-around"
                            >
                                <a
                                    href={`/history/${formatDate(event.start_date)}`}
                                    className="underline block text-base mb-1"
                                    target={'_blank'}
                                >
                                    {event.title}
                                </a>
                                {formatDate(event.start_date)} to {formatDate(event.end_date)}
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

const NextArrow = ({className, onClick}) => {
    return (
        <div className={`${className} absolute !-right-6 min-[1169px]:!-right-8  !top-1/2`} onClick={onClick}/>
    );
};

const PrevArrow = ({className, onClick}) => {
    return (
        <div className={`${className} absolute !-left-6 min-[1169px]:!-left-8 !top-1/2`} onClick={onClick}/>
    );
};

const formatDate = (inputDate) => {
    // Parse the input date string into a Date object
    const dateObj = new Date(inputDate);

    // Get the year, month, and day from the Date object
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Create the formatted date string in "YYYY-MM-DD" format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};