import React, { useContext, useMemo, useState, useRef, Suspense, lazy } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import classNames from "classnames";

import { StormContext } from "contexts/StormContext";

const LeftSidebar = lazy(() => import ("components/LeftSidebar"));
const TopBar = lazy(() => import ("components/TopBar"));
const RightSidebar = lazy(() => import ("components/RightSidebar"));

const MapLayout = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isSidebarOpen } = useContext(StormContext);
	const isMobile = useMediaQuery("(max-width: 1024px)");

  const location = useLocation();
  /*const isHomePage = useMemo(() => {
    return location.pathname === "/";
  }, [location]);*/

  // Toggle sidebar menu
  const toggleMenuOpen = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const toggleBtnRef = useRef(null)

  return (
    <>
      <div className="App flex stormmap">
        <div
          className={classNames(
            " fixed top-16 lg:top-0 lg:relative z-[2000] left-0 lg:h-full w-64 transition-transform duration-300 transform overflow-hidden",
            {
              "-translate-x-full lg:translate-x-0": !isSidebarOpen,
              "translate-x-0": isSidebarOpen,
							"custom-mobile-sidebar": isMobile,
            }
          )}
        >
          <Suspense fallback={<div>Loading sidebar...</div>}>
            <LeftSidebar menuStatus={true} toggleMenuOpen={toggleMenuOpen} toggleBtnRef={toggleBtnRef} />
          </Suspense>
        </div>
        <div className="w-full lg:w-[calc(100vw-256px)]">
          <Suspense fallback={<div>Loading topbar...</div>}>
            <TopBar toggleBtnRef={toggleBtnRef} />
          </Suspense>
          <Suspense fallback={<div>Loading right sidebar...</div>}>
            <RightSidebar />
          </Suspense>
          <div className={"mt-16 h-[calc(100vh-64px)]"}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default MapLayout;
