import {
    IconLayer,
} from "@deck.gl/layers";

import tvIcon from "assets/images/icons/map/tv.webp";

export const createTvChannelsLayer = ({
    tvChannels,
    tvChannelsCheck,
    displayPopup,
    setModalType
  }) => {
    return tvChannels.length > 0
    ? new IconLayer({
        id: "tv-channels-layer",
        data: tvChannels,
        pickable: true,
        iconAtlas: tvIcon,
        iconMapping: {
        marker: {
            x: 0,
            y: 0,
            width: 200,
            height: 200,
            sizeUnits: "pixels",
            mask: false,
        },
        },
        getIcon: (d) => "marker",
        sizeScale: 50,
        getPosition: (d) => d.position,
        getColor: "fff", //(d) => [Math.sqrt(d.exits), 140, 0],
        visible: tvChannelsCheck,
        onClick: (d) => {
        console.log("D", d.object);
        setModalType("TVChannel");
        displayPopup(
            <div className={"text-black font-bold"}>
            <h3 className={"text-black font-bold"}>
                Local TV Channel: {d.object.name}
            </h3>
            <p className={"text-black font-bold"}>
                Visit {d.object.name} for possible live coverage of storms in
                this area:{" "}
                <a
                href={d.object.url}
                rel="nofollow noreferrer"
                target="_blank"
                className="text-black font-bold"
                >
                {d.object.url}
                </a>
            </p>
            </div>
        );
        },
    })
    : null
}