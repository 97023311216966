import axiosService from "utils/axios";

export default class AuthService {
  async login(data) {
    return (await axiosService.post(`/login`, data)).data;
  }

  async register(data) {
    return (await axiosService.post(`/register`, data)).data;
  }

  async forgot(data) {
    return (await axiosService.post(`/forgot`, data)).data;
  }

  async reset(data) {
    return (await axiosService.post(`/reset`, data)).data;
  }

  async verifyEmail(email) {
    return (await axiosService.get(`/verification?email=`+ email)).data;
  }

  async verifyCode(data) {
    return (await axiosService.post(`/verification`, data)).data;
  }

  async updateSetting(data) {
    return (await axiosService.post('/settings', data)).data;
  }
}
