import React from 'react';
import { useNavigate } from "react-router-dom";

import { getTimeFromTimestamp, getSunlightIcon } from 'utils/general-functions';

function handleReportClicked(tornadoReportId, navigate) {
    console.log("Report clicked", tornadoReportId)
    navigate(`/tornado-reports/${tornadoReportId}`)
}


function SpotterTornadoRow({ tornadoReport }) {
    const navigate = useNavigate();
    return (        
        <tr className="text-white cursor-pointer text-lg even:bg-[#ffffff1a] hover:bg-[#ffffff4a]" onClick={() => handleReportClicked(tornadoReport.id, navigate)}>
            <td className='p-[8px] align-top'><a className='underline' href={`/history/${tornadoReport.datetime_utc.slice(0,10)}`}>{tornadoReport.datetime_utc.slice(0,10)}</a></td>
            <td className='p-[8px] align-top'>{getTimeFromTimestamp(tornadoReport.datetime_utc)} Z</td>
            <td className='p-[8px] align-top'> {getSunlightIcon(tornadoReport.sunlight)} </td>
            <td className='p-[8px] align-top'>{parseFloat(tornadoReport.distance_difference_miles).toFixed(2)} miles @<br />{parseFloat(tornadoReport.time_difference_minutes).toFixed(2)} min</td>
            <td className='p-[8px] align-top'>{tornadoReport.location}, {tornadoReport.county} county</td>
            <td className='p-[8px] align-top'> {tornadoReport.state} </td>
            <td className='p-[8px] align-top'> {tornadoReport.tags?.join(", ")} </td>
            <td className='p-[8px] align-top'>{tornadoReport.f_scale_enhanced ?? "-"}</td>
            <td className='p-[8px] align-top'>{tornadoReport.comments}</td>
        </tr>
    );
}

export default SpotterTornadoRow;
