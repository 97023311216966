import Events from "pages/storm-history/Events";
import EventDate from "pages/storm-history/EventDate";
import EventYearMonth from "pages/storm-history/EventYearMonth";
import EventYearMonthAll from "pages/storm-history/EventYearMonthAll";
import EventYear from "pages/storm-history/EventYear";
import EventYearAll from "pages/storm-history/EventYearAll";
import { CustomEvents } from "pages/storm-history/CustomEvents";
import Insights from "pages/storm-history/Insights";

export const eventRoutes = [
    {
        path: '/history',
        children: [
            {index: true, element: <Events/>},
            {path: ':eventDate', element: <EventDate/>},
            {path: 'month/', element: <EventYearMonthAll/>},
            {path: 'month/:eventYearMonth', element: <EventYearMonth/>},            
            {path: 'year/', element: <EventYearAll />},              
            {path: 'year/:eventYear', element: <EventYear/>},                          
            {path: 'custom/', element: <CustomEvents />},
            {path: 'insights/', element: <Insights />},         
        ]
    }
]

// 