import React, {useContext} from "react";

import Alert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';

import { StormContext } from "contexts/StormContext";
import { EventBox } from "./EventBox"
import Loader from "../loader-gifs/Loader";
import "pages/storm-history/Events.css";

import windWhiteIcon from "assets/images/icons/wind-icon-white.webp";
import hailIcon from "assets/images/icons/map/hail-round.webp";
import tornadoSimpleIcon from "assets/images/icons/tornado-simple.webp";

import { formatDate } from "utils/general-functions";

const EventList = ({loading, filterApplied, monthlyStats=true}) => {
    const {
        events,
        isLoading            
    } = useContext(StormContext);

    let previousMonth = "";
    let previousYear = "";
    return (<>
        <div className="grid grid-cols-1 gap-2 relative bottom-10">
            <div className="w-full mx-auto">
                {isLoading && <Loader />}
                {!isLoading && Object.keys(events).length > 0 ? (
                    <div
                        id="events-new"
                        className="h-full flex flex-col my-3 gap-y-3"
                    >
                        <p>{filterApplied ? <Alert severity="warning">A filter has been applied. Monthly stats does not represent the entire month.</Alert>: null}</p>                            
                        {
                            
                            Object.entries(events).map(([year_month, event], i) => {
   
                                const [year, month] = year_month.split('-');

                                // Convert the month number to an integer (removing leading zeros)
                                const monthNumber = parseInt(month, 10);

                                // Array of month names starting from January (index 0) to December (index 11)
                                const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

                                const monthName = monthNames[monthNumber - 1];
                                let totalTornadoReports = 0;
                                let totalHailReports = 0;
                                let totalWindReports = 0;
                                let tornadoRiskList = {
                                    "0": 0,
                                    "2": 0,
                                    "5": 0,
                                    "10": 0,
                                    "15": 0,
                                    "30": 0,
                                    "45": 0
                                }
                                let categoricalList = {
                                    "NO TSTM": 0,
                                    "TSTM": 0,
                                    "MRGL": 0,
                                    "SLGT": 0,
                                    "ENH": 0,
                                    "MDT": 0,
                                    "HIGH": 0
                                }

                                const filteredEvent = event.filter((day_event) => !!day_event.day1_max_categorical )

                                for (const ev of filteredEvent) {
                                    totalTornadoReports += ev.tornado_reports;
                                    totalHailReports += ev.hail_reports;
                                    totalWindReports += ev.wind_reports;
                                    categoricalList[ev.day1_max_categorical] += 1;
                                    tornadoRiskList[ev.day1_max_tornado] += 1;
                                }
                                
                                return (
                                    <div className="block" key={i}>
                                        <h1 className="block w-full mb-3"> {monthName} {year} </h1>
                                        {monthlyStats && (

                                        <>                                        
                                        <div className="flex items-center">

                                        <h5 className="w-[150px] hidden sm:inline">SPC Reports</h5>
                                            {/* Tornado Reports */}
                                            <Tooltip title="SPC Tornado reports" className="text-xl">
                                            <div className="flex items-center mr-2 sm:mr-4">
                                                <div className="bg-[#ff0000] border-2 border-solid text-black rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-2xl text-black text-bold">{totalTornadoReports}</span>
                                                </div>
                                                <span className="ml-2 mr-4">
                                                <img src={tornadoSimpleIcon} width="40px" alt="tornado" />
                                                </span>
                                            </div>
                                            </Tooltip>

                                            {/* Hail Reports */}
                                            <Tooltip title="SPC Hail reports" className="text-xl">
                                            <div className="flex  items-center mr-2 sm:mr-4">
                                                <div className="bg-[#e3e3e3] border-2 border-solid  text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-xl text-black text-bold">{totalHailReports}</span>
                                                </div>
                                                <span className="ml-2 mr-4">
                                                <img src={hailIcon} width="40px" alt="hail" />
                                                </span>
                                            </div>
                                            </Tooltip>

                                            {/* Wind Reports */}
                                            <Tooltip title="SPC Wind reports" className="text-xl">
                                            <div className="flex items-center">                                    
                                                <div className="bg-[#e3e3e3] border-2 border-solid text-black active:bg-pink-600 font-bold uppercase text-xs px-4 py-[5px] rounded shadow hover:shadow-md outline-none w-12 h-10 sm:w-16 sm:h-12 flex items-center justify-center cursor-pointer">
                                                <span className="text-xl text-black font-bold">{totalWindReports}</span>
                                                </div>
                                                <span className="ml-2 mr-4">
                                                <img src={windWhiteIcon} width="40px" alt="tornado" />
                                                </span>
                                            </div>
                                            </Tooltip>

                                        </div>

                                        <div className="flex mt-3 items-center">
                                            <h5 className="w-[150px] hidden sm:inline">Outlooks</h5>
                                            {Object.keys(categoricalList).map(key => (
                                                <div className="flex flex-col items-center mr-3 sm:mr-4" key={key}>
                                                    <div 
                                                        className={`${key === 'NO TSTM' ? 'bg-[#fff]' : `lvl-bg-${key}`} 
                                                            border-2 border-solid border-[#afb200] text-black text-xl font-bold rounded shadow hover:shadow-md outline-none w-9 h-9 sm:w-10 sm:h-10 flex items-center justify-center cursor-pointer`}
                                                    >
                                                    {categoricalList[key]}
                                                    </div>
                                                    <span className="">
                                                    {key === 'NO TSTM' ? 'NONE' : key}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="flex items-center mt-2">
                                            <h5 className="w-[150px] hidden sm:inline">Tornado risks</h5>
                                            {Object.keys(tornadoRiskList).map(key => (
                                                <div className="flex flex-col items-center mr-3 sm:mr-4" key={key}>
                                                    <div
                                                        className="bg-[#e3e3e3] border-2 border-solid border-[#afb200] text-black text-xl font-bold rounded shadow hover:shadow-md outline-none w-9 h-9 sm:w-10 sm:h-10 flex items-center justify-center cursor-pointer">
                                                        {tornadoRiskList[key]}
                                                    </div>
                                                    <span className="">
                                                    {key} %
                                                    </span>
                                                </div>
                                            ))}
                                        </div>   
                                        </>
                                        )}                         
                                        <div className={'flex w-full flex-wrap gap-2 mt-2'}>
                                            {event.map((item, i) => {
                                                //console.log("VENTITEM", item)
                                                item.event_date = String(new Date(item.event_date).toLocaleString("CDT"));

                                                let bg = item.day1_max_categorical ? item.day1_max_categorical : item.day1_max_categorical ? item.day1_max_categorical : "nostorms";

                                                let dateStr = item.event_date.slice(0, 10).replace(",", "");
                                                let formatedDate = formatDate(item.event_date);
                                                let significantClass = "";
                                                if (item.tornado_reports && parseFloat(item.tornado_reports) > 20) {
                                                    significantClass = "significant";
                                                } else if (item.significance_rank >= 2) {
                                                    significantClass = "significant";
                                                }

                                                var outlookLabelFull = item.day1_max_categorical ? item.day1_max_categorical : "NO TSTM";

                                                const affectedStates = item.affected_states && item.affected_states[0].tornadoReports.map((report) => report.state);

                                                const currentMonth = new Date(item.event_date).toLocaleString("en-US", {
                                                    month: "long",
                                                });
                                                const currentYear = new Date(item.event_date)
                                                    .getFullYear()
                                                    .toString();

                                                // Render the month and year header only if it's a new month or year
                                                let monthYearHeader = null;
                                                if (currentMonth !== previousMonth || currentYear !== previousYear) {
                                                    monthYearHeader = (<h3 className="month-year-header ">
                                                        {currentMonth} {currentYear}
                                                    </h3>);
                                                }

                                                // Update previousMonth and previousYear for the next iteration
                                                previousMonth = currentMonth;
                                                previousYear = currentYear;

                                                // {checkboxClasses(item)}
                                                
                                                return (                                
                                                    <EventBox 
                                                        formatedDate        = {formatedDate}
                                                        significantClass    = {significantClass}
                                                        i                   = {i}
                                                        bg                  = {bg}
                                                        dateStr             = {dateStr}
                                                        item                = {item}
                                                        outlookLabelFull    = {outlookLabelFull}
                                                        affectedStates      = {affectedStates}
                                                        key={i}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                ) : (
                    <p className="text-base mt-2 font-medium">Dates earlier than this are not likely to be added due to a lack of data.</p>
                )}
            </div>
        </div>        
    </>);
};

export default EventList;
