import axiosService from "utils/axios";

export default class MapService {

    async getMetaData() {
        return (await axiosService.post(`/get-meta-data`)).data
    }

    async getCurrentEvents() {
        return (await axiosService.post(`/get-current-events`)).data
    }    

    async getWeatherAlerts() {
        return (await axiosService.post(`/get-weather-alerts`)).data
    }    

    async getHistoricalOutlook(eventDate) {
        return (await axiosService.post(`/get-historical-outlook`, { eventDate })).data
    }

    async getHistoricalWarnings(eventDate) {
        return (await axiosService.post(`/get-historical-warnings`, { eventDate })).data
    }        

    async getActiveTvChannels() {
        return (await axiosService.post(`/get-active-tv-channels`)).data
    }

    async getSpotterReports() {
        //console.log("Retrieving Spotter data from backend...(not cache)")
        return (await axiosService.post(`/get-spotter-reports`)).data
    }    

    async getSpcReports() {
        //console.log("Retrieving SPC data from backend...(not cache)")
        return (await axiosService.post(`/get-spc-reports`)).data
    } 

    // async getTornadoReportNearby() {
    //     //console.log("Retrieving SPC data from backend...(not cache)")
    //     return (await axiosService.post(`/get-tornado-report-nearby`, { spcReportId })).data
    // }     

    async getSpcReportsJson() {
        //console.log("Retrieving SPC data as JSON from backend...(not cache)")
        return (await axiosService.post(`/get-spc-reports-json`)).data
    }     

    async getHistoricalSpcReports(eventDate, reportType) {        
        return (await axiosService.post(`/get-historical-spc-reports`, { eventDate, reportType })).data
    }

    async getSpotterPositions(spotters, upperTime, lowerTime) {
        console.log("Retrieving Spotter position data from backend...(not cache)")
        //console.log("spotters", spotters, "upperTime", upperTime, "lowerTime", lowerTime)
        return (await axiosService.post(`/get-spotter-positions`, { spotters, upperTime, lowerTime })).data
    }  

    async getChristoffer() {
        return (await axiosService.post(`/get-christoffer`)).data
    }       
    
    async getSpotterFavorites(email) {
        return (await axiosService.post(`/get-spotter-favorites-data`, {email})).data
    }
}